import { MIN_PASS_LENGTH } from '@/constants/const'

const Validation = {
  data: () => ({
    valid: true,
    notEmtpy: [v => !!v || 'This field should not be empty'],
    nameRules: [
      v => (v?.length <= 10) || 'Name must be less than 10 characters'
    ],
    emailRules: [
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    loginEmailRules: [],
    loginPwdRules: [],
    regEmailRules: [],
    regPwdRules: [],
    regCfmPwdRules: [],
    regAgreeRules: [],
    confirmFormPwdRules: [],
    confirmFormCfmPwdRules: [],
    pwdRules: [
      v => !!v || 'Password is required.',
      v => (v && v?.length) >= MIN_PASS_LENGTH || `Min ${MIN_PASS_LENGTH} characters`
    ],
    urlRules: [
      v => (/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\\/\w .-]*)*\/?$/.test(v) || !v?.length) || 'Url address must be valid'
    ],
    stateRules: [
      v => /(US|us)/.test(v) || 'Sorry. Only `US` state is allowed'
    ],
    agreeRules: [v => !!v || 'You must agree to continue!']
  }),
  methods: {
    /**
     * nameRulesWithType - is name validation method
     * @date 2021-10-28
     * @returns {Array} - with error message.
     */
    nameRulesWithType: (nameType) => v => (v?.length <= 10) || `${nameType} must be less than 10 characters`,
    /**
     * Validate - is check to valid form
     * @date 2020-11-30
     * @returns {Boolean} - validation state.
     */
    validate () {
      return this.$refs.form.validate()
    },
    /**
     * Reset form
     * @date 2020-11-30
     * @returns {any}
     */
    reset () {
      this.$refs.form.reset()
    },

    /**
     * Reset validation
     * @date 2020-11-30
     * @returns {any}
     */
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    /**
     * Set dynamic validation rules
     */
    setDynamicValidationRules (data) {
      for (const key in data) {
        this[key] = data[key]
      }
    }
  },
  computed: {
    /**
     * cfmPwdRule - is confirm password validation method
     * @date 2021-10-28
     * @returns {Array} - with error message. In component should add to data 'cfmPwd' property
     */
    cfmPwdRule () {
      return () => {
        return (this.form.confirmPassword === this.form.password) || 'The password doesn\'t match'
      }
    }
  }
}

export default Validation
