import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function supportedLocales () {
  return process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
}
function isLocaleSupported (locale) {
  return supportedLocales().includes(locale)
}
function getUserSupportedLocale () {
  const userPreferredLocale = getUserLocale()

  if (isLocaleSupported(userPreferredLocale.locale)) {
    return userPreferredLocale.locale
  }

  if (isLocaleSupported(userPreferredLocale.localeNoISO)) {
    return userPreferredLocale.localeNoISO
  }
  return process.env.VUE_APP_I18N_LOCALE
}
function getUserLocale () {
  const locale = window.navigator.language || window.navigator.userLanguage || process.env.VUE_APP_I18N_LOCALE
  return {
    locale: locale,
    localeNoISO: locale.split('-')[0]
  }
}

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const dateTimeFormats = {
  en: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }
  },
  es: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }
  }
}

const numberFormats = {
  en: {
    currency: {
      style: 'currency',
      currency: 'USD'
    }
  },
  es: {
    currency: {
      style: 'currency',
      currency: 'EUR'
    }
  }
}

export default new VueI18n({
  locale: localStorage.getItem('locale') || getUserSupportedLocale() || process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  dateTimeFormats,
  numberFormats
})
