<template>
  <div class="visit-page">
    <div class="pt-4">
      <Breadcrumbs/>
    </div>
    <template v-if="$route.name === 'Visit'">
      <div class="mobile-version">
        <v-row no-gutters class="panel-main">
          <v-col>
            <v-card
              :style="{'border-radius': '16px'}"
              elevation="2"
              class="survey"
            >
              <v-card-title class="white--text mt-8">
                <div class="survey__main-row">
                  <div class="avatar-and-full-name" v-if="visitsInfo && visitsInfo.doctor">
                    <div class="avatar">
                      <v-avatar>
                        <v-img :src="visitsInfo.doctor.logoSmall"></v-img>
                      </v-avatar>
                    </div>
                    <div class="full-name">
                      <div>
                        <span class="position">{{$t('visit.doctor')}}</span>
                      </div>
                      <div>
                        <span class="name">{{visitsInfo.doctor.name}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="information">
                    <!--<img :src="getImgWithExt(['icons', 'info', 'svg'])"/>-->
                    <v-row no-gutters class="justify-end">
                      <Button
                        class="call"
                        outlined
                        color="#008C89"
                        width="56"
                        height="56"
                        :styles="{'border': '2px solid'}"
                        :disabled="!visitsInfo.doctor.phone"
                        @click.native="callToDoctor(visitsInfo.doctor.phone)"
                        v-if="visitsInfo && visitsInfo.doctor"
                      >
                        <v-icon color="#008C89">mdi-phone-outline</v-icon>
                      </Button>
                      <Button
                        class="ml-2 chat"
                        outlined
                        color="#008C89"
                        width="56"
                        height="56"
                        :styles="{'border': '2px solid'}"
                        @click.native="goTo('/chat')"
                      >
                        <v-icon color="#008C89">mdi-chat-outline</v-icon>
                      </Button>
                    </v-row>
                  </div>
                </div>
                <div class="line"></div>
              </v-card-title>
              <v-card-text v-if="visitsInfo && visitsInfo.statusPanel">
                <div class="survey__main-row">
                  <div class="status" :class="getStatusCodeName[visitsInfo.statusPanel.statusCode].class">
                    <span class="status-name">{{ $t('visit.' + getStatusCodeName[visitsInfo.statusPanel.statusCode].name)}}</span>
                  </div>
                  <div class="date">
                    <div class="text-date">
                      {{$t('visit.dueDate')}}
                    </div>
                    <div class="full-date" :class="getStatusCodeName[visitsInfo.statusPanel.statusCode].class">
                      {{$moment(visitsInfo.statusPanel.date1).format('MM/DD/YYYY')}}
                    </div>
                  </div>
                </div>
                <div class="content-text mt-4">
<!--                  <p v-html="transformHtml($t('visit.descriptionSurvey'), { number: visitsInfo.statusPanel.number})"></p>-->
                  <p>{{getStatusCodeName[visitsInfo.statusPanel.statusCode].description(visitsInfo.statusPanel.statusDays) || '-'}}</p>
                </div>
              </v-card-text>
              <v-card-actions class="justify-center pb-4">
                <Button
                  height="55"
                  width="89vw"
                  @click.native="goTo('/survey')"
                  btn-bg="dark-green">
                  <span style="color: #FFFFFF">{{$t('visit.surveyLabel')}}</span>
                </Button>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters class="panel-report">
          <v-col>
            <v-card
              :style="{'border-radius': '16px'}"
              elevation="2"
              class="report"
            >
              <v-card-title class="white--text mt-8">
                <div class="title">
                  {{$t('visit.receipts')}}
                </div>
              </v-card-title>
              <v-card-text v-if="visitsInfo.receipts && visitsInfo.receipts.$values">
                <div class="link-block mb-1"
                     :class="{ 'disabled-link': !visitsInfo.hasResults }"
                     v-for="receipt in visitsInfo.receipts.$values"
                     :key="receipt.$id"
                     @click="openReport('/receipts', receipt)">
                  <div class="label">
                    <img :src="getImgWithExt(['icons', 'bottle-sup', 'svg'])"/><div class="label__name">{{$moment(receipt.date).format('MM/DD/YYYY')}} {{receipt.orderMethodName}}</div>
                  </div>
                  <div class="arrow">
                    <v-icon>mdi-chevron-right</v-icon>
                  </div>
                </div>
                <div class="line"></div>
              </v-card-text>
              <v-card-title class="white--text mt-0 pt-0">
                <div class="title">
                  {{$t('visit.schedule')}}
                </div>
              </v-card-title>
              <v-card-text>
                <div class="link-block" :class="{ 'disabled-link': !visitsInfo.hasResults }" @click="openReport('/schedule')">
                  <div class="label">
                    <img :src="getImgWithExt(['icons', 'tablet-green-outline', 'svg'])"/>
                    <div class="label__name">Schedule</div>
                  </div>
                  <div class="arrow">
                    <v-icon>mdi-chevron-right</v-icon>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
<!--        <v-row no-gutters class="panel-report">
          <v-col>
            <v-card
              :style="{'border-radius': '16px'}"
              elevation="2"
              class="report"
            >
              <v-card-title class="white&#45;&#45;text mt-8">
                <div class="title">
                  {{$t('visit.reports')}}
                </div>
              </v-card-title>
              <v-card-text>
                <div class="link-block" :class="{ 'disabled-link': !visitsInfo.hasResults }" @click="openReport('/receipts')">
                  <div class="label">
                    <img :src="getImgWithExt(['icons', 'bottle-sup', 'svg'])"/>
                    <div class="label__name">{{$t('visit.receipts')}}</div>
                  </div>
                  <div class="arrow">
                    <v-icon>mdi-chevron-right</v-icon>
                  </div>
                </div>
                <br>
                <div class="link-block" :class="{ 'disabled-link': !visitsInfo.hasResults }" @click="openReport('/schedule')">
                  <div class="label">
                    <img :src="getImgWithExt(['icons', 'tablet-green-outline', 'svg'])"/>
                    <div class="label__name">Schedule</div>
                  </div>
                  <div class="arrow">
                    <v-icon>mdi-chevron-right</v-icon>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>-->
        <v-row no-gutters class="panel-complaints">
          <v-col>
            <v-card
              :style="{'border-radius': '16px'}"
              elevation="2"
              class="complaints"
            >
              <v-card-title class="white--text mt-8">
                <div class="title">
                  {{$t('visit.complaints')}}
                </div>
              </v-card-title>
              <v-card-text v-if="visitsInfo.complaints && visitsInfo.complaints.$values">
                <ul class="complaints__list">
                  <li v-for="complaint in visitsInfo.complaints.$values" :key="complaint">
                    -{{complaint}}
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters class="panel-last-notes">
          <v-col>
            <v-card
              :style="{'border-radius': '16px'}"
              elevation="2"
              class="last-notes"
            >
              <v-card-title class="white--text mt-8">
                <div class="main-row">
                  <div class="title">
                    {{$t('visit.lastNotes')}}
                  </div>
                  <div class="time">
                    {{$moment(visitsInfo.date).format('MM/DD/YYYY')}}
                  </div>
                </div>
              </v-card-title>
              <v-card-text v-if="visitsInfo.notes">
                <ul class="last-notes__list">
                  <li v-for="notes in visitsInfo.notes.split(';')" :key="notes">
                    -{{notes}}
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div class="desktop-version">
        <v-row>
          <v-col cols="9">
            <v-row class="panel-main">
              <v-col>
                <v-card
                  :style="{'border-radius': '16px'}"
                  elevation="2"
                  class="survey"
                >
                  <v-card-title class="white--text mt-8">
                    <div class="survey__main-row">
                      <div class="avatar-and-full-name" v-if="visitsInfo && visitsInfo.doctor">
                        <div class="avatar">
                          <v-avatar>
                            <v-img :src="visitsInfo.doctor.logoSmall"></v-img>
                          </v-avatar>
                        </div>
                        <div class="full-name">
                          <div>
                            <span class="position">{{$t('visit.doctor')}}</span>
                          </div>
                          <div>
                            <span class="name">{{visitsInfo.doctor.name}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="information">
                        <!--<img :src="getImgWithExt(['icons', 'info', 'svg'])"/>-->
                        <v-row no-gutters class="justify-end">
                          <Button
                            class="call"
                            outlined
                            color="#008C89"
                            width="56"
                            height="56"
                            :styles="{'border': '2px solid'}"
                            :disabled="!visitsInfo.doctor.phone"
                            @click.native="callToDoctor(visitsInfo.doctor.phone)"
                            v-if="visitsInfo && visitsInfo.doctor"
                          >
                            <v-icon color="#008C89">mdi-phone-outline</v-icon>
                          </Button>
                          <Button
                            class="ml-2 chat"
                            outlined
                            color="#008C89"
                            width="56"
                            height="56"
                            :styles="{'border': '2px solid'}"
                            @click.native="goTo('/chat')"
                          >
                            <v-icon color="#008C89">mdi-chat-outline</v-icon>
                          </Button>
                        </v-row>
                      </div>
                    </div>
                    <div class="line"></div>
                  </v-card-title>
                  <v-card-text v-if="visitsInfo && visitsInfo.statusPanel">
                    <div class="survey__main-row">
                      <div class="status" :class="getStatusCodeName[visitsInfo.statusPanel.statusCode].class">
                        <span class="status-name">{{ $t('visit.' + getStatusCodeName[visitsInfo.statusPanel.statusCode].name)}}</span>
                      </div>
                      <div class="date">
                        <div class="text-date">
                          {{$t('visit.dueDate')}}
                        </div>
                        <div class="full-date" :class="getStatusCodeName[visitsInfo.statusPanel.statusCode].class">
                          {{$moment(visitsInfo.statusPanel.date1).format('MM/DD/YYYY')}}
                        </div>
                      </div>
                      <v-divider
                        vertical
                        class="divider__notes"
                      ></v-divider>
                      <div class="notes">
<!--                        <p v-html="transformHtml($t('visit.descriptionSurvey'), { number: visitsInfo.statusPanel.number})"></p>-->
                        <p>{{getStatusCodeName[visitsInfo.statusPanel.statusCode].description(visitsInfo.statusPanel.statusDays) || '-'}}</p>
                      </div>
                      <v-divider
                        class="divider__survey"
                        vertical
                      ></v-divider>
                      <div class="button-survey">
                        <Button
                          height="55"
                          @click.native="goTo('/survey')"
                          btn-bg="dark-green">
                          <span style="color: #FFFFFF">{{$t('visit.surveyLabel')}}</span>
                        </Button>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="panel-last-notes">
              <v-col>
                <v-card
                  :style="{'border-radius': '16px'}"
                  elevation="2"
                  class="last-notes"
                >
                  <v-card-title class="white--text mt-8">
                    <div class="main-row">
                      <div class="title">
                        {{$t('visit.lastNotes')}}
                      </div>
                      <div class="time">
                        {{$moment(visitsInfo.date).format('MM/DD/YYYY')}}
                      </div>
                    </div>
                  </v-card-title>
                  <v-card-text v-if="visitsInfo.notes">
                    <ul class="last-notes__list">
                      <li v-for="notes in visitsInfo.notes.split(';')" :key="notes">
                        -{{notes}}
                      </li>
                    </ul>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3">
            <v-row class="panel-report">
              <v-col>
                <v-card
                  :style="{'border-radius': '16px'}"
                  elevation="2"
                  class="report"
                >
                  <v-card-title class="white--text mt-8">
                    <div class="title">
                      {{$t('visit.receipts')}}
                    </div>
                  </v-card-title>
                  <v-card-text v-if="visitsInfo.receipts && visitsInfo.receipts.$values">
                    <div class="link-block mb-1"
                         :class="{ 'disabled-link': !visitsInfo.hasResults }"
                         v-for="receipt in visitsInfo.receipts.$values"
                         :key="receipt.$id"
                         @click="onDownloadReport(receipt)">
                          <!--@click="openReport('/receipts', receipt)"-->
                      <div class="label">
                        <img :src="getImgWithExt(['icons', 'bottle-sup', 'svg'])"/><div class="label__name">{{$moment(receipt.date).format('MM/DD/YYYY')}} {{receipt.orderMethodName}}</div>
                      </div>
                      <div class="arrow">
                        <v-icon>mdi-chevron-right</v-icon>
                      </div>
                    </div>
                    <div class="line"></div>
                  </v-card-text>
                  <v-card-title class="white--text mt-0 pt-0">
                    <div class="title">
                      {{$t('visit.schedule')}}
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <div class="link-block" :class="{ 'disabled-link': !visitsInfo.hasResults }" @click="openReport('/schedule')">
                      <div class="label">
                        <img :src="getImgWithExt(['icons', 'tablet-green-outline', 'svg'])"/>
                        <div class="label__name">Schedule</div>
                      </div>
                      <div class="arrow">
                        <v-icon>mdi-chevron-right</v-icon>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="panel-complaints">
              <v-col>
                <v-card
                  :style="{'border-radius': '16px'}"
                  elevation="2"
                  class="complaints"
                >
                  <v-card-title class="white--text mt-8">
                    <div class="title">
                      {{$t('visit.complaints')}}
                    </div>
                  </v-card-title>
                  <v-card-text v-if="visitsInfo.complaints && visitsInfo.complaints.$values">
                    <ul class="complaints__list">
                      <li v-for="complaint in visitsInfo.complaints.$values" :key="complaint">
                        -{{complaint}}
                      </li>
                    </ul>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-else>
      <router-view/>
    </template>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/layouts/Breadcrumbs'
import Button from '@/components/elements/Button'
import Image from '@/mixins/Image'
import { mapActions } from 'vuex'
import Mapping from '@/mixins/Mapping'

export default {
  name: 'Visit',
  props: {
    visitId: {
      type: String
    }
  },
  mixins: [Image, Mapping],
  components: { Breadcrumbs, Button },
  mounted () {
    this.loadData()
  },
  methods: {
    ...mapActions('visits', ['getVisitsInfo']),
    ...mapActions('receipts', ['printReceipt']),
    goTo (route) {
      this.$router.push({ path: this.$route.path + route })
    },
    callToDoctor (phoneNumber) {
      if (phoneNumber) {
        window.open('tel:' + phoneNumber)
        const self = this
        setTimeout(function () {
          self.TOGGLE_DIALOG({ name: self.dialog.name, state: false })
        }, 200)
      }
    },
    openReport (path, obj) {
      if (this.visitsInfo.hasResults) {
        if (obj) {
          this.$router.push({ path: this.$route.path + path, query: { receiptId: obj.id } })
        } else {
          this.$router.push({ path: this.$route.path + path })
        }
      }
    },
    async onDownloadReport (receipt) {
      const res = await this.printReceipt({ params: { resultId: this.visitId, receiptId: receipt.id }, code: receipt.orderMethodCode })
      const file = new Blob([res])
      const url = window.URL.createObjectURL(file)
      const link = document.createElement('a')
      document.getElementsByTagName('body')[0].appendChild(link)
      link.href = url
      link.download = 'Receipt.pdf'
      link.click()
      document.getElementsByTagName('body')[0].removeChild(link)
    },
    loadData () {
      this.getVisitsInfo(this.visitId).then(res => {
        if (res) {
          document.title = `Visit ${this.$moment(this.visitsInfo.date).format('MM/DD')} - Maestro Patient Portal`
        }
      })
    },
    transformHtml (string, params) {
      const htmlStr = '<span style="font-weight: 600">' + params.number + '</span>'
      string = string.replace('{number}', htmlStr)
      return string
    }
  },
  computed: {
    dashboardInfo () {
      return this.$store.state.patients.dashboardInfo
    },
    visitsInfo () {
      return this.$store.state.visits.visitsInfo
    }
  }
}
</script>

<style scoped lang="scss">
  .visit-page {
    padding: 0 16px 60px;
    background: #F7FAFC;
    .survey {
      &__main-row {
        display: flex;
        flex-wrap: wrap;
        column-gap: 1rem;
        row-gap: 1rem;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .avatar-and-full-name {
          display: flex;
          flex-wrap: wrap;
          column-gap: 1rem;
          justify-content: space-between;
          .avatar {
            width: 80px;
            ::v-deep .v-avatar {
              width: 70px!important;
              height: 70px!important;
            }
          }
          .full-name {
            color: #1A202C;
            .position {
              color: #718096;
              font-weight: 400;
              font-size: 16px;
            }
            .name {
              font-weight: 600;
              font-size: 16px;
              color: #1A202C;
            }
          }
        }
        .information {}
        .status {
          width: auto;
          height: 56px;
          background: rgba(255, 230, 228, 0.7);
          border-radius: 16px;
          display: flex;
          align-items: center;
          padding: 5px 10px;
          justify-content: center;
          .status-name {
            font-weight: 600;
            font-size: 14px;
          }
        }
        .date {
          width: 100px;
          text-align: end;
          font-size: 14px;
          .text-date {
            font-weight: 400;
            color: #A0AEC0;
          }
          .full-date {
            font-weight: 600;
            color: #F16063;
            background: transparent;
          }
          .full-date.need-product {
            color: #F7936F!important;
            background: transparent!important;
          }
          .full-date.in-active {
            color: #A0AEC0!important;
            background: transparent!important;
          }
          .full-date.need-survey {
            color: #F7936F!important;
            background: transparent!important;
          }
          .full-date.need-reeval {
            color: #F7936F!important;
            background: transparent!important;
          }
          .full-date.survey-sent {
            color: #668BFF!important;
            background: transparent!important;
          }
          .full-date.survey-due {
            color: #F16063!important;
            background: transparent!important;
          }
          .full-date.need-review {
            color: #9959F8!important;
            background: transparent!important;
          }
          .full-date.reviewed {
            color: #668BFF!important;
            background: transparent!important;
          }
          .full-date.in-protocol {
            color: #4FAA6E!important;
            background: transparent!important;
          }
          .full-date.protocol-ending {
            color: #E69376!important;
            background: transparent!important;
          }
          .full-date.completed {
            color: #8DBA6A!important;
            background: transparent!important;
          }
          .full-date.in-progress {
            color: #668BFF!important;
            background: transparent!important;
          }
        }
        .notes {
          width: 40%;
        }
        .button-survey {
          width: 88px;
        }
      }
    }
    .line {
      width: 100%;
      background: #d6d6d6;
      height: 1px;
      margin-top: 1rem;
    }
    @media screen and (max-width: 892px) {
      .survey {
        &__main-row {
          justify-content: center;
          .divider {
            &__survey {
              display: none;
            }
          }
          .button-survey {
            width: 100%;
            .button {
              ::v-deep .v-btn {
                width: 100% !important;
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 320px) {
      .survey {
        ::v-deep .v-card__actions {
          .button {
            .v-btn {
              width: 80vw !important;
            }
          }
        }
      }
    }
    .report {
      .title {
        font-weight: 600;
        font-size: 18px;
        color: #16192C;
      }
      .link-block {
        width: 100%;
        padding: 18px 20px;
        background: #F7FAFC;
        display: flex;
        justify-content: space-between;
        border-radius: 8px;
        cursor: pointer;
        .label {
          display: flex;
          align-items: center;
          column-gap: 0.5rem;
          justify-content: space-between;
          &__name {
            font-weight: 600;
            font-size: 14px;
            color: #425466;
          }
        }
      }
      .disabled-link {
        cursor: not-allowed;
        .label {
          &__name {
            color: #c6c6c6;
          }
        }
      }
    }
    .complaints {
      .title {
        font-weight: 600;
        font-size: 18px;
        color: #16192C;
      }
      &__list {
        list-style: none;
        font-weight: 400;
        font-size: 14px;
        color: #718096;
      }
    }
    .last-notes {
      .main-row {
        display: flex;
        column-gap: 1rem;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .title {
          font-weight: 600;
          font-size: 18px;
          color: #16192C;
        }
        .time {
          font-weight: 400;
          font-size: 12px;
          color: #718096;
        }
      }
      &__list {
        list-style: none;
        font-weight: 400;
        font-size: 14px;
        color: #718096;
      }
    }

    .need-product {
      background: rgba(255, 237, 227, 0.7)!important;
      color: #F7936F!important;
    }
    .in-active {
      background: #EDF2F7!important;
      color: #A0AEC0!important;
    }
    .need-survey {
      background: rgba(255, 237, 227, 0.7)!important;
      color: #F7936F!important;
    }
    .need-reeval {
      background: rgba(255, 237, 227, 0.7)!important;
      color: #F7936F!important;
    }
    .survey-sent {
      background: rgba(208, 220, 255, 0.5)!important;
      color: #668BFF!important;
    }
    .survey-due {
      background: rgba(255, 230, 228, 0.7)!important;
      color: #F16063!important;
    }
    .need-review {
      background: rgba(238, 227, 255, 1)!important;
      color: #9959F8!important;
    }
    .reviewed {
      background: rgba(208, 220, 255, 0.5)!important;
      color: #668BFF!important;
    }
    .in-protocol {
      background: rgba(115, 217, 149, 0.2)!important;
      color: #4FAA6E!important;
    }
    .protocol-ending {
      background: #FFE9E2!important;
      color: #E69376!important;
    }
    .completed {
      background: rgba(141, 186, 106, 0.2)!important;
      color: #8DBA6A!important;
    }
    .in-progress {
      background: rgba(208, 220, 255, 0.5)!important;
      color: #668BFF!important;
    }

    .mobile-version {
      display: none;
    }
    .desktop-version {
      display: block;
      .panel-last-notes, .panel-complaints {
        margin-top: 0!important;
      }
    }
    @media screen and (max-width: 760px) {
      .desktop-version {
        display: none;
      }
      .mobile-version {
        display: block;
        .survey {
          &__main-row {
            display: flex;
            flex-wrap: wrap;
            column-gap: 1rem;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            .avatar-and-full-name {
              display: flex;
              flex-wrap: wrap;
              column-gap: 1rem;
              justify-content: space-between;
              .avatar {
                width: 80px;
                ::v-deep .v-avatar {
                  width: 70px!important;
                  height: 70px!important;
                }
              }
              .full-name {
                color: #1A202C;
                .position {
                  color: #718096;
                  font-weight: 400;
                  font-size: 16px;
                }
                .name {
                  font-weight: 600;
                  font-size: 16px;
                  color: #1A202C;
                }
              }
            }
            .information {}
            .status {
              width: auto;
              height: 56px;
              background: rgba(255, 230, 228, 0.7);
              border-radius: 16px;
              display: flex;
              align-items: center;
              padding: 5px 10px;
              justify-content: center;
              .status-name {
                font-weight: 600;
                font-size: 14px;
              }
            }
            .date {
              width: 100px;
              text-align: end;
              font-size: 14px;
              .text-date {
                font-weight: 400;
                color: #A0AEC0;
              }
              .full-date {
                font-weight: 600;
                color: #F16063;
                background: transparent;
              }
              .full-date.need-product {
                color: #F7936F!important;
                background: transparent!important;
              }
              .full-date.in-active {
                color: #A0AEC0!important;
                background: transparent!important;
              }
              .full-date.need-survey {
                color: #F7936F!important;
                background: transparent!important;
              }
              .full-date.need-reeval {
                color: #F7936F!important;
                background: transparent!important;
              }
              .full-date.survey-sent {
                color: #668BFF!important;
                background: transparent!important;
              }
              .full-date.survey-due {
                color: #F16063!important;
                background: transparent!important;
              }
              .full-date.need-review {
                color: #9959F8!important;
                background: transparent!important;
              }
              .full-date.reviewed {
                color: #668BFF!important;
                background: transparent!important;
              }
              .full-date.in-protocol {
                color: #4FAA6E!important;
                background: transparent!important;
              }
              .full-date.protocol-ending {
                color: #E69376!important;
                background: transparent!important;
              }
              .full-date.completed {
                color: #8DBA6A!important;
                background: transparent!important;
              }
              .full-date.in-progress {
                color: #668BFF!important;
                background: transparent!important;
              }
            }
          }
        }
      }
    }
  }
</style>
