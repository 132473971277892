<template>
  <div class="doctor-info">
    <Dialog
      width="350"
      v-if="visit"
      :styles="{'border-radius': '16px'}">
      <template slot="title">
        <div class="doctor-info__header pl-4 pt-4 d-flex align-center">
          <div class="d-flex">
            <v-img :src="visit.doctor.logoSmall" width="60px" height="60px" style="border-radius: 50%"></v-img>
          </div>
          <div class="doctor-title">
            {{visit.doctor.name}}
            <div class="doctor-subtitle">
              Health is happiness
            </div>
          </div>
        </div>
      </template>

      <template slot="body">
        <div class="doctor-info__body">
          <v-row no-gutters>
            <v-col cols="12">
              <span class="title">{{ $t('appBar.organizationInfo.address') }}: </span>
              <span class="description">{{visit.doctor.address || '-'}}</span>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <span class="title">{{ $t('appBar.organizationInfo.phone') }}: </span>
              <span class="description">{{visit.doctor.address || '-'}}</span>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <span class="title">{{ $t('appBar.organizationInfo.mail') }}: </span>
              <span class="description">{{visit.doctor.email || '-'}}</span>
            </v-col>
          </v-row>
        </div>
      </template>

      <template slot="actions">
        <div class="d-flex justify-space-between w-100 pt-4">
          <Button color="transparent" :disabled="!visit.doctor.phone" @click.native="callToDoctor(visit.doctor.phone)">
            <v-icon color="#4FAA6E">mdi-phone-outline</v-icon>
            <span class="ml-2">{{ $t('appBar.organizationInfo.call') }}</span>
          </Button>
          <Button color="transparent" @click.native.stop="goTo('/chat')">
            <v-icon color="#4FAA6E">mdi-email-outline</v-icon>
            <span class="ml-2">{{ $t('appBar.organizationInfo.message') }}</span>
          </Button>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/layouts/dialog'
import Button from '@/components/elements/Button'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'doctorInfo',
  props: {
    visit: Object
  },
  components: { Dialog, Button },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    callToDoctor (phoneNumber) {
      if (phoneNumber) {
        window.open('tel:' + phoneNumber)
        const self = this
        setTimeout(function () {
          self.TOGGLE_DIALOG({ name: self.dialog.name, state: false })
        }, 200)
      }
    },
    goTo (route) {
      this.$router.push({ path: route })
      this.TOGGLE_DIALOG({ name: 'doctorInfo', isOpen: false })
    }
  },
  computed: mapState({
    dialog (state) {
      const modalName = this._.findKey(state.dialogs, ['isOpen', true])
      return state.dialogs[modalName]
    }
  })
}
</script>

<style scoped lang="scss">
.doctor-info {
  ::v-deep .v-dialog {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }
  &__header{
    width: inherit;
    .doctor-title {
      width: 180px;
      margin-left: 10px;
      font-size: 14px;
      color: #2D3748;
      font-weight: 600;
      .doctor-subtitle {
        font-size: 13px;
        font-weight: normal;
      }
    }
  }
  &__body {
    margin-top: 10px;
    .title {
      font-size: 14px!important;
      color: #718096;
    }
    .description {
      font-size: 13px;
      font-weight: bold;
      font-weight: bold;
    }
  }
  .w-100 {
    width: 100%;
  }
  @media screen and (max-width: 338px) {
    &__header{
      flex-wrap: wrap;
      justify-content: center;
      .doctor-title {
        width: 100%;
        margin-left: 0;
        text-align: center;
      }
    }
  }
}
</style>
