<template>
  <div class="login d-flex justify-center">
    <div class="login-area d-flex justify-center">
      <div class="login-area__form-wrapper d-flex flex-column">
        <div class="logo flex-grow-1 d-flex align-center">
          <Img :src="getImgWithExt(['images', 'logo_drawer', 'svg'])" width="172" />
        </div>
        <div class="login-area__form flex-grow-1">
          <login-form v-if="$route.name == 'SignIn'" />
          <registration-form v-if="$route.name == 'SignUp'" />
          <comfirm-account v-if="$route.name == 'ConfirmAccount'" />
          <redirect-auto-auth v-if="$route.name == 'RedirectAutoAuth'"/>
        </div>
      </div>
    </div>
    <div class="slider-area d-none d-lg-block">
      <div class="slider-area__carusel d-flex justify-center align-center">
        <carousel :items="items" />
      </div>
    </div>
  </div>
</template>

<script>
import Image from '@/mixins/Image'
import loginForm from '@/components/layouts/forms/login'
import registrationForm from '@/components/layouts/forms/registration'
import comfirmAccount from '@/views/confirm-account'
import carousel from '@/components/layouts/carusel'
import Img from '@/components/elements/Img'
import RedirectAutoAuth from '@/views/redirect-auto-auth.vue'

export default {
  name: 'login',
  mixins: [Image],
  components: { RedirectAutoAuth, loginForm, registrationForm, comfirmAccount, carousel, Img },
  computed: {
    items () {
      return [
        {
          src: this.getImgWithExt(['images', 'slider', 'slide1', 'jpeg']),
          content: 'Feeling Sluggish? Having trouble maintaining a healthy weight?<br> Ask your practitioner if completing a detox or purification program is right for you.'
        },
        {
          src: this.getImgWithExt(['images', 'slider', 'slide2', 'jpeg']),
          content: 'Confused about your protocol? Need to reorder products?<br> You can chat with your practitioner through the Portal and get help or answers to any of <br> your questions.'
        },
        {
          src: this.getImgWithExt(['images', 'slider', 'slide3', 'jpeg']),
          content: 'Our survey is designed to assess your nutritional needs from a<br> whole-body perspective, not just to address the symptoms, but to understand and <br> work to resolve any root causes.'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .login {
    .login-area,
    .slider-area {
      width: 50%;
      height: 100vh;
    }
    .login-area {
      background-color: #FFFFFF;
      @media (max-width: 960px) {
        width: 100%;
        padding: 0 30px;
      }
      &__form-wrapper {
        width: 439px;
      }
      &__form {
        width: 100%;
      }
    }
    .slider-area {
      position: relative;
      background-color: $dark-green;
      overflow: hidden;
      &__carusel {
        //position: absolute;
        //left: 15%;
        height: 100vh;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .login {
      .login-area {
        &__form-wrapper {
          width: 100%;
        }
      }
    }
  }
</style>
