<template>
  <div class="evaluations-page">
    <template v-if="$route.name === 'Evaluations'">
      <div class="pt-4">
        <Breadcrumbs/>
      </div>
<!--      <v-row no-gutters class="d-flex justify-space-between align-center">
        <v-col cols="12" class="mt-4">
          <span class="history-page__title">History</span>
        </v-col>
      </v-row>-->
      <template v-if="visitsHistory && visitsHistory.$values">
        <v-row no-gutters class="mb-2 mt-4 main-row">
          <template v-for="visit in visitsHistory.$values">
            <visit-info-block
              :componentFrom="'evaluations'"
              :key="visit.$id"
              v-if="visit.status === 'Completed'"
              :visit="visit"
              class="info-block"
            />
            <v-card
              :key="visit.$id"
              v-else
              :style="{'border-radius': '16px'}"
              elevation="2"
              class="survey info-block"
            >
              <v-card-title class="white--text">
                <div class="survey__main-row">
                  <div class="avatar-and-full-name">
                    <div class="avatar">
                      <v-avatar>
                        <v-img :src="visit.doctor.logoFull || visit.doctor.logoSmall"></v-img>
                      </v-avatar>
                    </div>
                    <div class="full-name">
                      <div>
                        <span class="position">{{$t('visit.doctor')}}</span>
                      </div>
                      <div>
                        <span class="name">{{visit.doctor.name}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="information">
                    <v-row no-gutters class="justify-end">
                      <Button
                        class="call"
                        outlined
                        color="#4FAA6E"
                        width="56"
                        height="56"
                        :styles="{'border': '2px solid'}"
                        :disabled="!visit.doctor.phone"
                        @click.native="callToDoctor(visitsInfo.doctor.phone)"
                      >
                        <v-icon color="#4FAA6E">mdi-phone-outline</v-icon>
                      </Button>
                      <Button
                        class="ml-2 chat"
                        outlined
                        color="#4FAA6E"
                        width="56"
                        height="56"
                        :styles="{'border': '2px solid'}"
                        @click.native="goTo('/chat')"
                      >
                        <v-icon color="#4FAA6E">mdi-chat-outline</v-icon>
                      </Button>
                    </v-row>
                  </div>
                </div>
                <div class="line"></div>
              </v-card-title>
              <v-card-text>
                <div class="survey__main-row">
                  <div class="status" :class="getStatusCodeName[visit.status].class">
                    <span class="status-name">{{ $t('visit.' + getStatusCodeName[visit.status].name)}}</span>
                  </div>
                  <div class="date">
                    <div class="text-date">
                      {{$t('visit.dueDate')}}
                    </div>
                    <div class="full-date" :class="getStatusCodeName[visit.status].class">
                      {{$moment(visit.date).format('MM/DD/YYYY')}}
                    </div>
                  </div>
                </div>
                <div class="content-text mt-4">
                  <p v-html="visit.notes"></p>
                </div>
              </v-card-text>
              <v-card-actions class="justify-center pb-4">
                <Button
                  dark
                  btn-bg="dark-green"
                  height="55"
                  @click.native="goTo('evaluations/visit/' + visit.id +'/survey')">
                  <span style="color: #FFFFFF">{{$t('visit.surveyLabel')}}</span>
                </Button>
              </v-card-actions>
            </v-card>
          </template>
        </v-row>
      </template>
    </template>
    <template v-else>
      <router-view/>
    </template>
  </div>
</template>

<script>
import VisitInfoBlock from '@/components/elements/visit-info-block'
import Breadcrumbs from '@/components/layouts/Breadcrumbs'
import Button from '@/components/elements/Button'
import Mapping from '@/mixins/Mapping'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'evaluations',
  components: { VisitInfoBlock, Button, Breadcrumbs },
  mixins: [Mapping],
  data: () => ({}),
  mounted () {
    document.title = 'Evaluations - Maestro Patient Portal'
    this.loadData()
  },
  methods: {
    ...mapActions('visits', ['getVisitsHistory']),
    loadData () {
      console.log('getVisitsHistory')
      this.getVisitsHistory()
    },
    goTo (route) {
      this.$router.push({ path: route })
    },
    callToDoctor (phoneNumber) {
      if (phoneNumber) {
        window.open('tel:' + phoneNumber)
        const self = this
        setTimeout(function () {
          self.TOGGLE_DIALOG({ name: self.dialog.name, state: false })
        }, 200)
      }
    }
  },
  computed: {
    ...mapState({
      visitsHistory: state => state.visits.visitHistory
    })
  }
}
</script>

<style scoped lang="scss">
.evaluations-page {
  padding: 0 16px;
  background: #F7FAFC;
  &__title {
    font-weight: 600;
    font-size: 22px;
    color: #1A202C;
  }
  &__due-date {
    font-weight: normal;
    font-size: 14px;
    color: #A0AEC0;
  }
  &__date {
    font-weight: 600;
    font-size: 14px;
    color: #1A202C;
  }

  .main-row {
    column-gap: 1rem;
    .info-block {
      margin-bottom: 10px;
      max-width: 30%;
      .button {
        width: 100%;
        ::v-deep .v-btn {
          width: 100%;
        }
      }
    }
    @media screen and (max-width: 700px) {
      .info-block {
        max-width: 100%;
      }
    }
  }

  .survey {
    width: 100%;
    &__main-row {
      display: flex;
      flex-wrap: wrap;
      column-gap: 1rem;
      row-gap: 1rem;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .avatar-and-full-name {
        display: flex;
        flex-wrap: wrap;
        column-gap: 1rem;
        justify-content: space-between;
        .avatar {
          width: 80px;
          ::v-deep .v-avatar {
            width: 70px!important;
            height: 70px!important;
          }
        }
        .full-name {
          color: #1A202C;
          .position {
            color: #718096;
            font-weight: 400;
            font-size: 16px;
          }
          .name {
            font-weight: 600;
            font-size: 16px;
            color: #1A202C;
          }
        }
      }
      .information {
        display: flex;
        .row {
          display: contents;
        }
      }
      .status {
        width: auto;
        height: 56px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        padding: 5px 10px;
        justify-content: center;
        .status-name {
          font-weight: 600;
          font-size: 14px;
        }
      }
      .date {
        width: 100px;
        text-align: end;
        font-size: 14px;
        .text-date {
          font-weight: 400;
          background: transparent!important;
        }
        .full-date {
          font-weight: 600;
          background: transparent!important;
        }
      }
    }
    .line {
      width: 100%;
      background: #d6d6d6;
      height: 1px;
      margin-top: 1rem;
    }
  }
  @media screen and (max-width: 320px) {
    .survey {
      ::v-deep .v-card__actions {
        .button {
          .v-btn {
            width: 80vw !important;
          }
        }
      }
    }
  }

  .need-product {
    background: rgba(255, 237, 227, 0.7)!important;
    color: #F7936F!important;
  }
  .in-active {
    background: #EDF2F7!important;
    color: #A0AEC0!important;
  }
  .need-survey {
    background: rgba(255, 237, 227, 0.7)!important;
    color: #F7936F!important;
  }
  .need-reeval {
    background: rgba(255, 237, 227, 0.7)!important;
    color: #F7936F!important;
  }
  .survey-sent {
    background: rgba(208, 220, 255, 0.5);
    color: #668BFF;
  }
  .survey-due {
    background: rgba(255, 230, 228, 0.7)!important;
    color: #F16063!important;
  }
  .need-review {
    background: rgba(238, 227, 255, 1)!important;
    color: #9959F8!important;
  }
  .reviewed {
    background: rgba(208, 220, 255, 0.5)!important;
    color: #668BFF!important;
  }
  .in-protocol {
    background: rgba(115, 217, 149, 0.2)!important;
    color: #4FAA6E!important;
  }
  .protocol-ending {
    background: #FFE9E2!important;
    color: #E69376!important;
  }
  .completed {
    background: rgba(141, 186, 106, 0.2)!important;
    color: #8DBA6A!important;
  }
  .in-progress {
    background: rgba(208, 220, 255, 0.5);
    color: #668BFF;
  }
}
</style>
