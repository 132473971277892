<template>
  <div class="confirm-password d-flex justify-center">
    <div class="form-area d-flex justify-center">
      <div class="form-area__form-wrapper d-flex flex-column">
        <!--<div class="logo flex-grow-1 d-flex align-center">
          <Img :src="responseResult.logoSmall" width="110" />
        </div>-->
        <div class="form-area__form flex-grow-1">
          <confirm-form-from-email v-if="$route.name == 'ConfirmPassword'" :responseResult="responseResult" />
        </div>
      </div>
    </div>
    <div class="slider-area d-none d-lg-block">
      <div class="slider-area__carusel d-flex justify-center align-center">
        <carousel :items="items" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import Validation from '@/mixins/Validation'
import carousel from '@/components/layouts/carusel'
import Image from '@/mixins/Image'
import confirmFormFromEmail from '@/components/layouts/forms/confirmFormFromEmail'

export default {
  name: 'confirmPassword',
  mixins: [Validation, Image],
  components: {
    confirmFormFromEmail,
    carousel
  },
  data: () => ({
    form: {
      password: '',
      confirmPassword: '',
      userId: '',
      token: '',
      type: ''
    },
    responseResult: null
  }),
  async mounted () {
    document.title = 'Confirm Password Maestro Patient Portal'
    const { id, token, type } = this.$route.params // , type
    this.form.userId = id
    this.form.token = token
    this.form.type = type
    const result = await this.passwordConfirmation({ id, token, type }) // , type
    this.responseResult = {
      userId: result.record ? result.record.id : '',
      message: result.message,
      practiceName: result.record ? result.record.practiceName : '',
      practitionerName: result.record ? result.record.practitionerName : '',
      practiceTitle: result.record ? result.record.practiceTitle : '',
      logoSmall: result.record ? result.record.logoSmall : ''
    }
  },
  methods: {
    ...mapActions(['passwordUpdate', 'passwordConfirmation']),
    confirm () {
      // const { id } = this.$route.params
      console.log('confirm', this.form)
      this.passwordUpdate(this.form)
    }
  },
  computed: {
    items () {
      return [
        {
          src: this.getImgWithExt(['images', 'slider', 'slide1', 'jpeg']),
          content: 'Feeling Sluggish? Having trouble maintaining a healthy weight?<br> Ask your practitioner if completing a detox or purification program is right for you.'
        },
        {
          src: this.getImgWithExt(['images', 'slider', 'slide2', 'jpeg']),
          content: 'Confused about your protocol? Need to reorder products?<br> You can chat with your practitioner through the Portal and get help or answers to any of your questions.'
        },
        {
          src: this.getImgWithExt(['images', 'slider', 'slide3', 'jpeg']),
          content: 'Our survey is designed to assess your nutritional needs from a<br> whole-body perspective, not just to address the symptoms, but to understand and work to resolve any root causes.'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.confirm-password {
  .form-area,
  .slider-area {
    width: 50%;
    height: 100vh;
  }
  .form-area {
    background-color: #FFFFFF;
    @media (max-width: 960px) {
      width: 100%;
      padding: 0 30px;
    }
    &__form-wrapper {
      width: 439px;
    }
    &__form {
      width: 100%;
    }
  }
  .slider-area {
    position: relative;
    background-color: $dark-green;
    overflow: hidden;
    &__carusel {
      position: absolute;
      left: 15%;
      height: 100vh;
    }
  }
}
</style>
