import Vue from 'vue'

const state = () => ({
  organization: null
})

const getters = {}

const actions = {
  async getCurrentOrganization ({ commit }) {
    const result = await Vue.$http.get('/Organization/Current')
    if (result?.status === 200) {
      commit('SET_DATA', ['organization', result.data])
    }
  },
  async updateOrganization ({ _ }, params) {
    const { id } = params
    const result = await Vue.$http.put(`/Organization/${id}/Update`, { ...params })
    if (result?.staus === 200) {
      console.log(params)
      console.log(result)
    }
  },
  async inviteDoctor ({ commit }, params) {
    const result = await Vue.$http.post('/organization/InviteDoctor', { ...params })
    if (result?.status === 200) {
      console.log(`Respons: The user is registerd; Status: ${result?.status}`)
      // router.push('/').catch(() => {})
    }
    return result?.data
  }
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
