<template>
  <Input
    input-class="login"
    outlined
    height="56"
    radius="16"
    tabindex="1"
    background-color="#EDF2F7"
    placeholder="Type your message"
    :value="text"
    @input="text = $event"
    @enter="send"
    @focus="markAsReadMessage"
    @keydown="markAsReadMessage"
  />
</template>

<script>
import Input from '@/components/elements/Input'
export default {
  name: 'ChatForm',
  components: { Input },
  data: () => ({
    text: ''
  }),
  methods: {
    send () {
      this.$emit('sendNewMessage', this.text)
      this.text = ''
    },
    markAsReadMessage () {
      this.$emit('markAsReadMessage')
    }
  }
}
</script>

<style scoped>

</style>
