<template>
  <div class="schedule">
    <template v-if="scheduleData && scheduleData.resultSupplements">
      <v-row no-gutters class="d-flex justify-space-between align-center pt-4 schedule__block">
        <v-col md5 sm12>
          <span class="schedule__title">{{ $t('schedule.title') }}</span>
          <div class="schedule__date">From {{$moment(scheduleData.visitData.visitDate).format('MM/DD/YYYY')}} Evaluation</div>
        </v-col>
        <v-col md7 sm12 style="text-align: right">
          <span class="schedule__days-title">{{ $t('schedule.daysSupply') }} </span>
          <span class="schedule__days-value">{{scheduleData.visitData.resultDaysSupply}}</span>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-4 schedule__block">
        <v-col>
          <v-card
            :style="{'border-radius': '16px'}"
            elevation="2"
            class="card-schedule"
          >
            <v-card-text style="padding-bottom: 0">
              <div class="schedule-table-desktop-mobile">
                <v-simple-table>
                  <template v-slot:default>
                    <thead class="header">
                    <tr>
                      <th>{{ $t('schedule.supplement') }}</th>
                      <th>{{ $t('schedule.uponArising') }}</th>
                      <th>{{ $t('schedule.breakfast') }}</th>
                      <th>{{ $t('schedule.10am') }}</th>
                      <th>{{ $t('schedule.lunch') }}</th>
                      <th>{{ $t('schedule.3pm') }}</th>
                      <th>{{ $t('schedule.dinner') }}</th>
                      <th>{{ $t('schedule.7pm') }}</th>
                      <th>{{ $t('schedule.beforeSleep') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in scheduleData.resultSupplements.$values" :key="item.$id">
                      <td class="dynamic-device">
                        <div class="small-device-version mobile-version">{{ $t('schedule.supplement') }}</div>
                        <div class="schedule-table-value">
                          <div class="custom-block d-flex justify-space-between align-center">
                            <div style="margin-left: 15px;text-align: justify">
                              <v-row>
                                <span class="schedule-table-value__name">{{item.supplementName}}</span>
                              </v-row>
                              <v-row class="schedule-table-value__number">
                                <span></span>
                              </v-row>
                            </div>
                            <div class="schedule-table-value__info">
                              <v-icon color="#CBD5E0">mdi-information</v-icon>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="dynamic-device">
                        <div class="small-device-version">{{ $t('schedule.uponArising') }}</div>
                        <div class="schedule-table-value">
                          <span class="schedule-table-value__default">{{item.dosageUponArising || ''}}</span>
                        </div>
                      </td>
                      <td class="dynamic-device">
                        <div class="small-device-version">{{ $t('schedule.breakfast') }}</div>
                        <div class="schedule-table-value">
                          <span class="schedule-table-value__default">{{item.dosageBreakfast || ''}}</span>
                        </div>
                      </td>
                      <td class="dynamic-device">
                        <div class="small-device-version">{{ $t('schedule.10am') }}</div>
                        <div class="schedule-table-value">
                          <span class="schedule-table-value__default">{{item.dosage10am || ''}}</span>
                        </div>
                      </td>
                      <td class="dynamic-device">
                        <div class="small-device-version">{{ $t('schedule.lunch') }}</div>
                        <div class="schedule-table-value">
                          <span class="schedule-table-value__default">{{item.dosageLunch || ''}}</span>
                        </div>
                      </td>
                      <td class="dynamic-device">
                        <div class="small-device-version">{{ $t('schedule.3pm') }}</div>
                        <div class="schedule-table-value">
                          <span class="schedule-table-value__default">{{item.dosage3pm || ''}}</span>
                        </div>
                      </td>
                      <td class="dynamic-device">
                        <div class="small-device-version">{{ $t('schedule.dinner') }}</div>
                        <div class="schedule-table-value">
                          <span class="schedule-table-value__default">{{item.dosageDinner || ''}}</span>
                        </div>
                      </td>
                      <td class="dynamic-device">
                        <div class="small-device-version">{{ $t('schedule.7pm') }}</div>
                        <div class="schedule-table-value">
                          <span class="schedule-table-value__default">{{item.dosage7pm || ''}}</span>
                        </div>
                      </td>
                      <td class="dynamic-device">
                        <div class="small-device-version">{{ $t('schedule.beforeSleep') }}</div>
                        <div class="schedule-table-value">
                          <span class="schedule-table-value__default">{{item.dosageBeforeSleep || ''}}</span>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-6 schedule__block">
        <v-col cols="12">
          <Button
            dark
            outlined
            height="55"
            @click.native="downloadFile"
            width="100%"
            :styles="{ 'border-color': '#A6B7D4' }"
            color="transparent">
          <span style="color: #16192C;font-weight: 600;font-size: 12px;line-height: 12px;">
            <v-icon color="#A6B7D4">mdi-tray-arrow-down</v-icon>
            {{ $t('schedule.downloadPDF') }}
          </span>
          </Button>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <div class="empty-data">No active Schedule</div>
    </template>
  </div>
</template>

<script>
import Button from '@/components/elements/Button'
import { mapActions, mapState } from 'vuex'
import DownloadFile from '@/mixins/DownloadFile'

export default {
  name: 'Schedule',
  props: {
    visitId: {
      type: String
    }
  },
  mixins: [DownloadFile],
  components: { Button },
  mounted () {
    this.loadData()
  },
  methods: {
    ...mapActions('reports', ['getSchedule', 'gerReportByCode']),
    loadData () {
      this.getSchedule(this.$route.params.visitId)
    },
    async downloadFile () {
      const payload = {
        appUserId: this.userProfile.userId,
        reportCode: 'PatientSchedule',
        entityId: this.$route.params.visitId
      }
      const res = await this.gerReportByCode(payload)
      this.resolveAndDownloadBlob(res, 'pdf')
    }
  },
  computed: {
    ...mapState({
      scheduleData: state => state.reports.schedule,
      userProfile: state => state.userProfile
    })
  }
}
</script>

<style scoped lang="scss">
.schedule {
  padding: 0 16px;
  background: #F7FAFC;
  min-height: 100vh;

  .empty-data {
    text-align: center;
    min-height: calc(100vh - 110px);
  }

  &__title {
    font-weight: 600;
    font-size: 24px;
    color: #1A202C;
  }
  &__date {
    font-size: 13px;
    font-weight: bold;
    color: grey;
  }
  &__days-title {
    font-weight: normal;
    font-size: 14px;
    color: #A0AEC0;
  }
  &__days-value {
    font-weight: 600;
    font-size: 14px;
    color: #1A202C;
  }
  .schedule-table-desktop-mobile {
    display: block;
    width: 100%;
    position: relative;
    ::v-deep .v-data-table .v-data-table__wrapper .header tr th {
      padding: 0 6px !important;
    }
    .header {
      tr > th {
        color: #8492A6;
        font-weight: 600;
        font-size: 10px;
        text-align: center;
        border-right: thin solid rgba(0, 0, 0, 0.12);
      }
      tr > th:last-child {
        border-right: unset;
      }
    }
    .dynamic-device {
      border-right: thin solid rgba(0, 0, 0, 0.12);
      padding: 0 4px !important;
      .schedule-table-value {
        text-align: center;
        &__default {
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          color: #425466;
        }
        &__name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #425466;
        }
        &__number {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: #A0AEC0;
        }
      }
      .small-device-version {
        display: none;
        color: #8492A6;
        font-weight: 600;
        font-size: 10px;
      }
    }
    .dynamic-device:last-child {
      border-right: unset;
    }
  }
  &__block-total {
    .total-title {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 23px;
      color: #718096;
    }
    .total-title.bold {
      font-weight: 600;
      font-size: 14px;
      color: #2D3748;
    }
    .total-value {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 23px;
      color: #718096;
    }
    .total-value.bold {
      font-weight: 600;
      font-size: 14px;
      color: #718096;
    }
  }

  @media screen and (min-width: 840px) {
    &__block {
      width: 65%;
    }
  }

  @media (max-width: 485px) {
    .schedule-table-desktop-mobile {
      .header {
        display: none !important;
        tr > th {
          border-right: unset;
        }
      }
      .dynamic-device {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-right: unset;
        border-bottom: thin solid rgba(0, 0, 0, 0.12);
        .small-device-version {
          display: block;
        }
        .schedule-table-value {
          .custom-block {
            flex-direction: row-reverse;
          }
          &__number {
            justify-content: flex-end;
          }
          &__name {
            text-align: right;
            padding-right: 9px;
          }
        }
      }
    }
  }
}
</style>
