<template>
  <v-footer
    class="footer"
    color="#FFFFFF"
    padless
    app
    bottom
    fixed
    elevation="3"
    :styles="styles"
  >
    <v-row
      justify="space-around"
      no-gutters
      v-if="dashboardInfo"
    >
      <router-link exact to="/">
        <v-btn
          class="my-2 footer-link"
          icon
        >
          <div class="footer-link__content">
            <v-icon color="#718096">mdi-home-outline</v-icon>
            <span>{{ $t('footer.menu.home') }}</span>
          </div>
        </v-btn>
      </router-link>
<!--      <router-link exact :to="goToSurvey('/visit')" :class="{ disabled: !dashboardInfo.menuRefs.surveyVisitId }">
        <v-btn
          class="my-2 footer-link"
          icon
        >
          <div class="footer-link__content">
            <v-icon color="#718096">mdi-badge-account-outline</v-icon>
            <span>{{ $t('footer.menu.survey') }}</span>
          </div>
        </v-btn>
      </router-link>
      <router-link exact :to="goTo('/visit')" :class="{ disabled: !dashboardInfo.menuRefs.surveyVisitId }">
        <v-btn
          class="my-2 footer-link"
          icon
        >
          <div class="footer-link__content">
            <Img :src="getImgWithExt(['icons', $route.path === '/results' ? 'tablet-green' : 'tablet-icon', 'svg'])" width="20"/>
            <span style="margin-top: 5px;">{{ $t('footer.menu.results') }}</span>
          </div>
        </v-btn>
      </router-link>-->
      <router-link exact :to="goToEvaluations('/evaluations')" :class="{ disabled: !dashboardInfo.menuRefs.surveyVisitId }">
        <v-btn
          class="my-2 footer-link"
          icon
        >
          <div class="footer-link__content">
            <v-icon color="#718096">mdi-badge-account-outline</v-icon>
            <span>Evaluations</span>
          </div>
        </v-btn>
      </router-link>
      <router-link exact to="/chat">
        <v-btn
          class="my-2 footer-link"
          icon
        >
          <div class="footer-link__content">
            <v-badge
              color="#008C89"
              overlap
              dot
              offset-y="10"
              bordered
            >
              <v-icon color="#718096">mdi-chat-outline</v-icon>
            </v-badge>
            <span>{{ $t('footer.menu.chat') }}</span>
          </div>
        </v-btn>
      </router-link>
    </v-row>
  </v-footer>
</template>

<script>
import Image from '@/mixins/Image'
// import Img from '@/components/elements/Img'
import { mapState } from 'vuex'

export default {
  props: {
    styles: {
      type: Object
    }
  },
  mixins: [Image],
  // components: { Img },
  name: 'Footer',
  methods: {
    goTo (path) {
      if (this.dashboardInfo && this.dashboardInfo.menuRefs) {
        return path + `/${this.dashboardInfo.menuRefs.scheduleVisitId}/schedule`
      } else {
        return this.$route.path
      }
    },
    goToSurvey (path) {
      if (this.dashboardInfo && this.dashboardInfo.menuRefs) {
        return path + `/${this.dashboardInfo.menuRefs.surveyVisitId}/survey`
      } else {
        return this.$route.path
      }
    },
    goToEvaluations (path) {
      return path
    }
  },
  computed: {
    ...mapState({
      dashboardInfo: state => state.patients.dashboardInfo
    })
  }
}
</script>

<style scoped lang="scss">
.footer {
  .row {
    .disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    }
    a {
      text-decoration: none;
      .footer-link {
        &__content {
          display: flex;
          flex-direction: column;
          align-items: center;
          span {
            color: #718096;
            font-family: Poppins;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            text-transform: none;
          }
        }
      }
    }
    .router-link-active {
      .footer-link {
        &__content {
          i {
            color: $dark-green !important;
          }
          span {
            color: $dark-green;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 760px) {
  .footer {
    display: none;
  }
}
</style>
