import Vue from 'vue'

const state = () => ({
  supplements: []
})

const getters = {}

const actions = {
  supplementsDropdown ({ _ }, name) {
    return new Promise((resolve) => {
      Vue.$http.get(`/Supplements/SupplementsDropdown?search=${name}`).then(result => {
        resolve(result?.data?.record)
      })
    })
  },
  getDefaultDosagesBySupplement ({ _ }, id) {
    return new Promise((resolve) => {
      Vue.$http.get(`/Supplements/${id}/Get`).then(result => {
        resolve(result?.data?.record)
      })
    })
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
