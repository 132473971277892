const ACCESSIBILITY_EXT = ['svg', 'jpg', 'jpeg']

const Image = {
  methods: {
    /**
     * The function 'getImgWithExt' returns path to svg icon.
     * Example: ['article', 'logo', 'jpg']
     *
     * @date 2020-11-02
     * @param {Array} params - path is present as array
     * @returns {String} - path to icon
     */
    getImgWithExt (params) {
      const extenstion = params.pop()
      const path = params.filter((el, index, array) => (el.length && (index + 1) <= array.length)).join('/')

      if (!params.length) {
        console.error('You don`t pass params')
        return
      }

      if (!ACCESSIBILITY_EXT.includes(extenstion)) {
        console.error('you are using an invalid extension')
        return
      }
      return require(`@/assets/${path}.${extenstion}`)
    }
  }
}

export default Image
