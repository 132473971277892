<template>
  <div class="receipt">
    <v-row no-gutters class="d-flex justify-space-between align-center pt-4 receipt__block">
      <v-col md5 sm12>
        <span class="receipt__title">{{ $t('receipts.title') }}</span>
      </v-col>
      <v-col md7 sm12 style="text-align: right">
        <span class="receipt__days-title">{{ $t('receipts.daysSupply') }} </span>
        <span class="receipt__days-value">{{currentReceiptReport.daysSupply}}</span>
      </v-col>
    </v-row>
    <v-row no-gutters class="receipt__block justify-center" v-if="currentReceiptReport.orderType && currentReceiptReport.orderType.code === 'DropShip'">
      <span>Please process the drop ship order as shown below.</span>
    </v-row>
    <v-row no-gutters class="receipt__block">
      <v-row>
        <v-col cols="6">
          <div class="main-row justify-center" v-if="currentReceiptReport.orderType.code === 'DropShip'">
            <span>Ship To</span>
          </div>
          <ul class="custom-list" v-if="currentReceiptReport && currentReceiptReport.billToAddress">
            <li>{{currentReceiptReport.billToAddress.name || '-'}}</li>
            <li>{{currentReceiptReport.billToAddress.line1 || '-'}}</li>
            <li>{{currentReceiptReport.billToAddress.email || '-'}}</li>
            <li>{{currentReceiptReport.billToAddress.phone || '-'}}</li>
            <li>{{currentReceiptReport.billToAddress.state || '-'}}</li>
          </ul>
          <div v-if="currentReceiptReport">Evaluation Date: {{currentReceiptReport.evaluationDate ? $moment(currentReceiptReport.evaluationDate).format('MM/DD/YYYY') : '-'}}</div>
        </v-col>
        <v-col cols="6">
          <div class="main-row justify-center" v-if="currentReceiptReport.orderType.code === 'DropShip'">
            <span>Bill To</span>
          </div>
          <ul class="custom-list" v-if="currentReceiptReport && currentReceiptReport.shipToAddress">
            <li>{{currentReceiptReport.shipToAddress.name || '-'}}</li>
            <li>{{currentReceiptReport.shipToAddress.line1 || '-'}}</li>
            <li>{{currentReceiptReport.shipToAddress.phone || '-'}}</li>
            <li>{{currentReceiptReport.shipToAddress.email || '-'}}</li>
            <li>{{currentReceiptReport.shipToAddress.state || '-'}}</li>
          </ul>
          <div v-if="currentReceiptReport">Days Supply: {{currentReceiptReport.daysSupply || '-'}}</div>
          <div v-if="currentReceiptReport && currentReceiptReport.orderType">SP Account Number: {{currentReceiptReport.orderType.accountNumber || '-'}}</div>
        </v-col>
      </v-row>
    </v-row>
    <v-row no-gutters class="d-flex receipt__block justify-center">
      <span>{{currentReceiptReport.orderType.code || '-'}}</span>
    </v-row>
    <v-row no-gutters class="mt-4 receipt__block">
      <v-col>
        <v-card
          :style="{'border-radius': '16px'}"
          elevation="2"
          class="card-receipt"
        >
          <v-card-text style="padding-bottom: 0">
            <div class="receipt-table-desktop-mobile">
              <v-simple-table>
                <template v-slot:default>
                  <thead class="header">
                  <tr>
                    <th>{{$t('receipts.productCode')}}</th>
                    <th>{{$t('receipts.quantity')}}</th>
                    <th>{{$t('receipts.product')}}</th>
                    <th>{{$t('receipts.size')}}</th>
                    <th>{{$t('receipts.unit')}}</th>
                    <th>{{$t('receipts.total')}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in currentReceiptReport.products.$values" :key="item.id">
                    <td class="dynamic-device">
                      <div class="small-device-version mobile-version">{{$t('receipts.productCode')}}</div>
                      <div class="receipt-table-value">
                        <div class="custom-block d-flex justify-space-between align-center">
                          <div style="margin-left: 15px;text-align: justify">
                            <v-row>
                              <span class="receipt-table-value__name">{{item.productNumber}}</span>
                            </v-row>
                          </div>
                          <div class="receipt-table-value__info">
                            <v-icon color="#CBD5E0">mdi-information</v-icon>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="dynamic-device">
                      <div class="small-device-version">{{$t('receipts.quantity')}}</div>
                      <div class="receipt-table-value">
                        <span class="receipt-table-value__default">{{item.quantity}}</span>
                      </div>
                    </td>
                    <td class="dynamic-device">
                      <div class="small-device-version mobile-version">{{$t('receipts.supplement')}}</div>
                      <div class="receipt-table-value">
                        <div class="custom-block d-flex justify-space-between align-center">
                          <div style="margin-left: 15px;text-align: justify">
                            <v-row>
                              <span class="receipt-table-value__name">{{item.productName}}</span>
                            </v-row>
                            <v-row class="receipt-table-value__number">
                              <span>{{item.productCode}}</span>
                            </v-row>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="dynamic-device">
                      <div class="small-device-version">{{$t('receipts.size')}}</div>
                      <div class="receipt-table-value">
                        <span class="receipt-table-value__default">{{item.size}}</span>
                      </div>
                    </td>
                    <td class="dynamic-device">
                      <div class="small-device-version">{{$t('receipts.unit')}}</div>
                      <div class="receipt-table-value">
                        <span class="receipt-table-value__default">${{item.unitPrice.toFixed(2)}}</span>
                      </div>
                    </td>
                    <td class="dynamic-device">
                      <div class="small-device-version">{{$t('receipts.total')}}</div>
                      <div class="receipt-table-value">
                        <span class="receipt-table-value__default">${{item.totalCost.toFixed(2)}}</span>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-card-text>
          <v-card-text style="padding: 0;background-color: #EDF2F7">
            <div class="receipt__block-total mt-6 pr-8 pl-8 pb-12 pt-8">
              <v-row class="justify-end additional-table mb-2">
                <v-simple-table>
                  <template v-slot:default>
                    <thead class="header">
                    </thead>
                    <tbody>
                    <tr v-if="currentReceiptReport && currentReceiptReport.orderType && currentReceiptReport.orderType.priceModifier !== 0">
                      <td class="dynamic-device">
                        <div class="d-flex align-center justify-center">
                          <div class="d-flex">
                            <span>Total product price</span>
                          </div>
                        </div>
                      </td>
                      <td class="dynamic-device">
                        <div class="d-flex align-center justify-center">
                          <div class="d-flex">
                            <span v-if="currentReceiptReport && currentReceiptReport.totalProductsPrice">${{currentReceiptReport.totalProductsPrice}}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="currentReceiptReport && currentReceiptReport.discountAmount !== 0">"
                      <td class="dynamic-device" style="border-top:thin solid #0000001f !important;border-top-left-radius: 10px!important;">
                        <div class="d-flex align-center justify-center">
                          <div class="d-flex">
                            <span>Discount Amt</span>
                          </div>
                        </div>
                      </td>
                      <td class="dynamic-device" style="border-top:thin solid #0000001f !important;border-top-right-radius: 10px!important;">
                        <div class="d-flex align-center justify-center">
                          <div class="d-flex">
                            <span>{{currentReceiptReport.discountAmount}}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="currentReceiptReport && currentReceiptReport.tax !== 0">
                      <td class="dynamic-device">
                        <div class="d-flex align-center justify-center">
                          <div class="d-flex">
                            <span>Tax</span>
                          </div>
                        </div>
                      </td>
                      <td class="dynamic-device">
                        <div class="d-flex align-center justify-center">
                          <div class="d-flex">
                            <span>{{currentReceiptReport.tax}}%</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="currentReceiptReport && currentReceiptReport.orderType && currentReceiptReport.orderType.shippingFee !== 0">
                      <td class="dynamic-device">
                        <div class="d-flex align-center justify-center">
                          <div class="d-flex">
                            <span>Shipping</span>
                          </div>
                        </div>
                      </td>
                      <td class="dynamic-device">
                        <div class="d-flex align-center justify-center">
                          <div class="d-flex">
                            <span v-if="currentReceiptReport && currentReceiptReport.orderType">${{currentReceiptReport.orderType.shippingFee}}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="currentReceiptReport && currentReceiptReport.orderType && currentReceiptReport.orderType.priceModifier !== 0">
                      <td class="dynamic-device">
                        <div class="d-flex align-center justify-center">
                          <div class="d-flex">
                            <span>Price modifier</span>
                          </div>
                        </div>
                      </td>
                      <td class="dynamic-device">
                        <div class="d-flex align-center justify-center">
                          <div class="d-flex">
                            <span v-if="currentReceiptReport && currentReceiptReport.orderType">{{currentReceiptReport.orderType.priceModifier}}%</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="currentReceiptReport && currentReceiptReport.visitFee !== 0">
                      <td class="dynamic-device">
                        <div class="d-flex align-center justify-center">
                          <div class="d-flex">
                            <span>Visit Fee</span>
                          </div>
                        </div>
                      </td>
                      <td class="dynamic-device">
                        <div class="d-flex align-center justify-center">
                          <div class="d-flex">
                            <span>{{currentReceiptReport.visitFee}}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="currentReceiptReport && currentReceiptReport.otherFee !== 0">
                      <td class="dynamic-device">
                        <div class="d-flex align-center justify-center">
                          <div class="d-flex">
                            <span>Other Fee</span>
                          </div>
                        </div>
                      </td>
                      <td class="dynamic-device">
                        <div class="d-flex align-center justify-center">
                          <div class="d-flex">
                            <span>{{currentReceiptReport.otherFee}}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="currentReceiptReport && currentReceiptReport.totalPrice !== 0">
                      <td class="dynamic-device">
                        <div class="d-flex align-center justify-center">
                          <div class="d-flex">
                            <span>Total</span>
                          </div>
                        </div>
                      </td>
                      <td class="dynamic-device">
                        <div class="d-flex align-center justify-center">
                          <div class="d-flex">
                            <span>${{currentReceiptReport.totalPrice}}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <span class="total-title">{{$t('receipts.productTotal')}}</span>
                  </v-row>
<!--                  <v-row>
                    <span class="total-title">{{$t('receipts.nextDayCharge')}}</span>
                  </v-row>-->
                  <v-row>
                    <span class="total-title">{{$t('receipts.visitFee')}}</span>
                  </v-row>
                  <v-row>
                    <span class="total-title bold">{{$t('receipts.totalDue')}}</span>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row class="justify-end">
                    <span class="total-value">${{currentReceiptReport.totalPrice}}</span>
                  </v-row>
<!--                  <v-row class="justify-end">
                    <span class="total-value">{{currentReceiptReport.nextDayCharge}}</span>
                  </v-row>-->
                  <v-row class="justify-end">
                    <span class="total-value">{{currentReceiptReport.visitFee}}</span>
                  </v-row>
                  <v-row class="justify-end">
                    <span class="total-value bold">${{currentReceiptReport.totalPrice}}</span>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-6 receipt__block">
      <v-col cols="12">
        <Button
          dark
          outlined
          height="55"
          @click.native="downloadFile"
          width="100%"
          :styles="{ 'border-color': '#A6B7D4' }"
          color="transparent">
          <span style="color: #16192C;font-weight: 600;font-size: 12px;line-height: 12px;">
            <v-icon color="#A6B7D4">mdi-tray-arrow-down</v-icon>
            {{$t('receipts.downloadPDF')}}
          </span>
        </Button>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Button from '@/components/elements/Button'
import { mapActions, mapState } from 'vuex'
import DownloadFile from '@/mixins/DownloadFile'

export default {
  name: 'receipt',
  props: {
    visitId: {
      type: String
    }
  },
  mixins: [DownloadFile],
  components: { Button },
  mounted () {
    this.loadData()
  },
  methods: {
    ...mapActions('reports', ['getReceipts', 'getReceipt', 'gerReportByCode']),
    loadData () {
      this.getReceipts(this.$route.params.visitId)
      this.getReceipt({ resultId: this.visitsInfo.resultId, receiptId: this.$route.query.receiptId })
    },
    async downloadFile () {
      const payload = {
        appUserId: this.userProfile.userId,
        reportCode: 'patient_receipt',
        entityId: this.$route.params.visitId
      }
      const res = await this.gerReportByCode(payload)
      this.resolveAndDownloadBlob(res, 'pdf')
    }
  },
  computed: {
    ...mapState({
      receiptsData: state => state.reports.receipts,
      currentReceiptReport: state => state.reports.currentReceiptReport,
      userProfile: state => state.userProfile
    }),
    visitsInfo () {
      return this.$store.state.visits.visitsInfo
    }
  }
}
</script>

<style scoped lang="scss">
.receipt {
  padding: 0 16px;
  background: #F7FAFC;
  min-height: 100vh;

  .custom-list {
    list-style-type: none;
    padding-top: 0;
  }

  &__title {
    font-weight: 600;
    font-size: 24px;
    color: #1A202C;
  }
  &__days-title {
    font-weight: normal;
    font-size: 14px;
    color: #A0AEC0;
  }
  &__days-value {
    font-weight: 600;
    font-size: 14px;
    color: #1A202C;
  }
  .receipt-table-desktop-mobile {
    display: block;
    width: 100%;
    position: relative;
    ::v-deep .v-data-table .v-data-table__wrapper .header tr th {
      padding: 0 6px !important;
    }
    .header {
      tr > th {
        color: #8492A6;
        font-weight: 600;
        font-size: 10px;
        text-align: center;
        border-right: thin solid rgba(0, 0, 0, 0.12);
      }
      tr > th:last-child {
        border-right: unset;
      }
    }
    .dynamic-device {
      border-right: thin solid rgba(0, 0, 0, 0.12);
      padding: 0 4px !important;
      .receipt-table-value {
        text-align: center;
        &__default {
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          color: #425466;
        }
        &__name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #425466;
        }
        &__number {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: #A0AEC0;
        }
      }
      .small-device-version {
        display: none;
        color: #8492A6;
        font-weight: 600;
        font-size: 10px;
      }
    }
    .dynamic-device:last-child {
      border-right: unset;
    }
  }
  &__block-total {
    .total-title {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 23px;
      color: #718096;
    }
    .total-title.bold {
      font-weight: 600;
      font-size: 14px;
      color: #2D3748;
    }
    .total-value {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 23px;
      color: #718096;
    }
    .total-value.bold {
      font-weight: 600;
      font-size: 14px;
      color: #718096;
    }
  }

  @media screen and (min-width: 840px) {
    &__block {
      width: 65%;
    }
  }

  @media (max-width: 485px) {
    .receipt-table-desktop-mobile {
      .header {
        display: none !important;
        tr > th {
          border-right: unset;
        }
      }
      .dynamic-device {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-right: unset;
        border-bottom: thin solid rgba(0, 0, 0, 0.12);
        .small-device-version {
          display: block;
        }
        .receipt-table-value {
          .custom-block {
            flex-direction: row-reverse;
          }
          &__number {
            justify-content: flex-end;
          }
          &__name {
            text-align: right;
            padding-right: 9px;
          }
        }
      }
    }
  }
}
</style>
