import { render, staticRenderFns } from "./redirect-auto-auth.vue?vue&type=template&id=7df610ce&scoped=true&"
import script from "./redirect-auto-auth.vue?vue&type=script&lang=js&"
export * from "./redirect-auto-auth.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7df610ce",
  null
  
)

export default component.exports