import Vue from 'vue'

const state = () => ({
  auth: { isOpen: false, name: '' },
  forgot: { isOpen: false, name: '' },
  registration: { isOpen: false, name: '' },
  addPatient: { isOpen: false, name: '', title: 'Add Patient' },
  editPatient: { id: 0, isOpen: false, name: '', title: 'Edit patient' },
  addVisit: { isOpen: false, name: '', title: 'Add Evaluation' },
  editVisit: { isOpen: false, name: '', title: 'Edit Evaluation' },
  confirm: { isOpen: false, name: '', title: 'Confirm dialog', action: '' },
  addResult: { isOpen: false, name: '', title: 'Add result' },
  editResult: { isOpen: false, name: '', title: 'Edit result' },
  inviteDoctors: { isOpen: false, name: '', title: 'Invite Practitioner' },
  organizationInfo: { isOpen: false, name: '' },
  doctorInfo: { isOpen: false, name: '' },
  uploadAvatar: { isOpen: false, name: '' },
  termAndConditions: { isOpen: false, name: '', title: '' }
})

const getters = {}

const actions = {}

const mutations = {
  TOGGLE_DIALOG: (state, payload) => {
    Vue.set(state, payload.name, payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
