import Vue from 'vue'

const state = () => ({
  visit: null,
  results: [],
  result: null
})

const getters = {}

const actions = {
  async getResults ({ commit }, id) {
    const result = await Vue.$http.get(`Results/${id}/Get`)
    if (result?.status === 200) {
      commit('SET_DATA', ['results', result.data])
    }
  },
  async addResult ({ commit }, params) {
    const { id, form } = params
    const result = await Vue.$http.post(`Results/${id}/ResultSupplements/Add`, { ...form })
    if (result?.status === 200) {
      commit('dialogs/TOGGLE_DIALOG', { name: 'addResult', isOpen: false }, { root: true })
    }
  },
  async getResult ({ commit }, id) {
    const result = await Vue.$http.get(`Results/ResultSupplemetns/${id}/Get`)
    if (result?.status === 200) {
      commit('SET_DATA', ['result', result.data?.record])
    }
  },
  async updateResult ({ commit }, params) {
    const { id, form } = params
    const result = await Vue.$http.put(`Results/ResultSupplemetns/${id}/Update`, { ...form })
    if (result?.status === 200) {
      commit('dialogs/TOGGLE_DIALOG', { name: 'editResult', isOpen: false }, { root: true })
    }
    return result?.data
  },
  deleteResult ({ _ }, id) {
    return new Promise((resolve) => {
      Vue.$http.delete(`/Results/ResultSupplements/${id}/Delete`).then((result) => {
        if (result?.status === 200) {
          resolve()
          console.log(`Respons: The result was delete; Status: ${result?.status}`)
        }
      })
    })
  }
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
