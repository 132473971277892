<template>
  <div class="home-page">
    <div class="mobile-version">
      <v-row no-gutters>
        <v-col cols="6">
          <v-row no-gutters v-if="dashboardInfo">
            <v-avatar>
              <v-img :src="dashboardInfo.doctor.logoSmall"></v-img>
            </v-avatar>
            <div class="home-page__doctor-name">
              <div class="position">Practitioner</div>
              <div class="name">{{dashboardInfo.doctor.name}}</div>
            </div>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row no-gutters class="justify-end">
            <Button
              class="mr-2"
              outlined
              color="#008C89"
              width="190"
              height="56"
              :styles="{'border': '2px solid'}"
              v-if="dashboardInfo.canCreateNewSurvey"
              @click.native="createNewSurvey"
            >
              <span>Click to Complete <br> a New Survey</span>
            </Button>
            <Button
              class="call"
              outlined
              color="#008C89"
              width="56"
              height="56"
              :styles="{'border': '2px solid'}"
              :disabled="!dashboardInfo.doctor.phone"
              @click.native="callToDoctor(dashboardInfo.doctor.phone)"
              v-if="dashboardInfo"
            >
              <v-icon color="#008C89">mdi-phone-outline</v-icon>
            </Button>
            <v-badge
              color="error"
              overlap
              :offset-y="17"
              :offset-x="17"
              :value="unreadCountMessages"
              :content="unreadCountMessages"
            >
              <Button
                class="ml-2 chat"
                outlined
                color="#008C89"
                width="56"
                height="56"
                :styles="{'border': '2px solid'}"
                @click.native="goTo('/chat')"
              >
                <v-icon color="#008C89">mdi-chat-outline</v-icon>
              </Button>
            </v-badge>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="dashboardInfo && dashboardInfo.panels">
        <v-col>
          <template v-for="panel in dashboardInfo.panels.$values">
            <v-card
              :key="panel.$id"
              :style="{'border-radius': '16px'}"
              elevation="2"
              class="card-survey"
            >
              <v-card-title class="white--text mt-8">
                <v-row>
                  <v-col md="7" sm="12" class="card-survey__button">
                    <Button
                      :customDisabled="!panel.visitId"
                      height="56"
                      @click.native="goTo('visit/' + panel.visitId, panel, 'visitId')"
                      :classes="getStatusCodeName[panel.statusCode].class"
                      color="#668BFF"><!--:styles="{'box-shadow': '0px 9px 10px rgb(102 139 255 / 17%)!important'}"-->
                      <span :class="getStatusCodeName[panel.statusCode].class">{{getStatusCodeName[panel.statusCode].name}}</span>
                    </Button>
                  </v-col>
                  <v-col md="5" sm="12" style="text-align: end;min-width: 120px">
                    <div class="card-survey__date">
                      <div class="title-date">Last Evaluation</div>
                      <div class="subtitle-date">{{$moment(panel.date1).format('MM/DD/YYYY')}}</div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <div class="progress">
                  <span>{{ Math.ceil(panel.progress) }}%</span>
                  <v-progress-linear
                    :value="panel.progress"
                    :class="getStatusCodeName[panel.statusCode].class"
                    height="6"
                    :style="{'border-radius': '10px'}"
                  >
                  </v-progress-linear>
                </div>
                <div class="content-text mt-4">
                  <p>
<!--                    {{getStatusCodeName[panel.statusCode].description}}-->
                    {{$t('home.' + getStatusCodeName[panel.statusCode].translate, { number: panel.number })}}
                  </p>
                </div>
              </v-card-text>
              <v-card-actions class="justify-center pb-4 survey-btn">
                <Button
                  btn-bg="dark-green"
                  :disabled="!panel.visitId"
                  height="55"
                  width="265"
                  @click.native="goTo('visit/' + panel.visitId + '/survey', panel, 'visitId')">
                  <span style="color: #FFFFFF">Survey</span>
                </Button>
              </v-card-actions>
            </v-card>
          </template>
          <!--        <v-card
                    :style="{'border-radius': '16px'}"
                    elevation="2"
                    class="card-survey"
                  >
                    <v-card-title class="white&#45;&#45;text mt-8">
                      <v-row>
                        <v-col md="7" sm="12" class="card-survey__button">
                          <Button
                            dark
                            height="56"
                            :styles="{'box-shadow': '0px 9px 10px rgb(102 139 255 / 17%)!important'}"
                            @click.native="goTo('visit/' + dashboardInfo.menuRefs.scheduleVisitId)"
                            color="#668BFF">
                            <span style="color: #FFFFFF">Survey in Progress</span>
                          </Button>
                        </v-col>
                        <v-col md="5" sm="12" style="text-align: end;min-width: 120px">
                          <div class="card-survey__date">
                            <div class="title-date">Last Evaluation</div>
                            <div class="subtitle-date">Apr 12, 2022</div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-text>
                      <div class="progress">
                        <span>{{ Math.ceil(75) }}%</span>
                        <v-progress-linear
                          value="75"
                          color="#537CFF"
                          height="6"
                          :style="{'border-radius': '10px'}"
                        >
                        </v-progress-linear>
                      </div>
                      <div class="content-text mt-4">
                        <p>
                          The doctor received your filled Survey. You may edit the Survey before Doctor will approve it.
                        </p>
                      </div>
                    </v-card-text>
                    <v-card-actions class="justify-center pb-4">
                      <Button
                        dark
                        height="55"
                        width="265"
                        @click.native="goTo('visit/' + dashboardInfo.menuRefs.scheduleVisitId + '/survey')"
                        color="#4FAA6E">
                        <span style="color: #FFFFFF">Survey</span>
                      </Button>
                    </v-card-actions>
                  </v-card>-->
        </v-col>
      </v-row>
    </div>
    <div class="desktop-version">
      <v-row no-gutters>
        <v-card
          :style="{'border-radius': '16px'}"
          elevation="2"
        >
          <v-col cols="6">
            <v-row no-gutters v-if="dashboardInfo">
              <v-avatar>
                <v-img :src="dashboardInfo.doctor.logoSmall"></v-img>
              </v-avatar>
              <div class="home-page__doctor-name">
                <div class="position">Practitioner</div>
                <div class="name">{{dashboardInfo.doctor.name}}</div>
              </div>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row no-gutters class="justify-end">
              <Button
                class="mr-2"
                outlined
                color="#008C89"
                width="190"
                height="56"
                :styles="{'border': '2px solid'}"
                v-if="dashboardInfo.canCreateNewSurvey"
                @click.native="createNewSurvey"
              >
                <span>Click to Complete <br> a New Survey</span>
              </Button>
              <Button
                class="call"
                outlined
                color="#008C89"
                width="56"
                height="56"
                :styles="{'border': '2px solid'}"
                :disabled="!dashboardInfo.doctor.phone"
                @click.native="callToDoctor(dashboardInfo.doctor.phone)"
                v-if="dashboardInfo"
              >
                <v-icon color="#008C89">mdi-phone-outline</v-icon>
              </Button>
              <v-badge
                color="#008C89"
                overlap
                :offset-y="17"
                :offset-x="17"
                :value="unreadCountMessages"
                :content="unreadCountMessages"
              >
                <Button
                  class="ml-2 chat"
                  outlined
                  color="#008C89"
                  width="56"
                  height="56"
                  :styles="{'border': '2px solid'}"
                  @click.native="goTo('/chat')"
                >
                  <v-icon color="#008C89">mdi-chat-outline</v-icon>
                </Button>
              </v-badge>
            </v-row>
          </v-col>
        </v-card>
      </v-row>
      <v-row no-gutters v-if="dashboardInfo && dashboardInfo.panels">
        <v-col>
          <template v-for="panel in dashboardInfo.panels.$values">
            <v-card
              :key="panel.$id"
              :style="{'border-radius': '16px', 'margin-bottom': '5px'}"
              elevation="2"
              class="card-survey"
            >
              <v-card-text>
                <div class="main-row">
                  <div class="status">
                    <Button
                      :customDisabled="!panel.visitId"
                      height="56"
                      :class="getStatusCodeName[panel.statusCode].class"
                      @click.native="goTo('visit/' + panel.visitId, panel, 'visitId')">
                      <span :class="getStatusCodeName[panel.statusCode].class">{{getStatusCodeName[panel.statusCode].name}}</span>
                    </Button>
                  </div>
                  <div class="date">
                    <div class="card-survey__date">
                      <div class="title-date">Last Evaluation</div>
                      <div class="subtitle-date">{{$moment(panel.date1).format('MM/DD/YYYY')}}</div>
                    </div>
                  </div>
                  <div class="progress">
                    <span>{{ Math.ceil(panel.progress) }}%</span>
                    <v-progress-linear
                      :value="panel.progress"
                      :class="getStatusCodeName[panel.statusCode].class"
                      height="6"
                      :style="{'border-radius': '10px'}"
                    >
                    </v-progress-linear>
                  </div>
                  <v-divider
                    vertical
                  ></v-divider>
                  <div class="description">
                    <p>
                      {{$t('home.' + getStatusCodeName[panel.statusCode].translate, { number: panel.number })}}
                    </p>
                  </div>
                  <div class="button-survey">
                    <Button
                      :disabled="!panel.visitId"
                      btn-bg="dark-green"
                      height="55"
                      width="265"
                      @click.native="goTo('visit/' + panel.visitId + '/survey', panel, 'visitId')">
                      <span style="color: #FFFFFF">Survey</span>
                    </Button>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </template>
          <!--        <v-card
                    :style="{'border-radius': '16px'}"
                    elevation="2"
                    class="card-survey"
                  >
                    <v-card-title class="white&#45;&#45;text mt-8">
                      <v-row>
                        <v-col md="7" sm="12" class="card-survey__button">
                          <Button
                            dark
                            height="56"
                            :styles="{'box-shadow': '0px 9px 10px rgb(102 139 255 / 17%)!important'}"
                            @click.native="goTo('visit/' + dashboardInfo.menuRefs.scheduleVisitId)"
                            color="#668BFF">
                            <span style="color: #FFFFFF">Survey in Progress</span>
                          </Button>
                        </v-col>
                        <v-col md="5" sm="12" style="text-align: end;min-width: 120px">
                          <div class="card-survey__date">
                            <div class="title-date">Last Evaluation</div>
                            <div class="subtitle-date">Apr 12, 2022</div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-text>
                      <div class="progress">
                        <span>{{ Math.ceil(75) }}%</span>
                        <v-progress-linear
                          value="75"
                          color="#537CFF"
                          height="6"
                          :style="{'border-radius': '10px'}"
                        >
                        </v-progress-linear>
                      </div>
                      <div class="content-text mt-4">
                        <p>
                          The doctor received your filled Survey. You may edit the Survey before Doctor will approve it.
                        </p>
                      </div>
                    </v-card-text>
                    <v-card-actions class="justify-center pb-4">
                      <Button
                        dark
                        height="55"
                        width="265"
                        @click.native="goTo('visit/' + dashboardInfo.menuRefs.scheduleVisitId + '/survey')"
                        color="#4FAA6E">
                        <span style="color: #FFFFFF">Survey</span>
                      </Button>
                    </v-card-actions>
                  </v-card>-->
        </v-col>
      </v-row>
    </div>
    <!--SCHEDULE-->
    <div class="schedule">
      <template v-if="scheduleData && scheduleData.resultSupplements">
        <v-row no-gutters class="mt-4 schedule__block">
          <v-col>
            <v-card
              :style="{'border-radius': '16px'}"
              elevation="2"
            >
              <v-card-title>
                <div class="main-row">
                  <div class="schedule-title">
                    <div class="title">Current Supplement Schedule</div>
                    <div class="date">From {{$moment(scheduleData.visitData.visitDate).format('MM/DD/YYYY')}} Evaluation</div>
                  </div>
                  <div class="next-visit">
                    <div class="next-visit__date">
                      <span class="label">Next Evaluation: </span>
                      <span class="value">{{$moment(scheduleData.visitData.nextSurveyDate).format('MM/DD/YYYY')}}</span>
                    </div>
                    <div class="next-visit__doctor">
                      <span class="value">{{scheduleData.visitData.doctor.firstName + ' ' + scheduleData.visitData.doctor.lastName}}</span>
                    </div>
                  </div>
                </div>
              </v-card-title>
              <v-card-text style="padding-bottom: 0">
                <div class="schedule-table-desktop-mobile">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead class="header">
                      <tr>
                        <th>{{ $t('schedule.supplement') }}</th>
                        <th>{{ $t('schedule.uponArising') }}</th>
                        <th>{{ $t('schedule.breakfast') }}</th>
                        <th>{{ $t('schedule.10am') }}</th>
                        <th>{{ $t('schedule.lunch') }}</th>
                        <th>{{ $t('schedule.3pm') }}</th>
                        <th>{{ $t('schedule.dinner') }}</th>
                        <th>{{ $t('schedule.7pm') }}</th>
                        <th>{{ $t('schedule.beforeSleep') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="item in scheduleData.resultSupplements.$values" :key="item.$id">
                        <td class="dynamic-device">
                          <div class="small-device-version mobile-version">{{ $t('schedule.supplement') }}</div>
                          <div class="schedule-table-value">
                            <div class="custom-block d-flex justify-space-between align-center">
                              <div style="margin-left: 15px;text-align: justify">
                                <v-row>
                                  <span class="schedule-table-value__name">{{item.supplementName}}</span>
                                </v-row>
                                <v-row class="schedule-table-value__number">
                                  <span></span>
                                </v-row>
                              </div>
                              <div class="schedule-table-value__info">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="#CBD5E0" v-bind="attrs" v-on="on" style="cursor: pointer">mdi-information</v-icon>
                                  </template>
                                  <span>{{item.description || '-'}}</span>
                                </v-tooltip>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="dynamic-device">
                          <div class="small-device-version">{{ $t('schedule.uponArising') }}</div>
                          <div class="schedule-table-value">
                            <span class="schedule-table-value__default">{{item.dosageUponArising || ''}}</span>
                          </div>
                        </td>
                        <td class="dynamic-device">
                          <div class="small-device-version">{{ $t('schedule.breakfast') }}</div>
                          <div class="schedule-table-value">
                            <span class="schedule-table-value__default">{{item.dosageBreakfast || ''}}</span>
                          </div>
                        </td>
                        <td class="dynamic-device">
                          <div class="small-device-version">{{ $t('schedule.10am') }}</div>
                          <div class="schedule-table-value">
                            <span class="schedule-table-value__default">{{item.dosage10am || ''}}</span>
                          </div>
                        </td>
                        <td class="dynamic-device">
                          <div class="small-device-version">{{ $t('schedule.lunch') }}</div>
                          <div class="schedule-table-value">
                            <span class="schedule-table-value__default">{{item.dosageLunch || ''}}</span>
                          </div>
                        </td>
                        <td class="dynamic-device">
                          <div class="small-device-version">{{ $t('schedule.3pm') }}</div>
                          <div class="schedule-table-value">
                            <span class="schedule-table-value__default">{{item.dosage3pm || ''}}</span>
                          </div>
                        </td>
                        <td class="dynamic-device">
                          <div class="small-device-version">{{ $t('schedule.dinner') }}</div>
                          <div class="schedule-table-value">
                            <span class="schedule-table-value__default">{{item.dosageDinner || ''}}</span>
                          </div>
                        </td>
                        <td class="dynamic-device">
                          <div class="small-device-version">{{ $t('schedule.7pm') }}</div>
                          <div class="schedule-table-value">
                            <span class="schedule-table-value__default">{{item.dosage7pm || ''}}</span>
                          </div>
                        </td>
                        <td class="dynamic-device">
                          <div class="small-device-version">{{ $t('schedule.beforeSleep') }}</div>
                          <div class="schedule-table-value">
                            <span class="schedule-table-value__default">{{item.dosageBeforeSleep || ''}}</span>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-6 schedule__block">
          <v-col cols="6">
            <Button
              dark
              outlined
              height="55"
              @click.native="downloadFile"
              width="100%"
              :styles="{ 'border-color': '#A6B7D4' }"
              color="transparent">
          <span style="color: #16192C;font-weight: 600;font-size: 12px;line-height: 12px;">
            <v-icon color="#A6B7D4">mdi-tray-arrow-down</v-icon>
            {{ $t('schedule.downloadPDF') }}
          </span>
            </Button>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <div class="empty-data">No active Schedule</div>
      </template>
    </div>
    <!--SCHEDULE-->
    <confirm v-if="dialogs.confirm.isOpen" @requestNewVisit="requestNewVisit" />
  </div>
</template>

<script>

import Button from '@/components/elements/Button'
import confirm from '@/components/layouts/dialogs/confirm-dialog'
import Mapping from '@/mixins/Mapping'
import DownloadFile from '@/mixins/DownloadFile'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'home',
  mixins: [Mapping, DownloadFile],
  components: {
    Button,
    confirm
  },
  data: () => ({}),
  mounted () {
    document.title = 'Dashboard - Maestro Patient Portal'
  },
  methods: {
    ...mapActions('reports', ['gerReportByCode']),
    ...mapActions('visits', ['addVisit']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    goTo (route, panel, key) {
      if (panel && key) {
        if (panel[key]) {
          this.$router.push(route)
        }
      } else {
        this.$router.push(route)
      }
    },
    callToDoctor (phoneNumber) {
      if (phoneNumber) {
        window.open('tel:' + phoneNumber)
        const self = this
        setTimeout(function () {
          self.TOGGLE_DIALOG({ name: self.dialog.name, state: false })
        }, 200)
      }
    },
    async downloadFile () {
      const payload = {
        appUserId: this.userProfile.userId,
        reportCode: 'PatientSchedule',
        entityId: this.dashboardInfo.menuRefs.scheduleVisitId
      }
      const res = await this.gerReportByCode(payload)
      this.resolveAndDownloadBlob(res, 'pdf')
    },
    async createNewSurvey () {
      await this.TOGGLE_DIALOG({
        name: 'confirm',
        isOpen: true,
        whose: 'createNewSurvey',
        action: 'visits/addVisit',
        createNewSurvey: {
          doctorId: this.dashboardInfo.doctor.id,
          patientId: this.dashboardInfo.patient.id,
          patientName: this.dashboardInfo.patient.firstName,
          notes: '',
          date: this.$moment(new Date()).format('MM/DD/YYYY'),
          completeSurvey: true
        },
        description: 'Click confirm below to begin your Survey. Your practitioner will be notified.',
        title: 'Complete New Survey'
      })
    },
    requestNewVisit (data) {
      this.$router.push(`visit/${data.id}/survey`)
      window.location.reload()
    }
  },
  computed: {
    dashboardInfo () {
      return this.$store.state.patients.dashboardInfo
    },
    unreadCountMessages () {
      return this.$store.state.chat.totalCountUnreadMessages
    },
    scheduleData () {
      return this.$store.state.reports.schedule
    },
    userProfile () {
      return this.$store.state.userProfile
    },
    dialogs () {
      return this.$store.state.dialogs
    },
  }
}
</script>

<style scoped lang="scss">
.home-page {
  //margin-top: 10px;
  padding: 70px 16px 60px;
  background: #F7FAFC;
  min-height: 100vh;
  .mobile-version {
    display: none;
  }
  .desktop-version {
    display: block;
    width: 70%;
    .row {
      width: 100%;
      margin-bottom: 10px;
      ::v-deep .v-card {
        width: 100% !important;
        display: flex!important;
      }
    }
    .card-survey {
      .main-row {
        display: flex;
        flex-wrap: wrap;
        column-gap: 1rem;
        row-gap: 1rem;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .status {
          .button.need-product {
            ::v-deep .v-btn {
              background: rgba(255, 237, 227, 0.7)!important;
              color: #F7936F!important;
            }
          }
          .button.in-active {
            ::v-deep .v-btn {
              background: #EDF2F7!important;
              color: #A0AEC0!important;
            }
          }
          .button.need-survey {
            ::v-deep .v-btn {
              background: rgba(255, 237, 227, 0.7)!important;
              color: #F7936F!important;
            }
          }
          .button.need-reeval {
            ::v-deep .v-btn {
              background: rgba(255, 237, 227, 0.7)!important;
              color: #F7936F!important;
            }
          }
          .button.in-progress {
            ::v-deep .v-btn {
              background: rgba(208, 220, 255, 0.5);
              color: #668BFF;
            }
          }
          .button.survey-sent {
            ::v-deep .v-btn {
              background: rgba(208, 220, 255, 0.5);
              color: #668BFF;
            }
          }
          .button.survey-due {
            ::v-deep .v-btn {
              background: rgba(255, 230, 228, 0.7)!important;
              color: #F16063!important;
            }
          }
          .button.need-review {
            ::v-deep .v-btn {
              background: rgba(238, 227, 255, 1)!important;
              color: #9959F8!important;
            }
          }
          .button.reviewed {
            ::v-deep .v-btn {
              background: rgba(208, 220, 255, 0.5)!important;
              color: #668BFF!important;
            }
          }
          .button.in-protocol {
            ::v-deep .v-btn {
              background: rgba(115, 217, 149, 0.2)!important;
              color: #4FAA6E!important;
            }
          }
          .button.protocol-ending {
            ::v-deep .v-btn {
              background: #FFE9E2!important;
              color: #E69376!important;
            }
          }
          .button.completed {
            ::v-deep .v-btn {
              background: rgba(141, 186, 106, 0.2)!important;
              color: #8DBA6A!important;
            }
          }
        }
        .date {
          width: 115px;
        }
        .progress {
          width: 15%;
        }
        .description {
          width: 30%;
        }
        .button-survey {
          width: 88px;
          .button {
            ::v-deep .v-btn {
              width: 100%!important;
            }
          }
        }
        @media screen and (max-width: 1130px) {
          .button-survey {
            width: 100%;
          }
        }
        @media screen and (max-width: 860px) {
          .description {
            width: 100%;
          }
          .v-divider {
            display: none;
          }
          .button-survey {
            width: 100%;
          }
        }
      }
    }
  }
  &__doctor-name {
    margin-left: 8px;
    font-family: Poppins;
    font-size: 16px;
    .position {
      color: #718096;
      font-weight: normal;
    }
    .name {
      color: #1A202C;
      font-weight: 600;
    }
  }
  .card-survey {
    &__date {
      font-size: 14px;
      font-family: Poppins;
      .title-date {
        color: #A0AEC0;
        font-weight: normal;
      }
      .subtitle-date {
        color: #1A202C;
        font-weight: 600;
      }
    }
    .progress {
      text-align: center;
      span {
        font-weight: 600;
        font-size: 12px;
      }
      ::v-deep .need-product {
        .primary {
          background: rgba(255, 237, 227, 0.7)!important;
          color: #F7936F!important;
        }
      }
      ::v-deep .in-active {
        .primary {
          background: #EDF2F7!important;
          color: #A0AEC0!important;
        }
      }
      ::v-deep .need-survey {
        .primary {
          background: rgba(255, 237, 227, 0.7)!important;
          color: #F7936F!important;
        }
      }
      ::v-deep .need-reeval {
        .primary {
          background: rgba(255, 237, 227, 0.7)!important;
          color: #F7936F!important;
        }
      }
      ::v-deep .survey-sent {
        .primary {
          background: #a8b2ff!important;
          color: #668BFF!important;
        }
      }
      ::v-deep .survey-due {
        .primary {
          background: rgba(255, 230, 228, 0.7)!important;
          color: #F16063!important;
        }
      }
      ::v-deep .need-review {
        .primary {
          background: rgba(238, 227, 255, 1)!important;
          color: #9959F8!important;
        }
      }
      ::v-deep .reviewed {
        .primary {
          background: rgba(208, 220, 255, 0.5)!important;
          color: #668BFF!important;
        }
      }
      ::v-deep .in-protocol {
        .primary {
          background: rgba(115, 217, 149, 0.2)!important;
          color: #4FAA6E!important;
        }
      }
      ::v-deep .completed {
        .primary {
          background:  rgba(115, 217, 149, 0.2)!important;
          color: #4FAA6E!important;
        }
      }
    }
    .content-text {
      font-size: 14px;
      color: #425466;
    }
    .survey-btn {
      ::v-deep .v-btn.v-btn--disabled {
        background-color: rgb(79 170 110 / 50%) !important;
        cursor: not-allowed !important;
      }
    }
  }
  /*.need-product {
    background: rgba(255, 237, 227, 0.7)!important;
    color: #F7936F!important;
  }
  .in-active {
    background: #EDF2F7!important;
    color: #A0AEC0!important;
  }
  .need-survey {
    background: rgba(255, 237, 227, 0.7)!important;
    color: #F7936F!important;
  }
  .need-reeval {
    background: rgba(255, 237, 227, 0.7)!important;
    color: #F7936F!important;
  }
  .survey-sent {
    background: rgba(208, 220, 255, 0.5)!important;
    color: #668BFF!important;
  }
  .survey-due {
    background: rgba(255, 230, 228, 0.7)!important;
    color: #F16063!important;
  }
  .need-review {
    background: rgba(238, 227, 255, 1)!important;
    color: #9959F8!important;
  }
  .reviewed {
    background: rgba(208, 220, 255, 0.5)!important;
    color: #668BFF!important;
  }
  .in-protocol {
    background: rgba(115, 217, 149, 0.2)!important;
    color: #4FAA6E!important;
  }
  .completed {
    background:  rgba(115, 217, 149, 0.2)!important;
    color: #4FAA6E!important;
  }*/

  .schedule {
    background: #F7FAFC;
    min-height: 100vh;

    ::v-deep .v-data-table__wrapper {
      tbody > tr > td {
        border-top-right-radius: 0!important;
        border-top-left-radius: 0!important;
      }
    }

    ::v-deep .v-card {
      &__title {
        background-color: #edf2f7;
        padding: 5px 16px;
      }
    }

    .main-row {
      display: flex;
      flex-wrap: wrap;
      column-gap: 0.5rem;
      row-gap: 1rem;
      width: 100%;
      justify-content: space-between;
      .schedule-title {
        width: 320px;
        .title {
          font-weight: bold;
          font-size: 15px;
        }
        .date {
          font-size: 13px;
          font-weight: bold;
          color: grey;
        }
      }
      .next-visit {
        width: 200px;
        text-align: end;
        &__date {
          .label {
            color: grey;
            font-size: 13px;
          }
          .value {
            font-weight: bold;
            font-size: 13px;
          }
        }
        &__doctor {
          .label {
            color: grey;
            font-size: 13px;
          }
          .value {
            font-weight: bold;
            font-size: 13px;
          }
        }
      }
    }
    .empty-data {
      text-align: center;
      min-height: calc(100vh - 110px);
    }

    &__title {
      font-weight: 600;
      font-size: 24px;
      color: #1A202C;
    }
    &__days-title {
      font-weight: normal;
      font-size: 14px;
      color: #A0AEC0;
    }
    &__days-value {
      font-weight: 600;
      font-size: 14px;
      color: #1A202C;
    }
    .schedule-table-desktop-mobile {
      display: block;
      width: 100%;
      position: relative;
      ::v-deep .v-data-table .v-data-table__wrapper .header tr th {
        padding: 0 6px !important;
      }
      .header {
        tr > th {
          color: #8492A6;
          font-weight: 600;
          font-size: 10px;
          text-align: center;
          border-right: thin solid rgba(0, 0, 0, 0.12);
        }
        tr > th:last-child {
          border-right: unset;
        }
      }
      .dynamic-device {
        border-right: thin solid rgba(0, 0, 0, 0.12);
        padding: 0 4px !important;
        .schedule-table-value {
          text-align: center;
          &__default {
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            color: #425466;
          }
          &__name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 250px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #425466;
          }
          &__number {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            color: #A0AEC0;
          }
        }
        .small-device-version {
          display: none;
          color: #8492A6;
          font-weight: 600;
          font-size: 10px;
        }
      }
      .dynamic-device:first-child {
        width: 300px;
      }
      .dynamic-device:last-child {
        border-right: unset;
      }
    }
    &__block-total {
      .total-title {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 23px;
        color: #718096;
      }
      .total-title.bold {
        font-weight: 600;
        font-size: 14px;
        color: #2D3748;
      }
      .total-value {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 23px;
        color: #718096;
      }
      .total-value.bold {
        font-weight: 600;
        font-size: 14px;
        color: #718096;
      }
    }

    @media screen and (min-width: 840px) {
      &__block {
        width: 70%;
      }
    }

    @media (max-width: 485px) {
      .schedule-table-desktop-mobile {
        .header {
          display: none !important;
          tr > th {
            border-right: unset;
          }
        }
        .dynamic-device {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-right: unset;
          border-bottom: thin solid rgba(0, 0, 0, 0.12);
          .small-device-version {
            display: block;
          }
          .schedule-table-value {
            .custom-block {
              flex-direction: row-reverse;
            }
            &__number {
              justify-content: flex-end;
            }
            &__name {
              text-align: right;
              padding-right: 9px;
            }
          }
        }
        .dynamic-device:first-child {
          width: unset;
        }
      }
    }
  }
}
@media screen and (max-width: 760px) {
  .home-page {
    padding: 10px 16px 60px;
    .desktop-version {
      display: none;
    }
    .mobile-version {
      display: block;
    }
  }
}
@media screen and (max-width: 367px) {
  .home-page {
    .card-survey {
      .col {
        text-align: center !important;
      }
    }
  }
}
@media screen and (max-width: 320px) {
  .home-page {
    .card-survey {
      ::v-deep .v-card__actions {
        .button {
          .v-btn {
            width: 80vw !important;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 287px) {
  .home-page {
    .chat {
      margin-top: 2px;
      margin-left: 0!important;
    }
  }
}
@media screen and (max-width: 248px) {
  .home-page {
    .card-survey {
      &__button {
        .button {
          ::v-deep .v-btn {
            white-space: unset !important;
            display: unset !important;
          }
        }
      }
    }
  }
}
</style>
