import Vue from 'vue'

const state = () => ({
  listReceipts: [],
  receiptsPreview: null,
  generatedReceipts: null,
  currentReceiptReport: null,
  receiptReport: {}
})

const getters = {}

const actions = {
  async printReceipt ({ commit }, { params, code }) {
    const { resultId, receiptId } = params
    const result = await Vue.$http.post(`results/${resultId}/receipts/${receiptId}/print?orderTypeCode=${code}`, {}, { responseType: 'blob' }) // { responseType: 'arraybuffer' }
    if (result?.status === 200) {
      commit('SET_DATA', ['receiptReport', result.data])
      return result?.data
    }
  },
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
