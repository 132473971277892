<template>
  <div class="image">
    <v-img v-bind="$props" />
  </div>
</template>

<script>
export default {
  name: 'Img',
  props: {
    alt: { type: String },
    height: { type: [Number, String] },
    src: { type: String },
    width: { type: [Number, String] }
  }
}
</script>

<style lang="scss" scoped>
  .image {
    //..
  }
</style>
