<template>
  <div class="profile" v-if="patientProfile">
    <v-row no-gutters>
      <v-col cols="12">
        <v-row class="d-flex justify-center mt-2">
          <v-badge
            @click.native.stop="openUploadFile"
            bordered
            bottom
            offset-x="27"
            offset-y="27"
            class="profile__patient-avatar-badge"
          >
            <input id="hiddenUploadInput" ref="inputAvatar" type="file" name="image" @change="uploadFile">
            <template v-slot:badge>
              <v-icon style="font-size: 17px;top: 1px">mdi-pencil</v-icon>
            </template>
            <v-avatar class="profile__patient-avatar" @click.native.stop="openUploadFile">
              <v-img :src="form.avatarFull || getImgWithExt(['icons', 'avatar-default', 'svg'])"></v-img>
            </v-avatar>
          </v-badge>
        </v-row>
        <v-row class="d-flex justify-center mt-4">
          <span class="profile__full-name">{{patientProfile.firstName}} {{patientProfile.lastName}}</span>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-form ref="form" lazy-validation class="mt-3 profile__form">
          <v-row>
            <v-col class="py-0">
              <div class="label">{{ $t('profile.name') }}</div>
              <Input
                outlined
                height="56"
                radius="16"
                tabindex="1"
                background-color="#EDF2F7"
                placeholder="Type your name"
                @input="form.firstName = $event"
                :value="form.firstName"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <div class="label">{{ $t('profile.lastName') }}</div>
              <Input
                outlined
                height="56"
                radius="16"
                tabindex="2"
                background-color="#EDF2F7"
                placeholder="Type your lastname"
                @input="form.lastName = $event"
                :value="form.lastName"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <div class="label">{{ $t('profile.email') }}</div>
              <Input
                outlined
                height="56"
                disabled
                radius="16"
                tabindex="2"
                background-color="#EDF2F7"
                placeholder="Type your E-mail"
                @input="form.email = $event"
                :value="form.email"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <div class="label">{{ $t('profile.language') }}</div>
              <div class="language-switcher">
                <v-row class="d-flex">
                  <v-col cols="6">
                    <Button
                      block
                      dark
                      :class="{ 'active-language': form.language === 'es' }"
                      tabindex="3" height="56"
                      @click.native="updateLanguage('es')">
                      {{ $t('profile.spanish') }}
                    </Button>
                  </v-col>
                  <v-col cols="6">
                    <Button
                      block
                      dark
                      :class="{ 'active-language': form.language === 'en' }"
                      tabindex="3" height="56"
                      @click.native="updateLanguage('en')">
                      {{ $t('profile.english') }}
                    </Button>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <div class="">
                <Button
                  block
                  btn-bg="dark-green"
                  tabindex="3"
                  height="56"
                  :styles="{ 'color':'white' }"
                  @click.native="update">
                  {{ $t('profile.update') }}
                </Button>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <!--upload file-->
    <v-row v-if="dialogs.uploadAvatar.isOpen">
      <v-col cols="12">
        <UploadAvatar
          :avatar="avatar"
          @modalClose="clearAvatarInputValue"
          @selectedNewAvatar="setNewAvatar"/>
      </v-col>
    </v-row>
    <!--upload file-->
    <div class="custom-alert">
      <v-alert
        color="#7ac1fb"
        dismissible
        type="info"
        v-model="alertIsOpen"
      >
        <span style="font-size: 14px;">{{popupMessage}}</span>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Validate from '@/mixins/Validation'
import fakeData from '@/assets/fakeData'
import Input from '@/components/elements/Input'
import Button from '@/components/elements/Button'
import UploadAvatar from '@/components/elements/UploadAvatar'
import Image from "@/mixins/Image";

export default {
  name: 'profile',
  mixins: [Validate, Image],
  components: { Input, Button, UploadAvatar },
  data: () => ({
    ...fakeData,
    avatar: null,
    form: {},
    alertIsOpen: false,
    popupMessage: null
  }),
  mounted () {
    document.title = 'Profile - Maestro Patient Portal'
    this.getPatientProfile().then((isSuccess) => {
      this.prepareData()
    })
  },
  methods: {
    ...mapActions('organizations', ['updateOrganization']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions('patients', ['getPatientProfile', 'updatePatientProfile']),
    prepareData () {
      this.form = {
        ...this.patientProfile
      }
    },
    async update () {
      // if (this.validate()) {
      // }
      const res = await this.updatePatientProfile(this.form)
      if (res) {
        this.alertIsOpen = true
        this.popupMessage = 'Profile updated successfully!'
        const self = this
        setTimeout(function () {
          self.alertIsOpen = false
          self.$router.push({ name: 'Home' })
        }, 1300)
      }
    },
    updateLanguage (lang) {
      this.form.language = lang
      if (lang === 'es') {
        this.form.spanish = true
      } else {
        this.form.spanish = false
      }
    },
    openUploadFile () {
      this.$refs.inputAvatar.click()
    },
    uploadFile (e) {
      console.log('run uploadFile')
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = (e) => {
        console.log(e)
        this.avatar = e.target.result
        this.TOGGLE_DIALOG({ name: 'uploadAvatar', isOpen: true, data: this.avatar })
      }
      console.log(image)
    },
    setNewAvatar (newAvatar) {
      this.form.avatarFull = newAvatar
      this.form.avatarSmall = newAvatar
      this.form.customAvatar = true
    },
    clearAvatarInputValue () {
      this.avatar = null
      this.$refs.inputAvatar.value = null
    }
  },
  computed: {
    ...mapState({
      isAuth: state => state.isAuth,
      patientProfile: state => state.patients.profile
    }),
    dialogs () {
      return this.$store.state.dialogs
    }
  }
}
</script>

<style lang="scss" scoped>
.profile {
  padding: 0 16px;
  background: #F7FAFC;
  min-height: 100vh;
  &__patient-avatar {
    width: 110px !important;
    height: 110px !important;
  }
  &__patient-avatar-badge {
    ::v-deep .v-badge__wrapper .v-badge__badge {
      width: 30px !important;
      height: 30px !important;
      border-radius: 50% !important;
      background-color: #A6B7D4 !important;
    }
    #hiddenUploadInput {
      display: none;
    }
  }
  &__full-name {
    font-weight: 600;
    font-size: 22px;
    color: #16192C;
  }
  &__form {
    .label {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #425466;
    }
    ::v-deep .v-input__control .v-input__slot {
      box-shadow: none !important;
    }
    ::v-deep .v-input {
      .v-input__control {
        .v-input__slot {
          border: 0px !important;
          .v-text-field__slot input {
            color: #494949 !important;
          }
        }
      }
    }
    .language-switcher {
      border: 1px solid #f1f1f1;
      border-radius: 18px;
      padding: 4px;
      margin-bottom: 10px;
      box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);

      .button {
        ::v-deep .v-btn {
          background-color: rgba(255, 255, 255, 0.64) !important;
          color: black!important;
          font-weight: 600!important;
        }
      }
      .button.active-language {
        ::v-deep .v-btn {
          background-color: #008C89 !important;
          color: white!important;
          font-weight: 600!important;
        }
      }
    }
  }

  @media screen and (min-width: 760px) {
    ::v-deep {
      .row {
        justify-content: center!important;
        .col {
          max-width: 50% !important;
        }
      }
    }
  }

  .custom-alert {
    max-width: 300px;
    position: fixed;
    top: 60px;
    right: 10px;
    //margin-left: -150px;
    z-index: 100;
  }

}
</style>
