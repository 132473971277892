<template>
  <v-dialog v-bind="$props"
    :value="dialog"
    @click:outside="destroyModal"
    attach>

    <v-card>
      <v-card-title class="px-0 pt-0 d-flex justify-space-between">
        <slot name="title" />
        <v-icon class="icon-close" @click.native="close">mdi-close</v-icon>
      </v-card-title>

      <v-card-text class="py-1">
        <slot name="body" />
      </v-card-text>

      <v-card-actions>
        <slot name="actions" />
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>

import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Dialog',
  props: {
    width: [String, Number],
    scrollable: {
      type: Boolean
    },
    persistent: {
      type: [Boolean]
    },
    transition: {
      type: [String, Boolean]
    },
    styles: {
      type: Object
    }
  },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    destroyModal () {
      const dialog = Object.assign({}, this.dialog)
      dialog.isOpen = false
      this.TOGGLE_DIALOG({ ...dialog })
    },
    close () {
      this.TOGGLE_DIALOG({ name: this.dialog.name, state: false })
    }
  },
  computed: mapState({
    dialog (state) {
      const modalName = this._.findKey(state.dialogs, ['isOpen', true])
      return state.dialogs[modalName]
    }
  })
}

</script>

<style lang="scss" scoped>
  ::v-deep .v-dialog {
    border-radius: 16px !important;
  }
  .v-card {
    &__title {
      padding: 41px 30px 0 46px !important;
      font-size: 28px !important;
      font-weight: 600 !important;
      .v-icon {
        position: relative;
        bottom: 20px;
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 338px) {
    ::v-deep .v-card {
      .icon-close {
        position: absolute !important;
        display: block !important;
        top: 5px;
        right: 5px;
      }
    }
  }
</style>
