const DownloadFile = {
  methods: {
    resolveAndDownloadBlob (response, type) {
      let filename = 'file.' + type
      filename = decodeURI(filename)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(url)
      link.remove()
    }
  }
}

export default DownloadFile
