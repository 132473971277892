<template>
  <div class="upload-avatar">
    <Dialog
      width="auto"
      :styles="{'border-radius': '16px'}">
      <template slot="title">
        <div class="upload-avatar__title">
          New avatar
        </div>
      </template>

      <template slot="body">
        <v-row class="d-flex justify-center">
          <div style="width: 256px">
            <ImageCropper :src="avatar" :avatarSize="avatarSize" @resultAvatar="getCroppedAvatar" @updateAvatarSize="updateAvatarSize"/>
          </div>
        </v-row>
        <v-row style="justify-content: start!important;">
          <v-col cols="12" style="max-width: 100%!important;">
            <v-slider
              v-model="avatarSize"
              :max="2"
              color="#008c89"
              :step="0.1"
              label="Size"
            >
            </v-slider>
          </v-col>
        </v-row>
        <!--        <v-row class="d-flex align-center">
                  <v-col cols="3">
                    <span class="upload-avatar__result">
                      Result:
                    </span>
                  </v-col>
                  <v-col cols="7">
                    <img
                      v-if="newAvatar"
                      :src="newAvatar"
                      alt="image"
                      style="width: 110px !important; height: 110px; border-radius: 50%">
                  </v-col>
                </v-row>-->
      </template>

      <template slot="actions">
        <v-row class="d-flex justify-end mb-4" style="justify-content: end!important;">
          <Button
            :styles="{'border-radius': '8px', 'margin-left': '5px','border': '1px solid #008c89', 'font-weight': '600'}">
            <span class="ml-2" @click="close">Cancel</span>
          </Button>
          <Button btn-bg="dark-green"
                  @click.native="updateAvatar"
                  :styles="{'border-radius': '8px', 'margin-left': '5px', 'color': 'white'}">
            <span class="ml-2">Update</span>
          </Button>
        </v-row>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/layouts/dialog'
import ImageCropper from '@/components/elements/ImageCropper'
import Button from '@/components/elements/Button'
import { mapMutations } from 'vuex'

export default {
  props: {
    avatar: {
      type: [String, Object]
    },
    refName: String
  },
  name: 'UploadAvatar',
  components: { ImageCropper, Dialog, Button },
  data: () => ({
    newAvatar: null,
    avatarSize: 0
  }),
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    getCroppedAvatar (newAvatar) {
      console.log('getCroppedAvatar', newAvatar)
      this.newAvatar = newAvatar
    },
    updateAvatarSize (size) {
      this.avatarSize = size
    },
    updateAvatar () {
      this.$emit('selectedNewAvatar', this.newAvatar)
      this.close()
    },
    close () {
      this.TOGGLE_DIALOG({ name: 'uploadAvatar', state: false })
    }
  },
  beforeDestroy () {
    console.log('destroy modal avatar')
    this.$emit('modalClose', this.refName)
    this.newAvatar = null
  }
}
</script>

<style scoped lang="scss">
.upload-avatar {
  ::v-deep .v-dialog {
    overflow-x: hidden !important;
  }
  ::v-deep .v-dialog {
    .v-card {
      padding: 0!important;
      &__title {
        padding: 5px 10px!important;
        justify-content: center!important;
        background: #edf2f7;
        position: relative;
      }
      &__text {
        margin-top: 20px!important;
        padding: 0 24px!important;
      }
      &__actions {
        padding: 0 24px!important;
      }
    }
    .v-card .v-icon {
      display: none;
      bottom: 0 !important;
      right: 10px !important;
    }
  }
  &__title {
    padding: 10px 23px;
  }
  &__result {
    font-size: 18px;
    font-weight: 600;
  }
}
</style>
