<template>
  <div class="confirm-account">
    <v-container fluid>
      <v-row>
        <v-col class="py-0">
          <div class="d-flex confirm-account__icon">
            <Img :src="getImgWithExt(['images', 'success', 'svg'])" />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0 pt-4">
          <div class="confirm-account__title">Nice. Almost done!</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0 confirm-account__text-timer d-flex">
          In <timer :date="10"  @time:up="timerIsUp" class="mx-2" /> seconds you will be autorized
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0"></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Image from '@/mixins/Image'

import Img from '@/components/elements/Img'
import timer from '@/components/elements/timer'

export default {
  name: 'confirm-password',
  mixins: [Image],
  components: { Img, timer },
  mounted () {
    document.title = 'Confirm Password Maestro Patient Portal'
  },
  methods: {
    ...mapActions(['confirmEmail']),
    timerIsUp () {
      const { id, token } = this.$route.params
      this.confirmEmail({ userId: id, token, userType: 4 })
    }
  }
}
</script>

<style lang="scss" scoped>
  .confirm-account {
    &__title {
      font-weight: 500;
      font-size: 38px;
    }
    &__text-timer {
      font-size: 16px;
      color: #7E8294;
    }
  }
</style>
