<template>
  <div class="loader">
    <v-progress-circular
      indeterminate
      color="#4FAA6E"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: 'loader'
}
</script>

<style scoped lang="scss">
.loader {
  //position: absolute;
  //top: 50%;
  //left: 50%;display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: 0.2s cubic-bezier(0.25, 0.8, 0.25, 1), z-index 1ms;
  width: 100%;
  z-index: 6;
  background: #f3faffc4;
  .v-progress-circular {
    position: absolute;
    left: 50%;
    top: 50%;
  }
}
</style>
