import Vue from 'vue'

const state = () => ({
  patients: null,
  patient: null,
  doctors: [],
  dashboardInfo: null,
  profile: null,
  surveyGroups: null,
  surveyDetails: null,
  detailsComplaints: null
})

const getters = { }

const actions = {
  async getPatientProfile ({ commit }, params) {
    const result = await Vue.$http.get('/Patients/GetProfile')
    if (result?.status === 200) {
      commit('SET_DATA', ['profile', result?.data.record])
      return result?.data.record
    }
  },
  async updatePatientProfile ({ commit }, params) {
    const { $id, ...data } = params
    const result = await Vue.$http.post('/Patients/UpdateProfile', { ...data })
    if (result?.status === 200) {
      commit('SET_DATA', ['profile', result?.data.record])
      return result?.data.record
    }
  },
  async getSurveyGroups ({ commit }, params) {
    const { surveyID, additionalParam } = params
    console.log(surveyID, additionalParam, params)
    const result = await Vue.$http.get(`/Surveys/${surveyID}/GetSchema${additionalParam}`)
    if (result?.status === 200) {
      commit('SET_DATA', ['surveyGroups', result?.data.record])
      return result?.data.record
    }
  },
  async getSurveyGroupByID ({ commit }, params) {
    const { surveyID, groupID } = params
    const result = await Vue.$http.get(`/Surveys/${surveyID}/GetGroup${groupID}`)
    if (result?.status === 200) {
      commit('UPDATE_SURVEY_GROUPS_QUESTIONS_DATA', result?.data.record)
      return result?.data.record
    }
  },
  async updateSurveyAnswer ({ commit }, params) {
    const { surveyID, ...data } = params
    const result = await Vue.$http.put(`/Surveys/${surveyID}/UpdateGroup`, { ...data })
    if (result?.status === 200) {
      commit('UPDATE_SURVEY_GROUPS_QUESTIONS_DATA', result?.data.record)
    }
  },
  async getSurveyDetails ({ commit }, params) {
    const { surveyID } = params
    const result = await Vue.$http.get(`/Surveys/${surveyID}/GetDetails`)
    if (result?.status === 200) {
      commit('SET_DATA', ['surveyDetails', result?.data.record])
      return result?.data.record
    }
  },
  async submitSurvey ({ commit }, params) {
    const result = await Vue.$http.put('Surveys/Submit', { ...params })
    if (result?.status === 200) {
      console.log(`Response: The patient was added; Status: ${result?.status}`)
      return result?.data.record
    }
  },
  async getPatients ({ commit }, params) {
    const result = await Vue.$http.post('/Patients/GetPatients', { ...params })
    if (result?.status === 200) {
      commit('SET_DATA', ['patients', result?.data])
    }
  },
  async getPatient ({ commit, rootGetters }, params) {
    const { id, urlParams } = params
    const paramsStr = rootGetters.BUILD_URL_PARAMS(urlParams)
    const result = await Vue.$http.get(`/Patients/${id}/Get${paramsStr}`)
    if (result?.status === 200) {
      commit('SET_DATA', ['patient', result.data?.record])
    }
  },
  async getPatientDashboard ({ state, commit }) {
    // if (!state.dashboardInfo) {}
    const result = await Vue.$http.get('/Patients/GetDashboard')
    if (result?.status === 200) {
      commit('SET_DATA', ['dashboardInfo', result?.data.record])
      return true
    }
  },
  async addPatient ({ commit }, params) {
    const result = await Vue.$http.post('/Patients/Add', { ...params })
    if (result?.status === 200) {
      commit('dialogs/TOGGLE_DIALOG', { name: 'addPatient', isOpen: false }, { root: true })
      console.log(`Respons: The patient was added; Status: ${result?.status}`)
    }
  },
  async updatePatient ({ commit }, params) {
    const { id, form } = params
    const result = await Vue.$http.put(`/Patients/${id}/Update`, { ...form })
    if (result?.status === 200) {
      commit('dialogs/TOGGLE_DIALOG', { name: 'editPatient', isOpen: false }, { root: true })
      console.log(`Respons: The patient is updated; Status: ${result?.status}`)
    }
  },
  deactivatePatient ({ _ }, id) {
    return new Promise((resolve) => {
      Vue.$http.delete(`/Patients/${id}/Deactivate`).then((result) => {
        if (result?.status === 200) {
          resolve()
          console.log(`Respons: The patient was delete; Status: ${result?.status}`)
        }
      })
    })
  },
  async getDoctors ({ commit }) {
    const result = await Vue.$http.get('/Patients/DoctorsDropdown')
    if (result?.status === 200) {
      commit('SET_DATA', ['doctors', result?.data?.record])
    }
  },
  async vailidateEmail ({ _ }, email) {
    const result = await Vue.$http.post('/Patients/ValidateEmail', email)
    return !!(result?.status === 200)
  },
  async getDetailsTestComplaints ({ commit }, params) {
    const { surveyID } = params
    const result = await Vue.$http.get(`/Surveys/${surveyID}/GetDetails`)
    if (result?.status === 200) {
      commit('SET_DATA', ['detailsComplaints', result?.data.record])
      return result?.data.record
    }
  },
  async updateTestComplaints ({ commit }, params) {
    const { data, surveyId } = params
    const result = await Vue.$http.put(`/Surveys/${surveyId}/UpdatePatientDetails`, { ...data })
    if (result?.status === 200) {
      commit('SET_DATA', ['detailsComplaints', result?.data?.record])
    }
  }
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  },
  UPDATE_SURVEY_GROUPS_QUESTIONS_DATA: (state, payload) => {
    state.surveyGroups.progress = payload.progress
    state.surveyGroups.groups.$values.map(group => {
      if (payload.id === group.id) {
        // group = payload
        Object.assign(group, { ...payload })
      }
    })
    state.surveyGroups.canSubmit = payload.canSubmit
    state.surveyGroups.tcViewed = payload.tcViewed
    state.surveyGroups.prevGroupId = payload.prevGroupId
    state.surveyGroups.nextGroupId = payload.nextGroupId
    state.surveyGroups.currentGroupId = payload.id
  },
  APPEND_PATIENT: (state, payload) => {
    const [property, value] = payload
    const data = Object.assign([], state[property].data.$values)
    state[property].data.$values = [...data, {
      ...value,
      name: `${value.firstName} ${value.lastName}`,
      address: `${value.line1} ${value.line2} ${value.line3} ${value.line4}`,
      number: `${value.cellPhone} ${value.homePhone} ${value.workPhone}`
    }]
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
