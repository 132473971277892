const Validation = {
  computed: {
    getStatusCodeName () {
      return {
        InActive: {
          name: 'In Active',
          class: 'in-active',
          description: (param) => {
            return `N/A`
          },
          translate: 'in-active'
        },
        NeedSurvey: {
          name: 'Need survey',
          class: 'need-survey',
          description: (param) => {
            return `There is no active survey assigned to you`
          },
          translate: 'need-survey'
        },
        NeedReeval: {
          name: 'Need reeval',
          class: 'need-reeval',
          description: (param) => {
            return `No active survey has been assigned to you for ${param} days`
          },
          translate: 'need-reeval'
        },
        SurveySent: {
          name: 'Survey Sent',
          class: 'survey-sent',
          description: (param) => {
            return 'You\'ve been assigned a Survey. The Survey may be edited before practitioner approves it'
          },
          translate: 'survey-sent'
        },
        'Survey sent': {
          name: 'Survey Sent',
          class: 'survey-sent',
          description: (param) => {
            return 'You\'ve been assigned a Survey. The Survey may be edited before practitioner approves it'
          },
          translate: 'survey-sent'
        },
        SurveyDue: {
          name: 'Survey due',
          class: 'survey-due',
          description: (param) => {
            return `Your survey is overdue ${param} days. Please fill in and submit survey. Click "Survey" button to proceed`
          },
          translate: 'survey-due'
        },
        'Survey Due': {
          name: 'Survey due',
          class: 'survey-due',
          description: (param) => {
            return `Your survey is overdue ${param} days. Please fill in and submit survey. Click "Survey" button to proceed`
          },
          translate: 'survey-due'
        },
        NeedReview: {
          name: 'Need Review',
          class: 'need-review',
          description: (param) => {
            return `Practitioner received your filled survey. You may edit the survey before practitioner approves it`
          },
          translate: 'need-review'
        },
        Reviewed: {
          name: 'Reviewed',
          class: 'reviewed',
          description: (param) => {
            return `Your survey is under review. To check out initial results click… The results may change before practitioner approves them`
          },
          translate: 'reviewed'
        },
        InProtocol: {
          name: 'In Protocol',
          class: 'in-protocol',
          description: (param) => {
            return `Review your supplements consumption schedule for the next ${param} days`
          },
          translate: 'in-protocol'
        },
        ProtocolEnding: {
          name: 'Protocol Ending',
          class: 'protocol-ending',
          description: (param) => {
            return `Protocol is about to end`
          },
          translate: 'protocol-ending'
        },
        protocolEnding: {
          name: 'Protocol Ending',
          class: 'protocol-ending',
          description: (param) => {
            return `Protocol is about to end`
          },
          translate: 'protocol-ending'
        },
        'Protocol Ending': {
          name: 'Protocol Ending',
          class: 'protocol-ending',
          description: (param) => {
            return `Protocol Ending`
          },
          translate: 'protocol-ending'
        },
        Completed: {
          name: 'Completed',
          class: 'completed',
          description: (param) => {
            return ``
          },
          translate: 'completed'
        },
        Closed: {
          name: 'Closed',
          class: 'completed',
          description: (param) => {
            return 'The visit is finalized. All activities were completed'
          }
        },
        InProgress: {
          name: 'In Progress',
          class: 'in-progress',
          description: (param) => {
            return ``
          },
          translate: 'in-progress'
        },
        NeedProduct: {
          name: 'Need Product',
          class: 'need-product',
          description: (param) => {
            return `Need Product Need Product Need Product Need Product Need Product`
          },
          translate: 'need-product'
        },
        canceled: {
          name: 'Canceled',
          class: 'canceled',
          description: (param) => {
            return ''
          }
        }
      }
    }
  }
}

export default Validation
