<template>
  <div class="phone-input">
    <Input v-bind="$props"
      outlined
      v-on="inputListeners"
      placeholder="(XXX) XXX-XXXX"
      v-mask="'(###) ###-####'"
      @input="phone=$event">
    </Input>
  </div>
</template>

<script>
import Input from '@/components/elements/Input'

export default {
  name: 'phone-input',
  props: {
    backgroundColor: String,
    className: String,
    value: String,
    label: String,
    tabindex: String,
    height: { default: 36, type: [String, Number] },
    radius: [String, Number]
  },
  components: { Input },
  data: () => ({
    phone: ''
  }),
  mounted () {
    this.$set(this.$data, 'phone', this.value)
  },
  computed: {
    inputListeners: function () {
      var vm = this
      return Object.assign({},
        this.$listeners,
        {
          input: function (event) {
            const value = (event?.target) ? event.target.value : event
            vm.$emit('input', value)
          }
        }
      )
    }
  }
}
</script>
