<template>
  <div class="index-page"><!--style="background: #F7FAFC;"-->
    <template v-if="dashboardInfo">
      <AppBar class="mobile-version" @toggleDrawer="drawerState=$event" :value="drawerState"/>
      <app-bar-desktop class="desktop-version" @toggleDrawer="drawerState=$event" :value="drawerState"/>

      <v-navigation-drawer
        v-model="drawerState"
        app
        :right="window.width < 760"
        temporary
        :style="{'top': window.width < 760 ? '65px' : '0'}"
      ><!--absolute height :style="{'top':'50px'}"-->
        <v-list
          nav
          dense
        >
          <v-list-item-group
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item @click.native="goToPage('/profile')">
              <v-list-item-title>{{ $t('appBar.menu.myProfile') }}</v-list-item-title>
            </v-list-item>

            <v-list-item @click.native="goToPage('/history')">
              <v-list-item-title>{{ $t('appBar.menu.myVisit') }}</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title @click="logout">{{ $t('appBar.menu.logout') }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

      <template v-if="$route.name === 'Home'">
        <Home/>
      </template>
      <template v-else>
        <v-main app class="index-main"><!--class="pt-16" style="margin-top: 10px;padding: 68px 16px;height: 100vh;"-->
          <router-view />
        </v-main>
      </template>

      <Footer :styles="{'border-radius': '10px 10px 0 0'}"/>
    </template>
    <template v-else>
      <loader/>
    </template>
  </div>
</template>

<script>
import fakeData from '@/assets/fakeData'
import Image from '@/mixins/Image'
import AppBar from '@/components/layouts/App-bar'
import AppBarDesktop from '@/components/layouts/App-bar-desktop'
import Footer from '@/components/layouts/Footer'
import Home from '@/views/home'
import Loader from '@/components/elements/loader'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Index',
  mixins: [Image],
  components: { AppBar, Home, Footer, AppBarDesktop, Loader },
  data: () => ({
    ...fakeData,
    drawerState: false,
    window: {
      width: null
    }
  }),
  created () {
    window.addEventListener('resize', this.handleResize)
  },
  mounted () {
    this.getPatientDashboard().then(() => {
      if (this.userProfile.firstLogin && this.userProfile.lead) {
        this.openPage(this.dashboardInfo.menuRefs, '/survey')
      }
      const self = this
      setTimeout(function () {
        self.getSchedule(self.dashboardInfo.menuRefs.scheduleVisitId)
      }, 500)
    })
  },
  methods: {
    ...mapActions(['logout']),
    ...mapActions('patients', ['getPatientDashboard']),
    ...mapActions('reports', ['getSchedule']),
    openPage (visit, path) {
      const newPath = 'history/visit/'
      this.$router.push({ path: newPath + visit.scheduleVisitId + path })
    },
    handleResize () {
      this.window.width = window.screen.width
    },
    goToPage (page) {
      this.$router.push(page)
    }
  },
  computed: {
    ...mapState({
      dashboardInfo: state => state.patients.dashboardInfo,
      userProfile: state => state.userProfile
    })
  },
  watch: {
    '$route.name' (val) {
      if (val === 'Home') {
        this.getPatientDashboard()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/fonts.scss';
  .index-page {
    .index-main {
      //padding-bottom: 55px;
      //padding-top: 57px!important;
      padding: 57px 0 0!important;
    }
    .mobile-version {
      display: none;
    }
    .desktop-version {
      display: block;
    }
    @media screen and (max-width: 760px) {
      .desktop-version {
        display: none;
      }
      .mobile-version {
        display: block;
      }
      .index-main {
        padding: 0 0 0!important;
      }
    }
  }
</style>
