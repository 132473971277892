import { render, staticRenderFns } from "./terms-conditions.vue?vue&type=template&id=69c38197&scoped=true&"
import script from "./terms-conditions.vue?vue&type=script&lang=js&"
export * from "./terms-conditions.vue?vue&type=script&lang=js&"
import style0 from "./terms-conditions.vue?vue&type=style&index=0&id=69c38197&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69c38197",
  null
  
)

export default component.exports