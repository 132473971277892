const functions = {
  number_format (val, digits = 0) {
    if (val === null || val === undefined) return ''
    return (+val).toFixed(digits).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
  },
  percent (val) {
    if (val === null || val === undefined) return ''
    return (+val).toFixed(2) + '%'
  },
  filterInputData (param) {
    const event = (param) || window.event
    const expect = event.target.value.toString() + event.key.toString()

    if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
      event.preventDefault()
    } else {
      return true
    }
  }
}

export default {
  methods: functions,
  filters: functions
}
