import Vue from 'vue'

const state = () => ({
  status: null
})

const getters = {}

const actions = {
  async updateStatus ({ commit }, params) {
    const { type, visitId, surveyId } = params
    const result = await Vue.$http.put(`https://dev.kliksoft.services/${type}`, { visitId, surveyId })
    if (result?.status === 200) {
      commit('SET_DATA', ['status', result?.data?.record])
    }
  }
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
