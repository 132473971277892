<template>
  <div class="my-app-bar">
    <v-app-bar
      color="#FFFFFF"
      elevation="1"
      prominent
    ><!--app-->
      <div class="doctor-organization" @click="openOrganizationInfo">
        <v-list-item-avatar>
          <v-img v-if="dashboardInfo" :src="dashboardInfo.organization.logoSmall"></v-img>
        </v-list-item-avatar>
        <div class="my-app-bar__doctor-title">
          {{dashboardInfo.organization.practiceName}}
        </div>
      </div>

      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <Img :src="getImgWithExt(['images', $i18n.locale, 'svg'])" width="22"/>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(locale, index) in locales"
            :key="index"
          >
            <v-list-item-title @click="switchLocale(locale)">
              <Img :src="getImgWithExt(['images', locale, 'svg'])" width="22"/>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn icon @click="goToPage('/chat')">
        <v-badge
          color="error"
          overlap
          :value="unreadCountMessages"
          :content="unreadCountMessages"
        >
          <v-icon color="#718096">mdi-bell-outline</v-icon>
        </v-badge>
      </v-btn>

      <v-app-bar-nav-icon color="#718096" @click.stop="toggleDrawer"></v-app-bar-nav-icon>
    </v-app-bar>
    <OrganizationInfo v-if="dialogs.organizationInfo.isOpen"/>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import OrganizationInfo from '@/components/layouts/forms/organizationInfo'
import Image from '@/mixins/Image'
import Img from '@/components/elements/Img'

export default {
  props: {
    value: {
      type: Boolean
    }
  },
  mixins: [Image],
  components: { OrganizationInfo, Img },
  name: 'App-bar',
  data: () => ({
    drawerState: false,
    locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
  }),
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    goToPage (page) {
      this.$router.push(page)
    },
    toggleDrawer () {
      this.drawerState = this.value
      this.drawerState = !this.drawerState
      this.$emit('toggleDrawer', this.drawerState)
    },
    openOrganizationInfo () {
      this.TOGGLE_DIALOG({ name: 'organizationInfo', isOpen: true })
    },
    switchLocale (locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale
        localStorage.setItem('locale', this.$i18n.locale)
      }
    }
  },
  computed: {
    dialogs () {
      return this.$store.state.dialogs
    },
    dashboardInfo () {
      return this.$store.state.patients.dashboardInfo
    },
    unreadCountMessages () {
      return this.$store.state.chat.totalCountUnreadMessages
    }
  }
}
</script>

<style scoped lang="scss">
.my-app-bar {
  .doctor-organization {
    display: contents;
  }
  ::v-deep .v-toolbar--prominent .v-toolbar__content {
    align-items: center !important;
    height: auto !important;
  }
  ::v-deep .v-toolbar--prominent {
    height: auto !important;
  }
  ::v-deep .v-toolbar__title {
    display: contents !important;
  }
  &__doctor-title {
    width: 150px;
    word-break: break-word;
    color: #718096;
    font-family: Poppins;
    font-weight: 600;
    font-size: 12px;
  }
  @media screen and (max-width: 330px) {
    &__doctor-title {
      display: none;
    }
  }
}
</style>
