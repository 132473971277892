<template>
  <div class="receipts">
    <v-row no-gutters class="d-flex justify-space-between align-center pt-4">
      <v-col md5 sm12>
        <span class="receipts__title">{{ $t('result.receipt') }}</span>
      </v-col>
      <v-col md7 sm12 style="text-align: right">
        <span class="receipts__days-title">{{ $t('result.daysSupply') }} </span>
        <span class="receipts__days-value">30</span>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-4">
      <v-col>
        <v-card
          :style="{'border-radius': '16px'}"
          elevation="2"
          class="card-receipts"
        >
          <v-card-text style="padding-bottom: 0">
            <div class="receipts-table-desktop-mobile">
              <v-simple-table>
                <template v-slot:default>
                  <thead class="header">
                  <tr>
                    <th>SUPPLEMENT</th>
                    <th>Q-TY</th>
                    <th>SIZE</th>
                    <th>UNIT, $</th>
                    <th>TOTAL, $</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in mockData" :key="item.id">
                    <td class="dynamic-device">
                      <div class="small-device-version mobile-version">SUPPLEMENT</div>
                      <div class="receipts-table-value">
                        <div class="custom-block d-flex justify-space-between align-center">
                          <div style="margin-left: 15px;text-align: justify">
                            <v-row>
                              <span class="receipts-table-value__name">{{item.supplement}}</span>
                            </v-row>
                            <v-row class="receipts-table-value__number">
                              <span>11234</span>
                            </v-row>
                          </div>
                          <div class="receipts-table-value__info">
                            <v-icon color="#CBD5E0">mdi-information</v-icon>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="dynamic-device">
                      <div class="small-device-version">Q-TY</div>
                      <div class="receipts-table-value">
                        <span class="receipts-table-value__default">{{item.quantity}}</span>
                      </div>
                    </td>
                    <td class="dynamic-device">
                      <div class="small-device-version">SIZE</div>
                      <div class="receipts-table-value">
                        <span class="receipts-table-value__default">{{item.size}}</span>
                      </div>
                    </td>
                    <td class="dynamic-device">
                      <div class="small-device-version">UNIT, $</div>
                      <div class="receipts-table-value">
                        <span class="receipts-table-value__default">{{item.unit.toFixed(2)}}</span>
                      </div>
                    </td>
                    <td class="dynamic-device">
                      <div class="small-device-version">TOTAL, $</div>
                      <div class="receipts-table-value">
                        <span class="receipts-table-value__default">{{item.total.toFixed(2)}}</span>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-card-text>
          <v-card-text style="padding: 0;background-color: #EDF2F7">
            <div class="receipts__block-total mt-6 pr-8 pl-8 pb-12 pt-8">
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <span class="total-title">Product Total</span>
                  </v-row>
                  <v-row>
                    <span class="total-title">Next Day Charge</span>
                  </v-row>
                  <v-row>
                    <span class="total-title">PVisit Fee</span>
                  </v-row>
                  <v-row>
                    <span class="total-title bold">Total Due</span>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row class="justify-end">
                    <span class="total-value">$1 861.00</span>
                  </v-row>
                  <v-row class="justify-end">
                    <span class="total-value">$6.00</span>
                  </v-row>
                  <v-row class="justify-end">
                    <span class="total-value">$123.00</span>
                  </v-row>
                  <v-row class="justify-end">
                    <span class="total-value bold">$1 861.00</span>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-6">
      <v-col cols="12">
        <Button
          dark
          outlined
          height="55"
          @click.native="downloadFile"
          width="100%"
          :styles="{ 'border-color': '#A6B7D4' }"
          color="transparent">
          <span style="color: #16192C;font-weight: 600;font-size: 12px;line-height: 12px;">
            <v-icon color="#A6B7D4">mdi-tray-arrow-down</v-icon>
            Download PDF
          </span>
        </Button>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Button from '@/components/elements/Button'

export default {
  name: 'Receipts',
  components: { Button },
  data: () => ({
    mockData: [
      {
        id: 1,
        value: 1,
        supplement: 'Folic Acid',
        quantity: 3,
        size: 4,
        unit: 158.60,
        total: 158.60
      },
      {
        id: 2,
        value: 2,
        supplement: '10-Day Bgkjlffdhgfggf',
        quantity: 3,
        size: 4,
        unit: 158.60,
        total: 158.60
      },
      {
        id: 3,
        value: 3,
        supplement: 'Catalyn',
        quantity: 3,
        size: 4,
        unit: 158.60,
        total: 158.60
      },
      {
        id: 4,
        value: 4,
        supplement: 'Cataplex',
        quantity: 3,
        size: 4,
        unit: 158.60,
        total: 158.60
      }
    ]
  }),
  methods: {
    downloadFile () {
      console.log('downloadFile')
    }
  }
}
</script>

<style scoped lang="scss">
.receipts {
  padding: 0 16px;
  background: #F7FAFC;
  min-height: 100vh;

  &__title {
    font-weight: 600;
    font-size: 24px;
    color: #1A202C;
  }
  &__days-title {
    font-weight: normal;
    font-size: 14px;
    color: #A0AEC0;
  }
  &__days-value {
    font-weight: 600;
    font-size: 14px;
    color: #1A202C;
  }
  .receipts-table-desktop-mobile {
    display: block;
    width: 100%;
    position: relative;
    ::v-deep .v-data-table .v-data-table__wrapper .header tr th {
      padding: 0 6px !important;
    }
    .header {
      tr > th {
        color: #8492A6;
        font-weight: 600;
        font-size: 10px;
        text-align: center;
        border-right: thin solid rgba(0, 0, 0, 0.12);
      }
      tr > th:last-child {
        border-right: unset;
      }
    }
    .dynamic-device {
      border-right: thin solid rgba(0, 0, 0, 0.12);
      padding: 0 4px !important;
      .receipts-table-value {
        text-align: center;
        &__default {
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          color: #425466;
        }
        &__name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #425466;
        }
        &__number {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: #A0AEC0;
        }
      }
      .small-device-version {
        display: none;
        color: #8492A6;
        font-weight: 600;
        font-size: 10px;
      }
    }
    .dynamic-device:last-child {
      border-right: unset;
    }
  }
  &__block-total {
    .total-title {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 23px;
      color: #718096;
    }
    .total-title.bold {
      font-weight: 600;
      font-size: 14px;
      color: #2D3748;
    }
    .total-value {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 23px;
      color: #718096;
    }
    .total-value.bold {
      font-weight: 600;
      font-size: 14px;
      color: #718096;
    }
  }

  @media (max-width: 485px) {
    .receipts-table-desktop-mobile {
      .header {
        display: none !important;
        tr > th {
          border-right: unset;
        }
      }
      .dynamic-device {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-right: unset;
        border-bottom: thin solid rgba(0, 0, 0, 0.12);
        .small-device-version {
          display: block;
        }
        .receipts-table-value {
          .custom-block {
            flex-direction: row-reverse;
          }
          &__number {
            justify-content: flex-end;
          }
          &__name {
            text-align: right;
            padding-right: 9px;
          }
        }
      }
    }
  }
}
</style>
