<template>
  <div class="button">
    <v-btn v-on:click="$emit('click-btn')" v-bind="$props" :style="styles">
      <slot></slot>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    block: Boolean,
    color: String,
    dark: Boolean,
    disabled: Boolean,
    icon: Boolean,
    height: [Number, String],
    maxHeight: [String, Number],
    maxWidth: [String, Number],
    outlined: Boolean,
    small: Boolean,
    styles: Object,
    tabindex: String,
    tile: Boolean,
    width: [String, Number],
    xSmall: Boolean
  }
}
</script>

<style lang="scss" scoped>
.button {
  &[btn-bg="dark-green"] {
    .v-btn {
      background-color: $dark-green;
    }
  }
  &[btn-bg="transparent"] {
    .v-btn {
      background-color: $transparent;
    }
  }
  &[btn-bg="white"] {
    .v-btn {
      background-color: $white;
    }
  }
  .v-btn {
    border-radius: 16px;
    text-transform: unset !important;
    box-shadow: unset !important;
  }
}
</style>
