import ChatHub from '@/chatHub'
import store from '@/store'

export default {
  install: (Vue) => {
    if (store.state.isAuth) {
      ChatHub.start(store.state.token)
    }
    // Vue.prototype.$chatHub = ChatHub
  }
}
