<template>
  <div class="app-bar-desktop">
    <v-app-bar
      color="#FFFFFF"
      prominent
      app
    >
      <v-app-bar-nav-icon color="#718096" @click.stop="toggleDrawer"></v-app-bar-nav-icon>

      <div class="doctor-organization" @click="openOrganizationInfo">
        <v-list-item-avatar>
          <v-img v-if="dashboardInfo" :src="dashboardInfo.organization.logoSmall"></v-img>
        </v-list-item-avatar>
        <div class="app-bar-desktop__doctor-title">
          {{dashboardInfo.organization.practiceName}}
        </div>
      </div>

      <v-spacer></v-spacer>

      <div class="menu-tabs" v-if="dashboardInfo && dashboardInfo.menuRefs">
        <v-tabs slider-color="#008C89" v-model="activeTab">
          <v-tab v-for="tab in tabs"
                 :key="tab.name"
                 exact
                 :disabled="tab.name === 'survey' || tab.name === 'visit' ? !dashboardInfo.menuRefs.surveyVisitId : false"
                 :to="tab.goTo">
            {{ tab.btnName }}
          </v-tab>
        </v-tabs>
      </div>

      <v-divider
        vertical
      ></v-divider>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <Img :src="getImgWithExt(['images', $i18n.locale, 'svg'])" width="22"/>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(locale, index) in locales"
            :key="index"
          >
            <v-list-item-title @click="switchLocale(locale)">
              <Img :src="getImgWithExt(['images', locale, 'svg'])" width="22"/>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn icon @click="goToPage('chat')">
        <v-badge
          color="#008C89"
          overlap
          :value="unreadCountMessages"
          :content="unreadCountMessages"
        >
          <v-icon color="#718096">mdi-bell-outline</v-icon>
        </v-badge>
      </v-btn>
      <div class="right-side-main-info" @click="goToPage('profile')" v-if="userProfile">
        <v-list-item-avatar>
          <v-img :src="userProfile.avatarSmall" :alt="userProfile.firstName"></v-img>
        </v-list-item-avatar>
        <div class="right-side-main-info__full-name">{{userProfile.firstName}} {{userProfile.lastName}}
        </div>
      </div>
    </v-app-bar>
    <OrganizationInfo v-if="dialogs.organizationInfo.isOpen"/>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import OrganizationInfo from '@/components/layouts/forms/organizationInfo'
import Image from '@/mixins/Image'
// import Img from '@/components/elements/Img'

export default {
  props: {
    value: {
      type: Boolean
    }
  },
  mixins: [Image],
  components: { OrganizationInfo },
  name: 'App-bar-desktop',
  data: () => ({
    drawerState: false,
    locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','),
    activeTab: null
  }),
  mounted () {
    this.getPatientProfile()
  },
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    ...mapActions('patients', ['getPatientProfile']),
    toggleDrawer () {
      this.drawerState = this.value
      this.drawerState = !this.drawerState
      this.$emit('toggleDrawer', this.drawerState)
    },
    openOrganizationInfo () {
      this.TOGGLE_DIALOG({ name: 'organizationInfo', isOpen: true })
    },
    switchLocale (locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale
        localStorage.setItem('locale', this.$i18n.locale)
      }
    },
    goTo (path) {
      if (this.dashboardInfo && this.dashboardInfo.menuRefs) {
        return path + `/${this.dashboardInfo.menuRefs.scheduleVisitId}`
      }
    },
    goToSurvey (path) {
      if (this.dashboardInfo && this.dashboardInfo.menuRefs) {
        return path + `/${this.dashboardInfo.menuRefs.surveyVisitId}/survey`
      }
    },
    goToPage (page) {
      if (this.$route.name !== page) {
        this.$router.push('/' + page)
      }
    }
  },
  computed: {
    dialogs () {
      return this.$store.state.dialogs
    },
    dashboardInfo () {
      return this.$store.state.patients.dashboardInfo
    },
    unreadCountMessages () {
      return this.$store.state.chat.totalCountUnreadMessages
    },
    ...mapState({
      userProfile: state => state.patients.profile
    }),
    tabs () {
      return [
        { name: 'Home', btnName: 'Home', goTo: '/' },
        // { name: 'survey', btnName: 'Survey', goTo: `/visit/${this.dashboardInfo.menuRefs.surveyVisitId}/survey` },
        // { name: 'visit', btnName: 'Schedule', goTo: `/visit/${this.dashboardInfo.menuRefs.surveyVisitId}/schedule` },
        { name: 'evaluations', btnName: 'Evaluations', goTo: '/evaluations' },
        { name: 'chat', btnName: 'Chat', goTo: '/chat' },
        { name: 'profile', btnName: 'My Profile', goTo: '/profile' },
        { name: 'logout', btnName: 'Logout ', goTo: '/sign-in' }
      ]
    }
  },
  watch: {
    $route (val) {
      console.log(val)
    },
    activeTab (val) {
      console.log('activeTab', val)
    }
  }
}
</script>

<style scoped lang="scss">
.app-bar-desktop {
  .doctor-organization {
    display: contents;
  }
  ::v-deep .v-toolbar--prominent .v-toolbar__content {
    align-items: center !important;
    height: auto !important;
    padding-bottom: 0!important;
    padding-top: 0!important;
  }
  ::v-deep .v-toolbar--prominent {
    height: auto !important;
  }
  ::v-deep .v-toolbar__title {
    display: contents !important;
  }
  &__doctor-title {
    width: 200px; // 150px
    word-break: break-word;
    color: #718096;
    font-family: Poppins;
    font-weight: 600;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .menu-tabs {
    margin-right: 1px;

    ::v-deep .v-tabs {
      height: 56px!important;
    }
    ::v-deep .v-tabs .v-slide-group {
      height: 56px!important;
    }
    ::v-deep .v-tabs .v-item-group .v-slide-group {
      &__wrapper {}
      &__content {
        .v-tab {
          text-decoration: none!important;
          font-weight: 600;
          font-size: 12px;
          color: #718096;
        }
        .v-tab--active {
          color: $dark-green!important;
        }
      }
    }
  }

  .right-side-main-info {
    display: flex;
    align-items: center;
    &__full-name {
      font-weight: 600;
      font-size: 14px;
      color: #16192C;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 330px) {
    &__doctor-title {
      display: none;
    }
  }
}
</style>
