import Vue from 'vue'

const state = () => ({
  schedule: {},
  receipts: {},
  currentReceiptReport: {}
})

const getters = {}

const actions = {
  async getSchedule ({ commit }, id) {
    const result = await Vue.$http.get(`reports/GetSchedule?visitId=${id}`)
    if (result?.status === 200) {
      commit('SET_DATA', ['schedule', result.data])
    }
  },
  async getReceipts ({ commit }, id) {
    const result = await Vue.$http.get(`Reports/GetReceipt?visitId=${id}`)
    if (result?.status === 200) {
      commit('SET_DATA', ['receipts', result.data])
    }
  },
  async getReceipt ({ commit }, params) {
    const { resultId, receiptId } = params
    if (resultId && receiptId) {
      const result = await Vue.$http.get(`results/${resultId}/receipts/${receiptId}/report`)
      if (result?.status === 200) {
        commit('SET_DATA', ['currentReceiptReport', result.data])
      }
    }
  },
  async gerReportByCode ({ commit }, params) {
    const result = await Vue.$http.post('Reports/GetByCode', { ...params }, { responseType: 'blob' })
    if (result?.status === 200) {
      return result
    }
  }
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
