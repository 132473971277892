<template>
  <div class="survey">
    <template v-if="surveyGroups">
      <div class="breadcrumbs" v-if="$route.path === '/survey'">
        <Breadcrumbs/>
      </div>
      <v-row no-gutters class="d-flex justify-space-between align-center pl-4 mt-2 pr-4">
        <v-col md5 sm12>
          <span class="survey__title">{{ $t('survey.title') }}</span>
        </v-col>
        <v-col md7 sm12 style="text-align: right">
          <span class="survey__due-date">Due Date </span>
          <span class="survey__date">{{$moment(dueDate).format('MM/DD/YYYY') || 'Apr 12, 2022'}}</span>
        </v-col>
      </v-row>
      <template v-if="surveyGroups">
        <template v-if="!isReadonly">
          <!--NEW CONTROLS-->
          <v-row class="pl-8 pr-8 pt-2 pb-2 grey-bg">
            <Button
              btn-bg="white"
              height="45"
              width="100"
              :disabled="!prevGroupIdValue"
              :styles="{ 'border-radius': '8px', 'border': '1px solid #008C89' }"
              @click.native="selectCurrentGroup(-1)"
            >
              <span style="color: #008C89;font-weight: 600">{{ prevGroupIdValue ? prevGroupIdValue.toUpperCase() : '' }}</span>
              <span style="color: black;font-weight: 600;padding-left: 5px">Prev</span>
            </Button>
            <Button
              btn-bg="dark-green"
              height="45"
              width="50"
              class="mx-2"
              :styles="{ 'border-radius': '8px', 'min-width': '50px' }"
            >
              <span style="color: #FFFFFF">{{ selectedGroupValue ? selectedGroupValue.toUpperCase() : '' }}</span>
            </Button>
            <Button
              btn-bg="white"
              height="45"
              width="100"
              :disabled="!nextGroupIdValue"
              :styles="{ 'border-radius': '8px', 'border': '1px solid #008C89' }"
              @click.native="selectCurrentGroup(1)"
            >
              <span style="color: black;font-weight: 600;padding-right: 5px">Next</span>
              <span style="color: #008C89;font-weight: 600">{{ nextGroupIdValue ? nextGroupIdValue.toUpperCase() : '' }}</span>
            </Button>
            <Button
              class="ml-15"
              v-show="!nextGroupIdValue"
              btn-bg="dark-green"
              height="45"
              width="130"
              :disabled="!canSubmit"
              @click.native="sendSurvey"
              :styles="{ 'border-radius': '8px' }"
            >
              <span style="color: #FFFFFF">{{ $t('survey.submit') }}</span>
            </Button>
          </v-row>
          <br>
          <!--NEW CONTROLS-->
          <v-row no-gutters class="mt-2 pl-4 pr-4 slide-group" v-if="surveyGroups">
            <v-col cols="12" class="group-box">
              <v-slide-group
                v-model="selectedGroup"
                mandatory
                @change="changeSelectedGroup"
                :show-arrows="false"
              >
                <v-slide-item
                  class="group-slide-item"
                  v-for="(group, index) in surveyGroups"
                  :key="group.id"
                  v-slot="{ active }"
                ><!--, toggle-->
                  <v-btn
                    class="mx-1 group-button"
                    :input-value="active"
                    active-class="green-bg white--text"
                    depressed
                    rounded
                    :class="{ 'green-border': group.isCompleted, 'green-light-bg': group.isViewed && selectedGroup !== index }"
                    style="font-weight: 600;font-size: 13px"
                  ><!--toggle-->
                    <span><!--:class="{ 'green-text': group.isCompleted && selectedGroup !== index }"-->
                      {{ group.groupNumber }}
                    </span>
                    <v-icon
                      style="font-size: 16px!important;"
                      v-if="group.isCompleted && selectedGroup !== index"
                      color="black"
                    >
                      mdi-check
                    </v-icon>
                  </v-btn>
                </v-slide-item>
                <v-slide-item
                  v-slot="{ active }"
                  :value="'test&complaints'"
                ><!--, toggle-->
                  <v-btn
                    class="mx-1 group-button"
                    :input-value="active"
                    active-class="green-bg white--text"
                    depressed
                    rounded
                    width="40px"
                    style="font-weight: 600;font-size: 13px"
                    :class="{ 'green-light-bg': tcIsViewed && selectedGroup !== 'test&complaints' }"
                  ><!--@click="toggle" :class="{ 'green-border': surveyData.tcViewed }"-->
                    <span style="text-transform: capitalize"><!--:class="{ 'green-text': surveyData.tcViewed && selectedGroup !== 'test&complaints'}"--> <!--:class="{ 'green-text': selectedGroup !== 'test&complaints' }"-->
                      T&C
                    </span>
                  </v-btn>
                </v-slide-item>
              </v-slide-group>
              <div style="padding-left: 10px;">
                <span style="font-weight: 600">{{ Math.ceil(surveyProgress) }}% completed</span>
              </div>
            </v-col>
          </v-row>
          <!--<v-row no-gutters class="align-center mt-1 progress-block justify-center  pl-4 pr-4" v-if="surveyGroups">
            <v-col cols="12" sm="7">
              <span class="mobile-percent">{{ Math.ceil(progress) }}%</span>
              <div class="progress">
                <v-progress-linear
                  :value="progress"
                  color="#008C89"
                  height="6"
                  :style="{'border-radius': '10px'}"
                >
                </v-progress-linear>
              </div>
            </v-col>
            <v-col cols="12" sm="2" class="desktop-percent">
              <span>{{ Math.ceil(progress) }}%</span>
            </v-col>
            <v-col cols="12" sm="3" class="submit-btn">
              <Button
                btn-bg="dark-green"
                height="45"
                width="90%"
                :disabled="!canSubmit"
                @click.native="sendSurvey"
                :styles="{ 'border-radius': '8px' }"
              >
                <span style="color: #FFFFFF">{{ $t('survey.submit') }}</span>
              </Button>
            </v-col>
          </v-row>-->
          <v-row no-gutters class="mt-2">
            <div class="survey-table-desktop-mobile" v-if="surveyGroups && selectedGroup !== 'test&complaints'">
              <v-simple-table>
                <template v-slot:default>
                  <thead class="header">
                  <tr>
                    <th>SYMPTOMS</th>
                    <th>NONE</th>
                    <th>MILD</th>
                    <th>MOD</th>
                    <th>SEV</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in surveyQuestions" :key="item.id" :class="{'grey-bg': (index % 2) === 0}">
                    <td class="dynamic-device">
                      <div class="small-device-version">SYMPTOMS</div>
                      <div class="symptoms">
                        <div class="number-of-index" style="width: 50px;margin-left: -50px;margin-bottom: -21px;margin-right: 0px;font-weight: 600;text-align: center">{{item.number}}</div>
                        {{item.text}}
                      </div>
                    </td>
                    <td class="dynamic-device">
                      <div class="small-device-version mobile-version">NONE</div>
                      <div class="survey-value">
                        <v-radio-group
                          v-model="item.value"
                          column
                          :disabled="isReadonly"
                          @change="changeQuestionAnswer(item)"
                        >
                          <v-radio
                            label="0"
                            color="red"
                            :value="0"
                          >
                            <template v-slot:label>
                              <!--<div style="width: 30px;font-weight: 600;position: absolute; left: -32px">{{item.number}}</div>-->
                              <!--margin-left: -30px;margin-right: 0px;text-align: center;-->
                              <div class="circle" :class="{'active-0': item.value === 0}">
                                0
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </div>
                    </td>
                    <td class="dynamic-device">
                      <div class="small-device-version">MILD</div>
                      <div class="survey-value">
                        <v-radio-group
                          v-model="item.value"
                          column
                          :disabled="isReadonly"
                          @change="changeQuestionAnswer(item)"
                        >
                          <v-radio
                            label="1"
                            color="red"
                            :value="1"
                          >
                            <template v-slot:label>
                              <div class="circle" :class="{'active-1': item.value === 1}">
                                1
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </div>
                    </td>
                    <td class="dynamic-device">
                      <div class="small-device-version">MODE</div>
                      <div class="survey-value">
                        <v-radio-group
                          v-model="item.value"
                          column
                          :disabled="isReadonly"
                          @change="changeQuestionAnswer(item)"
                        >
                          <v-radio
                            label="2"
                            color="red"
                            :value="2"
                          >
                            <template v-slot:label>
                              <div class="circle" :class="{'active-2': item.value === 2}">
                                2
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </div>
                    </td>
                    <td class="dynamic-device">
                      <div class="small-device-version">SEV</div>
                      <div class="survey-value">
                        <v-radio-group
                          v-model="item.value"
                          column
                          :disabled="isReadonly"
                          @change="changeQuestionAnswer(item)"
                        >
                          <v-radio
                            label="3"
                            color="red"
                            :value="3"
                          >
                            <template v-slot:label>
                              <div class="circle" :class="{'active-3': item.value === 3}">
                                3
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
            <div class="test-complaints-form" v-if="selectedGroup === 'test&complaints'">
              <v-col cols="12">
                <v-form ref="form" lazy-validation class="test-complaints__form mt-3">
                  <div class="main-row">
                    <div class="test-complaints-column" style="border-right: thin solid rgba(0, 0, 0, 0.12);">
                      <v-row class="align-center" style="border-bottom: thin solid rgba(0, 0, 0, 0.12);padding-bottom: 10px">
                        <v-col cols="10" class="py-0 pr-0">
                          <div class="label" style="margin-top: 14px;padding-bottom: 19px">5 Main Complaints</div>
                          <div v-for="(complaint, index) in testComplaints.complaints" :key="index" class="d-flex five-main-complaints">
                            <Input
                              outlined
                              height="56"
                              radius="16"
                              background-color="#EDF2F7"
                              type="String"
                              :ref="`complaintInput_${index}`"
                              @keyup.enter="addComplaint(index)"
                              @blur="saveTestsComplaints"
                              :placeholder="index + 1 + '.Complaint'"
                              @input="complaint.value = $event"
                              :value="complaint.value"
                            />
                            <!--<Button
                              v-if="testComplaints.complaints.length > 1"
                              class="mr-1 ml-1 mt-3"
                              btn-bg="dark-green"
                              width="30"
                              height="30"
                              @click.native="deleteComplaint(index)"
                              :styles="{ 'border-radius':'50%', 'min-width':'unset' }"
                              darkheight="56">
                              <v-icon color="#ffff">mdi-minus</v-icon>
                            </Button>-->
                          </div>
                        </v-col>
                        <!--<v-col cols="2" class="pl-0 align-self-end mb-6" v-if="testComplaints.complaints.length < 5">
                          <div class="d-flex">
                            <Button
                              btn-bg="dark-green"
                              width="30"
                              height="30"
                              @click.native="addComplaint(testComplaints.complaints.length)"
                              :styles="{ 'border-radius':'50%', 'min-width':'unset' }"
                              darkheight="56">
                              <v-icon color="#ffff">mdi-plus</v-icon>
                            </Button>
                          </div>
                        </v-col>-->
                      </v-row>
                      <!--                    <v-row class="align-center pt-3" style="border-bottom: thin solid rgba(0, 0, 0, 0.12);">
                                            <v-col cols="10" class="py-0">
                                              <div class="label">Barnes Thyroid test</div>
                                              <v-row v-for="(barnesThyroid, index) in testComplaints.barnesThyroidTest" :key="index">
                                                <v-col cols="6">
                                                  <Datepicker
                                                    placeholder="MM/DD/YY"
                                                    height="56"
                                                    @date:update="barnesThyroid.dateTemp = $event;"/>
                                                </v-col>
                                                <v-col cols="6">
                                                  <Input
                                                    outlined
                                                    height="56"
                                                    radius="16"
                                                    tabindex="1"
                                                    placeholder="Temperature"
                                                    background-color="#EDF2F7"
                                                    @input="barnesThyroid.temperature = $event"
                                                    :value="barnesThyroid.temperature"
                                                  />
                                                </v-col>
                                              </v-row>
                                            </v-col>
                                            <v-col cols="2" class="align-self-end mb-2">
                                              <Button
                                                color="#4FAA6E"
                                                width="56"
                                                height="56"
                                                @click.native="addBarnesThyroidTest"
                                                :styles="{ 'border-radius':'50%', 'min-width':'unset' }"
                                                darkheight="56">
                                                <v-icon color="#ffff">mdi-plus</v-icon>
                                              </Button>
                                            </v-col>
                                          </v-row>-->
                    </div>
                    <div class="test-complaints-column">
<!--                      <v-row class="align-center pt-3" style="border-bottom: thin solid rgba(0, 0, 0, 0.12);">
                        <v-col cols="12" class="py-0">
                          <div class="label">Blood Pressure</div>
                          <v-radio-group v-model="testComplaints.bloodPressureValue">
                            <v-radio
                              v-for="blood in testComplaints.bloodPressure.values"
                              :key="blood.name"
                              :label="blood.name"
                              :value="blood.value"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>-->
                      <!--<v-row class="align-center pt-3">
                        <v-col cols="10" class="py-0 blood-type">
                          <div class="title">
                            Blood type
                          </div>
                          <v-slide-group
                            v-model="testComplaints.bloodType"
                            :show-arrows="false"
                          >&lt;!&ndash;mandatory&ndash;&gt;
                            <v-slide-item
                              v-slot="{ active, toggle }"
                              :value="'A+'"
                            >
                              <v-btn
                                class="mx-1 group-button"
                                :input-value="active"
                                active-class="green-bg white&#45;&#45;text"
                                depressed
                                rounded
                                style="font-weight: 600;font-size: 18px"
                                @click="toggle"
                              >
                      <span>
                        A+
                      </span>
                              </v-btn>
                            </v-slide-item>
                            <v-slide-item
                              v-slot="{ active, toggle }"
                              :value="'A-'"
                            >
                              <v-btn
                                class="mx-1 group-button"
                                :input-value="active"
                                active-class="green-bg white&#45;&#45;text"
                                depressed
                                rounded
                                style="font-weight: 600;font-size: 18px"
                                @click="toggle"
                              >
                      <span>
                        A-
                      </span>
                              </v-btn>
                            </v-slide-item>
                            <v-slide-item
                              v-slot="{ active, toggle }"
                              :value="'B+'"
                            >
                              <v-btn
                                class="mx-1 group-button"
                                :input-value="active"
                                active-class="green-bg white&#45;&#45;text"
                                depressed
                                rounded
                                style="font-weight: 600;font-size: 18px"
                                @click="toggle"
                              >
                      <span>
                        B+
                      </span>
                              </v-btn>
                            </v-slide-item>
                            <v-slide-item
                              v-slot="{ active, toggle }"
                              :value="'B-'"
                            >
                              <v-btn
                                class="mx-1 group-button"
                                :input-value="active"
                                active-class="green-bg white&#45;&#45;text"
                                depressed
                                rounded
                                style="font-weight: 600;font-size: 18px"
                                @click="toggle"
                              >
                      <span>
                        B-
                      </span>
                              </v-btn>
                            </v-slide-item>
                            <v-slide-item
                              v-slot="{ active, toggle }"
                              :value="'AB+'"
                            >
                              <v-btn
                                class="mx-1 group-button"
                                :input-value="active"
                                active-class="green-bg white&#45;&#45;text"
                                depressed
                                rounded
                                style="font-weight: 600;font-size: 18px"
                                @click="toggle"
                              >
                      <span>
                        AB+
                      </span>
                              </v-btn>
                            </v-slide-item>
                            <v-slide-item
                              v-slot="{ active, toggle }"
                              :value="'AB-'"
                            >
                              <v-btn
                                class="mx-1 group-button"
                                :input-value="active"
                                active-class="green-bg white&#45;&#45;text"
                                depressed
                                rounded
                                style="font-weight: 600;font-size: 18px"
                                @click="toggle"
                              >
                      <span>
                        AB-
                      </span>
                              </v-btn>
                            </v-slide-item>
                            <v-slide-item
                              v-slot="{ active, toggle }"
                              :value="'O+'"
                            >
                              <v-btn
                                class="mx-1 group-button"
                                :input-value="active"
                                active-class="green-bg white&#45;&#45;text"
                                depressed
                                rounded
                                style="font-weight: 600;font-size: 18px"
                                @click="toggle"
                              >
                      <span>
                        O+
                      </span>
                              </v-btn>
                            </v-slide-item>
                            <v-slide-item
                              v-slot="{ active, toggle }"
                              :value="'O-'"
                            >
                              <v-btn
                                class="mx-1 group-button"
                                :input-value="active"
                                active-class="green-bg white&#45;&#45;text"
                                depressed
                                rounded
                                style="font-weight: 600;font-size: 18px"
                                @click="toggle"
                              >
                      <span>
                        O-
                      </span>
                              </v-btn>
                            </v-slide-item>
                          </v-slide-group>
                        </v-col>
                      </v-row>-->
                      <v-row class="align-center pt-3" style="border-bottom: thin solid rgba(0, 0, 0, 0.12);padding-bottom: 10px">
                        <v-col cols="10" class="py-0">
                          <v-checkbox
                            color="#008C89"
                            v-model="testComplaints.nursingPregnant"
                            @change="saveTestsComplaints"
                            label="Are you Nursing/Pregnant?"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="10" class="py-0">
                          <v-checkbox
                            color="#008C89"
                            v-model="testComplaints.sufferFromHypertension"
                            @change="saveTestsComplaints"
                            label="Do you suffer from hypertension?"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row class="align-center pt-3">
                        <v-col cols="10" class="py-0">
                          <div class="title">
                            Weight (lb)
                          </div>
                          <Input
                            outlined
                            height="56"
                            radius="16"
                            tabindex="1"
                            @keypress="filterInputData($event)"
                            @blur="saveTestsComplaints"
                            background-color="#EDF2F7"
                            @input="testComplaints.weight = +$event"
                            :value="+testComplaints.weight"
                          />
                        </v-col>
                        <v-col cols="10" class="py-0">
                          <div class="title">
                            Height (inch)
                          </div>
                          <Input
                            outlined
                            height="56"
                            radius="16"
                            tabindex="1"
                            @keypress="filterInputData($event)"
                            @blur="saveTestsComplaints"
                            background-color="#EDF2F7"
                            @input="testComplaints.height = +$event"
                            :value="+testComplaints.height"
                          />
                        </v-col>
                        <v-col cols="2">
                        </v-col>
                      </v-row>
                      <!--<v-row class="align-center pt-3">
                        <v-col cols="12" class="py-0">
                          <Button
                            height="55"
                            @click.native="saveTestsComplaints"
                            width="100%"
                            btn-bg="dark-green">
                  <span style="font-weight: 600;font-size: 12px;line-height: 12px;color: white">
                    Save
                  </span>
                          </Button>
                        </v-col>
                      </v-row>-->
                      <!--                    <v-row class="align-center pt-3">
                                            <v-col cols="12" class="py-0">
                                              <span class="other-test-title">Other tests</span>
                                            </v-col>
                                          </v-row>-->
                      <!--                    <v-row class="align-center pt-3">
                                            <v-col cols="10" class="py-0">
                                              <div class="title">
                                                Blood pressure: <span class="sub-title">Recumbent</span>
                                              </div>
                                              <v-row class="align-center">
                                                <v-col cols="5">
                                                  <Input
                                                    outlined
                                                    height="56"
                                                    radius="16"
                                                    tabindex="1"
                                                    background-color="#EDF2F7"
                                                    @input="testComplaints.otherTests.bloodRecumbent.from = $event"
                                                    :value="testComplaints.otherTests.bloodRecumbent.from"
                                                  />
                                                </v-col>
                                                <v-col cols="1" class="align-self-center" style="margin-top: -22px">
                                                  <span class="divider-element">/</span>
                                                </v-col>
                                                <v-col cols="5">
                                                  <Input
                                                    outlined
                                                    height="56"
                                                    radius="16"
                                                    tabindex="1"
                                                    background-color="#EDF2F7"
                                                    @input="testComplaints.otherTests.bloodRecumbent.to = $event"
                                                    :value="testComplaints.otherTests.bloodRecumbent.to"
                                                  />
                                                </v-col>
                                              </v-row>
                                            </v-col>
                                            <v-col cols="2">
                                            </v-col>
                                          </v-row>-->
                      <!--                    <v-row class="align-center pt-3">
                                            <v-col cols="10" class="py-0">
                                              <div class="title">
                                                Blood pressure: <span class="sub-title">Standing</span>
                                              </div>
                                              <v-row class="align-center">
                                                <v-col cols="5">
                                                  <Input
                                                    outlined
                                                    height="56"
                                                    radius="16"
                                                    tabindex="1"
                                                    background-color="#EDF2F7"
                                                    @input="testComplaints.otherTests.bloodStanding.from = $event"
                                                    :value="testComplaints.otherTests.bloodStanding.from"
                                                  />
                                                </v-col>
                                                <v-col cols="1" class="align-self-center" style="margin-top: -22px">
                                                  <span class="divider-element">/</span>
                                                </v-col>
                                                <v-col cols="5">
                                                  <Input
                                                    outlined
                                                    height="56"
                                                    radius="16"
                                                    tabindex="1"
                                                    background-color="#EDF2F7"
                                                    @input="testComplaints.otherTests.bloodStanding.to = $event"
                                                    :value="testComplaints.otherTests.bloodStanding.to"
                                                  />
                                                </v-col>
                                              </v-row>
                                            </v-col>
                                            <v-col cols="2">
                                            </v-col>
                                          </v-row>-->
                      <!--                    <v-row class="align-center pt-3">
                                            <v-col cols="10" class="py-0">
                                              <div class="title">
                                                Pulse: <span class="sub-title">Recumbent</span>
                                              </div>
                                              <Input
                                                outlined
                                                height="56"
                                                radius="16"
                                                tabindex="1"
                                                background-color="#EDF2F7"
                                                @input="testComplaints.otherTests.pulseRecumbent = $event"
                                                :value="testComplaints.otherTests.pulseRecumbent"
                                              />
                                            </v-col>
                                            <v-col cols="2">
                                            </v-col>
                                          </v-row>-->
                      <!--                    <v-row class="align-center pt-3">
                                            <v-col cols="10" class="py-0">
                                              <div class="title">
                                                Pulse: <span class="sub-title">Standing</span>
                                              </div>
                                              <Input
                                                outlined
                                                height="56"
                                                radius="16"
                                                tabindex="1"
                                                background-color="#EDF2F7"
                                                @input="testComplaints.otherTests.pulseStanding = $event"
                                                :value="testComplaints.otherTests.pulseStanding"
                                              />
                                            </v-col>
                                            <v-col cols="2">
                                            </v-col>
                                          </v-row>-->
                      <!--                    <v-row class="align-center pt-3">
                                            <v-col cols="10" class="py-0">
                                              <div class="title">
                                                H-C Urine readings - pH
                                              </div>
                                              <Input
                                                outlined
                                                height="56"
                                                radius="16"
                                                tabindex="1"
                                                background-color="#EDF2F7"
                                                @input="testComplaints.otherTests.HCUrineReadings = $event"
                                                :value="testComplaints.otherTests.HCUrineReadings"
                                              />
                                            </v-col>
                                            <v-col cols="2">
                                            </v-col>
                                          </v-row>-->
                      <!--                    <v-row class="align-center pt-3">
                                            <v-col cols="10" class="py-0">
                                              <div class="title">
                                                Albumin %
                                              </div>
                                              <Input
                                                outlined
                                                height="56"
                                                radius="16"
                                                tabindex="1"
                                                background-color="#EDF2F7"
                                                @input="testComplaints.otherTests.albumin = $event"
                                                :value="testComplaints.otherTests.albumin"
                                              />
                                            </v-col>
                                            <v-col cols="2">
                                            </v-col>
                                          </v-row>-->
                      <!--                    <v-row class="align-center pt-3">
                                            <v-col cols="10" class="py-0">
                                              <div class="title">
                                                Glucose %
                                              </div>
                                              <Input
                                                outlined
                                                height="56"
                                                radius="16"
                                                tabindex="1"
                                                background-color="#EDF2F7"
                                                @input="testComplaints.otherTests.glucose = $event"
                                                :value="testComplaints.otherTests.glucose"
                                              />
                                            </v-col>
                                            <v-col cols="2">
                                            </v-col>
                                          </v-row>-->
                      <!--                    <v-row class="align-center pt-3">
                                            <v-col cols="10" class="py-0">
                                              <div class="title">
                                                Occult blood
                                              </div>
                                              <Input
                                                outlined
                                                height="56"
                                                radius="16"
                                                tabindex="1"
                                                background-color="#EDF2F7"
                                                @input="testComplaints.otherTests.occultBlood = $event"
                                                :value="testComplaints.otherTests.occultBlood"
                                              />
                                            </v-col>
                                            <v-col cols="2">
                                            </v-col>
                                          </v-row>-->
                      <!--                    <v-row class="align-center pt-3">
                                            <v-col cols="10" class="py-0">
                                              <div class="title">
                                                pH of saliva
                                              </div>
                                              <Input
                                                outlined
                                                height="56"
                                                radius="16"
                                                tabindex="1"
                                                background-color="#EDF2F7"
                                                @input="testComplaints.otherTests.phOfSaliva = $event"
                                                :value="testComplaints.otherTests.phOfSaliva"
                                              />
                                            </v-col>
                                            <v-col cols="2">
                                            </v-col>
                                          </v-row>-->
                    </div>
                    <div class="test-complaints-column">
                      <!--                    <v-row class="align-center pt-3">
                                            <v-col cols="10" class="py-0">
                                              <div class="title">
                                                pH of stool
                                              </div>
                                              <Input
                                                outlined
                                                height="56"
                                                radius="16"
                                                tabindex="1"
                                                background-color="#EDF2F7"
                                                @input="testComplaints.otherTests.phOfStool = $event"
                                                :value="testComplaints.otherTests.phOfStool"
                                              />
                                            </v-col>
                                            <v-col cols="2">
                                            </v-col>
                                          </v-row>
                                          <v-row class="align-center pt-3">
                                            <v-col cols="10" class="py-0">
                                              <div class="title">
                                                Blood clotting time
                                              </div>
                                              <Input
                                                outlined
                                                height="56"
                                                radius="16"
                                                tabindex="1"
                                                background-color="#EDF2F7"
                                                @input="testComplaints.otherTests.bloodClottingTime = $event"
                                                :value="testComplaints.otherTests.bloodClottingTime"
                                              />
                                            </v-col>
                                            <v-col cols="2">
                                            </v-col>
                                          </v-row>
                                          <v-row class="align-center pt-3">
                                            <v-col cols="10" class="py-0">
                                              <div class="title">
                                                Hemoglobin
                                              </div>
                                              <Input
                                                outlined
                                                height="56"
                                                radius="16"
                                                tabindex="1"
                                                background-color="#EDF2F7"
                                                @input="testComplaints.otherTests.hemoglobin = $event"
                                                :value="testComplaints.otherTests.hemoglobin"
                                              />
                                            </v-col>
                                            <v-col cols="2">
                                            </v-col>
                                          </v-row>
                                          <v-row class="align-center pt-3">
                                            <v-col cols="10" class="py-0 blood-type">
                                              <div class="title">
                                                Blood type
                                              </div>
                                              <v-slide-group
                                                v-model="testComplaints.otherTests.bloodType"
                                                mandatory
                                                :show-arrows="false"
                                              >
                                                <v-slide-item
                                                  v-slot="{ active, toggle }"
                                                  :value="'A+'"
                                                >
                                                  <v-btn
                                                    class="mx-2 group-button"
                                                    :input-value="active"
                                                    active-class="green-bg white&#45;&#45;text"
                                                    depressed
                                                    rounded
                                                    style="font-weight: 600;font-size: 18px"
                                                    @click="toggle"
                                                  >
                                            <span>
                                              A+
                                            </span>
                                                  </v-btn>
                                                </v-slide-item>
                                                <v-slide-item
                                                  v-slot="{ active, toggle }"
                                                  :value="'A-'"
                                                >
                                                  <v-btn
                                                    class="mx-2 group-button"
                                                    :input-value="active"
                                                    active-class="green-bg white&#45;&#45;text"
                                                    depressed
                                                    rounded
                                                    style="font-weight: 600;font-size: 18px"
                                                    @click="toggle"
                                                  >
                                            <span>
                                              A-
                                            </span>
                                                  </v-btn>
                                                </v-slide-item>
                                                <v-slide-item
                                                  v-slot="{ active, toggle }"
                                                  :value="'B+'"
                                                >
                                                  <v-btn
                                                    class="mx-2 group-button"
                                                    :input-value="active"
                                                    active-class="green-bg white&#45;&#45;text"
                                                    depressed
                                                    rounded
                                                    style="font-weight: 600;font-size: 18px"
                                                    @click="toggle"
                                                  >
                                            <span>
                                              B+
                                            </span>
                                                  </v-btn>
                                                </v-slide-item>
                                                <v-slide-item
                                                  v-slot="{ active, toggle }"
                                                  :value="'B-'"
                                                >
                                                  <v-btn
                                                    class="mx-2 group-button"
                                                    :input-value="active"
                                                    active-class="green-bg white&#45;&#45;text"
                                                    depressed
                                                    rounded
                                                    style="font-weight: 600;font-size: 18px"
                                                    @click="toggle"
                                                  >
                                            <span>
                                              B-
                                            </span>
                                                  </v-btn>
                                                </v-slide-item>
                                                <v-slide-item
                                                  v-slot="{ active, toggle }"
                                                  :value="'AB+'"
                                                >
                                                  <v-btn
                                                    class="mx-2 group-button"
                                                    :input-value="active"
                                                    active-class="green-bg white&#45;&#45;text"
                                                    depressed
                                                    rounded
                                                    style="font-weight: 600;font-size: 18px"
                                                    @click="toggle"
                                                  >
                                            <span>
                                              AB+
                                            </span>
                                                  </v-btn>
                                                </v-slide-item>
                                                <v-slide-item
                                                  v-slot="{ active, toggle }"
                                                  :value="'AB-'"
                                                >
                                                  <v-btn
                                                    class="mx-2 group-button"
                                                    :input-value="active"
                                                    active-class="green-bg white&#45;&#45;text"
                                                    depressed
                                                    rounded
                                                    style="font-weight: 600;font-size: 18px"
                                                    @click="toggle"
                                                  >
                                            <span>
                                              AB-
                                            </span>
                                                  </v-btn>
                                                </v-slide-item>
                                                <v-slide-item
                                                  v-slot="{ active, toggle }"
                                                  :value="'O+'"
                                                >
                                                  <v-btn
                                                    class="mx-2 group-button"
                                                    :input-value="active"
                                                    active-class="green-bg white&#45;&#45;text"
                                                    depressed
                                                    rounded
                                                    style="font-weight: 600;font-size: 18px"
                                                    @click="toggle"
                                                  >
                                            <span>
                                              O+
                                            </span>
                                                  </v-btn>
                                                </v-slide-item>
                                                <v-slide-item
                                                  v-slot="{ active, toggle }"
                                                  :value="'O-'"
                                                >
                                                  <v-btn
                                                    class="mx-2 group-button"
                                                    :input-value="active"
                                                    active-class="green-bg white&#45;&#45;text"
                                                    depressed
                                                    rounded
                                                    style="font-weight: 600;font-size: 18px"
                                                    @click="toggle"
                                                  >
                                            <span>
                                              O-
                                            </span>
                                                  </v-btn>
                                                </v-slide-item>
                                              </v-slide-group>
                                            </v-col>
                                          </v-row>
                                          <v-row class="align-center pt-3">
                                            <v-col cols="12" class="py-0">
                                              <v-checkbox
                                                v-model="testComplaints.reglandsTestInPositive"
                                                label="Reglands Test in Positive (drop in excess of 9mm)"
                                              ></v-checkbox>
                                            </v-col>
                                          </v-row>
                                          <v-row class="align-center pt-3">
                                            <v-col cols="12" class="py-0">
                                              <Button
                                                dark
                                                height="55"
                                                @click.native="saveTestsComplaints"
                                                width="100%"
                                                color="#4FAA6E">
                                        <span style="font-weight: 600;font-size: 12px;line-height: 12px;">
                                          Save
                                        </span>
                                              </Button>
                                            </v-col>
                                          </v-row>
                                          <v-row class="align-center pt-3" style="border-bottom: thin solid rgba(0, 0, 0, 0.12);">
                                            <v-col cols="12" class="py-0">
                                              <div class="label">Blood Pressure</div>
                                              <v-radio-group v-model="testComplaints.bloodPressure.value">
                                                <v-radio
                                                  v-for="blood in testComplaints.bloodPressure.values"
                                                  :key="blood"
                                                  :label="blood"
                                                  :value="blood"
                                                ></v-radio>
                                              </v-radio-group>
                                            </v-col>
                                          </v-row>-->
                    </div>
                  </div>
                </v-form>
              </v-col>
            </div>
          </v-row>
          <!--NEW CONTROLS-->
          <v-row class="pl-8 pr-8 pt-2 pb-2 grey-bg">
            <Button
              btn-bg="white"
              height="45"
              width="100"
              :disabled="!prevGroupIdValue"
              :styles="{ 'border-radius': '8px', 'border': '1px solid #008C89' }"
              @click.native="selectCurrentGroup(-1)"
            >
              <span style="color: #008C89;font-weight: 600">{{ prevGroupIdValue ? prevGroupIdValue.toUpperCase() : '' }}</span>
              <span style="color: black;font-weight: 600;padding-left: 5px">Prev</span>
            </Button>
            <Button
              btn-bg="dark-green"
              height="45"
              width="50"
              class="mx-2"
              :styles="{ 'border-radius': '8px', 'min-width': '50px' }"
            >
              <span style="color: #FFFFFF">{{ selectedGroupValue ? selectedGroupValue.toUpperCase() : '' }}</span>
            </Button>
            <Button
              btn-bg="white"
              height="45"
              width="100"
              :disabled="!nextGroupIdValue"
              :styles="{ 'border-radius': '8px', 'border': '1px solid #008C89' }"
              @click.native="selectCurrentGroup(1)"
            >
              <span style="color: black;font-weight: 600;padding-right: 5px;">Next</span>
              <span style="color: #008C89;font-weight: 600">{{ nextGroupIdValue ? nextGroupIdValue.toUpperCase() : '' }}</span>
            </Button>
            <Button
              class="ml-15"
              v-show="!nextGroupIdValue"
              btn-bg="dark-green"
              height="45"
              width="130"
              :disabled="!canSubmit"
              @click.native="sendSurvey"
              :styles="{ 'border-radius': '8px' }"
            >
              <span style="color: #FFFFFF">{{ $t('survey.submit') }}</span>
            </Button>
          </v-row>
          <br>
          <!--NEW CONTROLS-->
        </template>
        <template v-else>
          <v-row no-gutters class="mt-2 readonly-mode">
            <div class="d-flex survey-table-desktop-mobile" v-if="surveyGroups">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                  <tr v-for="(group, index) in surveyGroups" :key="group.id" class="desktop-version-readonly-mode">
                    <td class="dynamic-device" style="position:relative;width: 400px">
                      <div style="width: 300px;margin-left: auto;margin-right: auto;left: 0;right: 0;padding: 10px 0;">
                        <div style="color: #8492A6;font-weight: 600;font-size: 14px;">Group {{group.groupNumber}}</div>
                        <div style="color: #16192C;font-weight: 600;font-size: 20px;">{{group.name}}</div>
                        <div style="color: #61ddae;font-weight: 600;font-size: 20px;text-align: center;left: 50%;">{{group.total}}</div>
                      </div>
                    </td>
                    <td class="dynamic-device">
                      <div v-for="item in surveyGroups[index].questions.$values" :key="item.id" style="padding-top: 5px;">
                        <div class="d-flex align-center">
                          <div class="survey-value">
                            <v-radio
                              :label="item.value"
                              :value="item.value"
                            >
                              <template v-slot:label>
                                <div class="circle" :class="{'active-0': item.value === 0, 'active-1': item.value === 1, 'active-2': item.value === 2, 'active-3': item.value === 3}">
                                  {{item.value}}
                                </div>
                              </template>
                            </v-radio>
                          </div>
                          <div style="padding-left: 5px">—</div>
                          <div class="question-number">{{item.number}}</div>
                          <div class="symptoms">
                            {{item.text}}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <div class="mobile-version-readonly-mode" v-for="(group, index) in surveyGroups" :key="group.id" style="width: 100%">
                    <tr>
                      <td class="dynamic-device" style="width: 100vw;">
                        <div style="width: fit-content; padding: 10px 5px;">
                          <div style="color: #8492A6;font-weight: 600;font-size: 14px;">Group {{group.groupNumber}}</div>
                          <div style="color: #16192C;font-weight: 600;font-size: 20px;">{{group.name}}</div>
                          <div style="color: #61ddae;font-weight: 600;font-size: 20px;text-align: center">{{group.total}}</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="dynamic-device">
                        <div v-for="item in surveyGroups[index].questions.$values" :key="item.id" style="padding: 5px 5px">
                          <div class="d-flex align-center">
                            <div class="survey-value">
                              <v-radio
                                :label="item.value"
                                :value="item.value"
                              >
                                <template v-slot:label>
                                  <div class="circle" :class="{'active-0': item.value === 0, 'active-1': item.value === 1, 'active-2': item.value === 2, 'active-3': item.value === 3}">
                                    {{item.value}}
                                  </div>
                                </template>
                              </v-radio>
                            </div>
                            <div style="padding-left: 5px">—</div>
                            <div class="question-number">{{item.number}}</div>
                            <div class="symptoms">
                              {{item.text}}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </div>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-row>
          <v-row class="mt-2" style="padding-left: 28px">
            <v-card flat style="width: 100%;">
              <v-card-text>
                <v-row no-gutters class="flex-column readonly-mode-tests-complaints">
                  <div class="label">{{$t('survey.t&c')}}</div>
                  <v-row no-gutters class="mt-4">
                    <v-col cols="6">
                      <v-row no-gutters>
                        <v-checkbox
                          color="#008C89"
                          disabled
                          v-model="testComplaints.nursingPregnant"
                          :label="$t('survey.youNursing')"
                        ></v-checkbox>
                      </v-row>
                      <v-row no-gutters>
                        <v-checkbox
                          color="#008C89"
                          disabled
                          v-model="testComplaints.sufferFromHypertension"
                          :label="$t('survey.youSufferHypertension')"
                        ></v-checkbox>
                      </v-row>
                      <v-row no-gutters class="align-center">
                        <div class="title" style="width: 135px">
                          {{$t('survey.weightLb')}}
                        </div>
                        <div class="readonly-mode-tests-complaints__value">{{testComplaints.weight || '-'}}</div>
                      </v-row>
                      <v-row no-gutters class="align-center">
                        <div class="title" style="width: 135px">
                          {{$t('survey.heightInch')}}
                        </div>
                        <div class="readonly-mode-tests-complaints__value">{{testComplaints.height || '-'}}</div>
                      </v-row>
                    </v-col>
                    <v-col cols="6">
                      <v-row no-gutters class="align-center">
                        <div class="title" style="width: 290px">
                          {{$t('survey.bloodPressure')}}: {{$t('survey.recumbent')}}
                        </div>
                        <div class="readonly-mode-tests-complaints__value">{{testComplaints.bpRecumbent_Systolic || '-'}}/{{testComplaints.bpRecumbent_Diastolic || '-'}}</div>
                      </v-row>
                      <v-row no-gutters class="align-center">
                        <div class="title" style="width: 290px">
                          {{$t('survey.bloodPressure')}}: {{$t('survey.standing')}}
                        </div>
                        <div class="readonly-mode-tests-complaints__value">{{testComplaints.bpStanding_Systolic || '-'}}/{{testComplaints.bpStanding_Diastolic || '-'}}</div>
                      </v-row>
                      <v-row no-gutters class="align-center">
                        <div class="title" style="width: 160px">
                          {{$t('survey.bloodType')}}
                        </div>
                        <div class="readonly-mode-tests-complaints__value">
                          <v-slide-item
                            :value="testComplaints.bloodType || '-A'"
                          >
                            <v-btn
                              class="mx-1 group-button"
                              active-class="green-bg white--text"
                              depressed
                              rounded
                              style="font-weight: 600;font-size: 18px;pointer-events: none"
                            >
                              <span>
                                {{testComplaints.bloodType || '-A'}}
                              </span>
                            </v-btn>
                          </v-slide-item>
                        </div>
                      </v-row>
                      <v-row no-gutters>
                        <v-checkbox
                          color="#008C89"
                          disabled
                          v-model="testComplaints.raglanTestPositive"
                          :label="$t('survey.reglandsTestPositive')"
                        ></v-checkbox>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-row>
              </v-card-text>
            </v-card>
          </v-row>
        </template>
      </template>
      <template v-else>
        <div class="empty-data">No active Survey</div>
      </template>
    </template>
    <template v-else>
      <loader/>
    </template>
    <div class="custom-alert">
      <v-alert
        color="#7ac1fb"
        dismissible
        type="info"
        v-model="alertIsOpen"
      >
        <span style="font-size: 14px;">{{popupMessage}}</span>
      </v-alert>
    </div>
  </div>
</template>

<script>
import Button from '@/components/elements/Button'
import Breadcrumbs from '@/components/layouts/Breadcrumbs'
import { mapActions, mapState } from 'vuex'
import Input from '@/components/elements/Input'
// import Datepicker from '@/components/elements/datepicker'
import Loader from '@/components/elements/loader'
import Number from '@/mixins/Number'

export default {
  name: 'survey',
  components: { Button, Input, Breadcrumbs, Loader }, // Datepicker
  mixins: [Number],
  data: () => ({
    surveyProgress: 0,
    selectedGroup: 0,
    prevGroupIdValue: 0,
    selectedGroupValue: 0,
    nextGroupIdValue: 0,
    tcIsViewed: false,
    testComplaints: {
      complaints: [{ value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }],
      bloodPressure: {
        values: [{ name: 'Unknown', value: 0 }, { name: 'Below normal for age', value: 1 }, { name: 'Normal for age', value: 2 }, { name: 'Above normal for age', value: 3 }]
      },
      bloodPressureValue: 0,
      weight: 0,
      height: 0,
      nursingPregnant: false,
      sufferFromHypertension: false
    },
    alertIsOpen: false,
    popupMessage: null
  }),
  mounted () {
    this.scroll()
    this.loadData()
  },
  methods: {
    // isValueNotEqualNull (element) {
    //   return element.value !== null
    // },
    async loadData () {
      if (!this.dashboardInfo) {
        this.getPatientDashboard().then(() => {
          this.getSurveyGroups({ surveyID: this.dashboardInfo.menuRefs.surveyId, additionalParam: '?includeAllGroups=true' }).then(res => {
            // this.selectedGroup = 0
            this.tcIsViewed = this.surveyData.tcViewed
            this.surveyProgress = this.progress
            if (this.currentGroupId === 1) {
              this.getSurveyGroupByID({ surveyID: this.dashboardInfo.menuRefs.surveyId, groupID: '?groupId=1' })
            }
            this.selectedGroup = this.surveyGroups.findIndex((group => {
              return group.id === this.currentGroupId
            }))
            this.selectedGroupValue = this.surveyGroups[this.selectedGroup].groupNumber
            this.prevGroupIdValue = this.prevGroupId !== null ? this.surveyGroups.find((group => {
              return group.id === +this.prevGroupId
            })).groupNumber : null
            this.nextGroupIdValue = this.nextGroupId !== null ?  this.surveyGroups.find((group => {
              return group.id === +this.nextGroupId
            })).groupNumber : null

            if (this.selectedGroup === (this.surveyGroups.length - 1)) {
              this.nextGroupIdValue = 'T&C'
            }
          })
          /*const res = this.getSurveyDetails({ surveyID: this.dashboardInfo.menuRefs.surveyId })
          delete res.complaints.$id
          this.tcIsViewed = res.tcViewed
          res.complaints = res.complaints.$values.map(item => {
            return { value: item }
          })
          delete res.complaints.$values
          this.testComplaints = { ...res, bloodPressureValue: res.bloodPressure }*/
        })
      } else {
        this.getSurveyGroups({ surveyID: this.dashboardInfo.menuRefs.surveyId, additionalParam: '?includeAllGroups=true' }).then(() => {
          // this.selectedGroup = 0
          this.tcIsViewed = this.surveyData.tcViewed
          this.surveyProgress = this.progress
          if (this.currentGroupId === 1) {
            this.getSurveyGroupByID({ surveyID: this.dashboardInfo.menuRefs.surveyId, groupID: '?groupId=1' })
          }
          this.selectedGroup = this.surveyGroups.findIndex((group => {
            return group.id === this.currentGroupId
          }))
          this.selectedGroupValue = this.surveyGroups[this.selectedGroup].groupNumber
          this.prevGroupIdValue = this.prevGroupId !== null ? this.surveyGroups.find((group => {
            return group.id === +this.prevGroupId
          })).groupNumber : null
          this.nextGroupIdValue = this.nextGroupId !== null ?  this.surveyGroups.find((group => {
            return group.id === +this.nextGroupId
          })).groupNumber : null

          if (this.selectedGroup === (this.surveyGroups.length - 1)) {
            this.nextGroupIdValue = 'T&C'
          }
        })
        /*const res = await this.getSurveyDetails({ surveyID: this.dashboardInfo.menuRefs.surveyId })
        delete res.complaints?.$id
        this.tcIsViewed = res.tcViewed
        res.complaints = res.complaints.$values.map(item => {
          return { value: item }
        })
        delete res.complaints.$values
        this.testComplaints = { ...res }*/
      }
    },
    async loadDataQuestionsByGroupID (index) {
      await this.getSurveyGroupByID({ surveyID: this.dashboardInfo.menuRefs.surveyId, groupID: '?groupId=' + this.surveyGroups[index].id })
      return true
    },
    updateUserAnswerInSurvey (surveyGroupID, guestionID, questionValue) {
      const surveyID = this.dashboardInfo.menuRefs.surveyId
      const data = {
        surveyId: surveyID,
        surveyGroupId: surveyGroupID,
        answers: [
          {
            questionId: guestionID,
            value: questionValue
          }
        ]
      }
      this.updateSurveyAnswer({ surveyID, ...data })
    },
    ...mapActions('patients', ['getPatientDashboard', 'getSurveyGroups', 'getSurveyGroupByID', 'updateSurveyAnswer', 'getSurveyDetails', 'submitSurvey', 'updateTestComplaints']),
    scroll () {
      window.onscroll = () => {
        const bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight
        if (bottomOfWindow && this.surveyGroups) { // orig without && this.surveyGroups
          this.surveyGroups[this.selectedGroup].isCompleted = true // temp
          if (!this.isReadonly) {
            this.updateUserAnswerInSurvey(this.surveyGroups[this.selectedGroup].id, this.surveyGroups[this.selectedGroup].questions.$values[0].id, 0)
          }
        }
      }
    },
    getScroll () {
      return (document.body.scrollHeight !== window.innerHeight)
    },
    async sendSurvey () {
      if (this.canSubmit) {
        const res = await this.submitSurvey({ surveyId: this.dashboardInfo.menuRefs.surveyId, visitId: this.dashboardInfo.menuRefs.surveyVisitId })
        if (res) {
          this.alertIsOpen = true
          this.popupMessage = 'Thank you! Survey was submitted to the Practitioner'
          const self = this
          setTimeout(function () {
            self.alertIsOpen = false
            self.$router.push({ name: 'Visit', visitId: self.$route.params.id })
          }, 1300)
        }
      }
    },
    changeQuestionAnswer (item) {
      if (!this.isReadonly) {
        this.updateUserAnswerInSurvey(this.surveyGroups[this.selectedGroup].id, item.id, item.value)
        this.surveyGroups[this.selectedGroup].isCompleted = true
      }
    },
    deleteComplaint (index) {
      this.testComplaints.complaints.splice(index, 1)
    },
    addComplaint (index) {
      if (index !== 4) {
        this.$refs[`complaintInput_${index + 1}`][0].$el.querySelector('input[type="String"]').focus()
      } else {
        this.$refs[`complaintInput_${index}`][0].$el.querySelector('input[type="String"]').blur()
      }
      /*if (this.testComplaints.complaints.length < 5) {
        this.testComplaints.complaints.push({ value: '' })
      }*/
    },
    addBarnesThyroidTest () {
      this.testComplaints.barnesThyroidTest.push({ date: null, value: null })
    },
    saveTestsComplaints () {
      const data = { ...this.testComplaints }
      data.complaints = data.complaints.map(item => {
        return item.value
      })
      data.bloodPressure = this.testComplaints.bloodPressureValue
      delete data.bloodPressureValue
      data.weight = +data.weight
      this.updateTestComplaints({ surveyId: this.dashboardInfo.menuRefs.surveyId, data })
    },
    async selectCurrentGroup(val) {
      const currentIndex = this.selectedGroupValue === 'T&C' ? this.surveyGroups.length : this.surveyGroups.findIndex(group => {
          return group.groupNumber === this.selectedGroupValue
      })
      const newCurrentIndex = currentIndex + val

      if ((newCurrentIndex >= 0) && (newCurrentIndex < this.surveyGroups.length)) {
        this.selectedGroup = newCurrentIndex
        this.selectedGroupValue = this.surveyGroups[newCurrentIndex].groupNumber
        const id = this.surveyGroups[newCurrentIndex].id
        this.getSurveyGroupByID({ surveyID: this.dashboardInfo.menuRefs.surveyId, groupID: '?groupId=' +id }).then(() => {
          if (newCurrentIndex === this.surveyGroups.length - 1) {
            this.prevGroupIdValue = this.prevGroupId !== null ? this.surveyGroups.find((group => {
              return group.id === +this.prevGroupId
            })).groupNumber : null
            this.nextGroupIdValue = 'T&C'
            this.surveyProgress = this.surveyGroups[newCurrentIndex].progress
          } else if (newCurrentIndex === this.surveyGroups.length) {
            this.selectedGroup = 'test&complaints'
            this.selectedGroupValue = 'T&C'
            this.prevGroupIdValue = this.surveyGroups[newCurrentIndex - 1].groupNumber
            this.nextGroupIdValue = null
          } else {
            this.surveyProgress = this.surveyGroups[newCurrentIndex].progress
            this.prevGroupIdValue = this.prevGroupId !== null ? this.surveyGroups.find((group => {
              return group.id === +this.prevGroupId
            })).groupNumber : null
            this.nextGroupIdValue = this.nextGroupId !== null ?  this.surveyGroups.find((group => {
              return group.id === +this.nextGroupId
            })).groupNumber : null
          }
        })
      }
      if (newCurrentIndex === this.surveyGroups.length - 1) {
        this.nextGroupIdValue = 'T&C'
      } else if (newCurrentIndex === this.surveyGroups.length) {
        this.selectedGroup = 'test&complaints'
        this.selectedGroupValue = 'T&C'
        this.prevGroupIdValue = this.surveyGroups[newCurrentIndex - 1].groupNumber
        this.nextGroupIdValue = null
      }

      if (this.selectedGroupValue === 'T&C') {
        const res = await this.getSurveyDetails({ surveyID: this.dashboardInfo.menuRefs.surveyId })
        delete res.complaints?.$id
        this.tcIsViewed = res.tcViewed
        this.surveyProgress = res.progress
        const complaints = []
        for (let i = 0; i < 5; i++) {
          complaints.push({ value: res.complaints.$values[i]})
        }
        /*res.complaints = res.complaints.$values.map(item => {
          return { value: item }
        })*/
        delete res.complaints
        this.testComplaints = { ...res, complaints: complaints }
      }
    },
    async changeSelectedGroup() {
      if (this.selectedGroup !== 'test&complaints') {
        if (!this.getScroll()) {
          this.surveyGroups[this.selectedGroup].isCompleted = true // temp
          if (!this.isReadonly) {
            this.updateUserAnswerInSurvey(this.surveyGroups[this.selectedGroup - 1].id, this.surveyGroups[this.selectedGroup - 1].questions.$values[0].id, 0)
          }
        }

        this.loadDataQuestionsByGroupID(this.selectedGroup).then(() => {
          this.selectedGroupValue = this.surveyGroups[this.selectedGroup].groupNumber
          this.prevGroupIdValue = this.prevGroupId !== null ? this.surveyGroups.find((group => {
            return group.id === +this.prevGroupId
          })).groupNumber : null
          this.nextGroupIdValue = this.nextGroupId !== null ?  this.surveyGroups.find((group => {
            return group.id === +this.nextGroupId
          })).groupNumber : null
        })
      }
      if (this.selectedGroup === 'test&complaints') {
        const res = await this.getSurveyDetails({ surveyID: this.dashboardInfo.menuRefs.surveyId })
        this.tcIsViewed = res.tcViewed
        const complaints = []
        for (let i = 0; i < 5; i++) {
          complaints.push({ value: res.complaints.$values[i]})
        }
        delete res.complaints
        this.testComplaints = { ...res, complaints: complaints }
        /*if (res.complaints.$values) {
          res.complaints = res.complaints.$values.map(item => {
            return { value: item }
          })
          delete res.complaints.$values
          this.testComplaints = { ...res }
        }*/
      }
    }
  },
  watch: {
    /* surveyGroups: {
      handler: function (val) {
        console.log(val[this.selectedGroup].questions.$values)
      },
      deep: true
    }, */
  },
  computed: {
    ...mapState({
      dashboardInfo: state => state.patients.dashboardInfo
    }),
    progress () {
      return this.$store.state.patients.surveyGroups?.progress
    },
    surveyData () {
      return this.$store.state.patients.surveyGroups
    },
    surveyGroups () {
      return this.$store.state.patients.surveyGroups?.groups?.$values
    },
    isReadonly () {
      return this.$store.state.patients.surveyGroups?.readonly
    },
    dueDate () {
      return this.$store.state.patients.surveyGroups?.dueDate
    },
    canSubmit () {
      return this.$store.state.patients.surveyGroups?.canSubmit
    },
    currentSelectedGroup () {
      return this.selectedGroup !== this.surveyGroups.length ? this.surveyGroups[this.selectedGroup] : null
    },
    surveyQuestions () {
      return this.currentSelectedGroup ? this.currentSelectedGroup.questions?.$values : []
    },
    prevGroupId () {
      return this.$store.state.patients.surveyGroups.prevGroupId
    },
    currentGroupId () {
      return this.$store.state.patients.surveyGroups.currentGroupId
    },
    nextGroupId () {
      return this.$store.state.patients.surveyGroups.nextGroupId
    },
  }
}
</script>

<style scoped lang="scss">
.survey {
  //padding: 0 16px;
  .breadcrumbs {
    background: #F7FAFC;
    padding: 10px 16px;
  }
  &__title {
    font-weight: 600;
    font-size: 22px;
    color: #1A202C;
  }
  &__due-date {
    font-weight: normal;
    font-size: 14px;
    color: #A0AEC0;
  }
  &__date {
    font-weight: 600;
    font-size: 14px;
    color: #1A202C;
  }
  .empty-data {
    text-align: center;
    min-height: calc(100vh - 110px);
  }
  ::v-deep .v-btn.group-button {
    width: 37px;
    min-width: 37px!important;
    height: 33px;
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    background-color: transparent !important;
    cursor: default;
  }
  ::v-deep .v-btn.green-bg {
    background-color: $dark-green !important;
    border: 0px !important;
  }
  ::v-deep .v-btn.green-light-bg {
    background-color: $dark-green-transparent !important;
    border: 0px !important;
  }
  ::v-deep .v-slide-group__content .v-icon {
    font-size: 20px !important;
  }
  .progress-block {
    .mobile-percent {
      display: none;
    }
    .desktop-percent {
      text-align: center;
    }
    .submit-btn {
      text-align: right;
      margin-left: 10px;

      ::v-deep .v-btn.v-btn--disabled {
        background-color: rgb(0 140 137 / 50%) !important;
        cursor: not-allowed !important;
      }
    }
  }
  .survey-table-head-desktop {
    width: 100%;
    position: relative;
    display: block;
    .survey-title {
      color: #CBD5E0;
      font-weight: 600;
      font-size: 12px;
    }
  }
  .survey-table-desktop-mobile {
    display: block;
    width: 100%;
    position: relative;
    padding: 0 16px;
    background-color: #F7FAFC;
    ::v-deep .v-data-table .v-data-table__wrapper .header tr th {
      padding: 0 6px !important;
    }
    .header {
      tr > th {
        font-weight: 600;
        font-size: 12px;
        color: #CBD5E0;
      }
    }
    .dynamic-device {
      padding: 0 4px !important;
      .small-device-version {
        display: none;
      }
      .question-number {
        padding-left: 5px;
        font-weight: 600;
        font-size: 14px;
        color: #1A202C;
      }
      .symptoms {
        padding-left: 10px;
        color: #1A202C;
        font-weight: 500;
        font-size: 14px;
      }
      .survey-value {
        ::v-deep .v-input--selection-controls__input {
          position: absolute !important;
          visibility: hidden;
        }
        ::v-deep .v-input__control {
          height: 40px !important; // 30
          .v-input__slot {
            margin-bottom: 0px !important;
          }
        }
        ::v-deep .v-input--selection-controls {
          margin-top: 0px !important;
          padding-top: 0px !important;
        }
        .circle {
          width: 40px;
          height: 40px;
          border: 2px solid #CBD5E0;
          background: rgba(203, 213, 224, 0.2);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #CBD5E0;
        }
        .circle.active-0 {
          border-color: $dark-green;
          background-color: $light-dark-green;
          color: #4FAA6E;
        }
        .circle.active-1 {
          border-color: #D4CE15;
          background-color: rgba(212, 206, 21, 0.2);
          color: #D4CE15;
        }
        .circle.active-2 {
          border-color: #FF7A4A;
          background-color: rgba(255, 122, 74, 0.2);
          color: #FF7A4A;
        }
        .circle.active-3 {
          border-color: #F23A4E;
          background-color: rgba(242, 58, 78, 0.2);
          color: #F23A4E;
        }
      }
    }
  }
  .survey-table-mobile {
    display: none;
    width: 100%;
    margin-top: 20px;
    .survey-title {
      color: #CBD5E0;
      font-weight: 600;
      font-size: 12px;
    }
  }
  .mobile-version-readonly-mode {
    display: none;
  }
  ::v-deep .theme--light.v-data-table {
    //background-color: transparent !important;
    .grey-bg {
      background: #ececec!important;
    }
  }
  ::v-deep .v-data-table .v-data-table__wrapper {
    padding-left: 30px;
    tr {
      background-color: transparent !important;
    }
    td {
      border-bottom: 0px !important;
    }
    overflow: unset !important;
  }
  .green-text {
    color: $dark-green;
  }
  .green-bg {
    background: $dark-green !important;
  }
  .green-border {
    border: 1px solid $dark-green !important;
  }
  .grey-bg {
    background: #ececec!important;
  }

  .test-complaints-form {
    display: block;
    width: 100%;
    position: relative;
    padding: 0 16px;
    background-color: #F7FAFC;
    .test-complaints__form {
      .label {
        font-weight: 600;
        font-size: 18px;
        color: #1A202C;
      }
      .other-test-title {
        font-weight: 600;
        font-size: 18px;
        color: #1A202C;
      }
      .title {
        font-weight: 500;
        font-size: 14px;
        color: #425466;
        .sub-title {
          font-weight: 600;
          font-size: 17px;
        }
      }
      .blood-type {
        ::v-deep .v-slide-group__content {
          display: unset !important;
          flex: unset !important;
          white-space: pre-wrap !important;
          .v-btn {
            margin-top: 2px !important;
            margin-bottom: 2px !important;
          }
        }
      }
      @media screen and (min-width: 1000px) {
        .main-row {
          display: flex;
          flex-wrap: wrap;
          column-gap: 1rem;
          justify-content: space-between;
          .test-complaints-column {
            width: calc(92% / 3);
            ::v-deep .v-input:not(.v-input--is-focused, .v-input--checkbox) .v-input__control .v-input__slot {
              box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgb(50 50 71);
            }
          }
          .test-complaints-column:first-child {
            .row:last-child {
              border-bottom: unset!important;
            }
          }
        }
      }
      @media screen and (max-width: 1000px) {
        .main-row {
          .test-complaints-column:nth-child(1) {
            .row:first-child {
              margin-top: 12px;
            }
          }
        }
      }

    }
    .five-main-complaints {
      .input {
        ::v-deep .v-input {
          width: 300px;
        }
      }
    }
  }

  .readonly-mode {
    ::v-deep .v-data-table .v-data-table__wrapper {
      .desktop-version-readonly-mode {
        tr {
          background-color: white !important;
        }
        td {
          border-bottom: 1px solid #E2E8F0 !important;
          border-right: 1px solid #E2E8F0!important;
        }
        td:last-child {
          border-right: unset!important;
        }
        border-radius: 10px!important;
        overflow-x: auto!important;
        overflow-y: hidden!important;
      }
      .mobile-version-readonly-mode {
        tr {
          background-color: transparent !important;
        }
        td {
          border-bottom: unset!important;
          border-right: unset!important;
        }
        tr:last-child > td {
          border-bottom: 1px solid #E2E8F0!important;
        }
      }
      .mobile-version-readonly-mode:last-child {
        tr:last-child > td {
          border-bottom: unset!important;
        }
      }
    }
  }
  .readonly-mode-tests-complaints {
    ::v-deep .v-input {
      margin-top: 0;
      &__control {
        .v-messages {
          display: none;
        }
      }
    }
    .label {
      color: rgb(22, 25, 44);
      font-weight: 600;
      font-size: 20px;
    }
    &__value {
      margin-left: 30px;
      color: rgb(22, 25, 44);
      font-weight: 600;
      font-size: 16px;
    }
  }

  @media (min-width: 375px) {
    .col-sm-7 {
      flex: 0 0 58.6666666667% !important;
      max-width: 58.6666666667% !important;
    }
  }
  @media (min-width: 375px) {
    .col-sm-2 {
      flex: 0 0 8.3333333333% !important;
      max-width: 8.3333333333% !important;
    }
  }
  @media (min-width: 375px) {
    .col-sm-3 {
      flex: 0 0 28.3333333333% !important;
      max-width: 28.3333333333% !important;
    }
  }
  @media (max-width: 375px) {
    .progress-block {
      .submit-btn {
        text-align: center!important;
        margin-left: 0 !important;
        margin-top: 6px;
      }
    }
  }
  @media (max-width: 375px) {
    .desktop-percent {
      display: none !important;
    }
    .progress-block {
      .mobile-percent {
        display: block;
        text-align: center;
      }
    }
  }
  @media (max-width: 340px) {
    .survey-table-desktop-mobile {
      .header {
        display: none !important;
      }
      .dynamic-device {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .small-device-version {
          display: block;
        }
        .symptoms .number-of-index {
          display: none;
        }
      }
    }
  }
  @media (max-width: 540px) {
    .desktop-version-readonly-mode {
      display: none;
    }
    .mobile-version-readonly-mode {
      display: block;
    }
  }
  @media (min-width: 530px) {
    .survey-table-desktop-mobile {
      .header {
        th {
          width: 60px;
        }
        //th:nth-child(4) {
        //  width: 100px;
        //}
        th:nth-child(1) {
          width: 55%;
        }
        th:last-child {
          width: auto;
        }
      }
    }
  }
  @media (max-width: 900px) {
    .slide-group {
      display: none;
    }
  }
  @media (min-width: 1140px) {
    .group-box {
      display: flex;
      align-items: center;
    }
  }
  .custom-alert {
    max-width: 300px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -150px;
    z-index: 100;
  }
}
</style>
