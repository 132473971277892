import Vue from 'vue'

const state = () => ({
  visit: null,
  model: null,
  visitsInfo: {},
  visitHistory: {}
})

const getters = {}

const actions = {
  async addVisit ({ commit }, params) {
    const result = await Vue.$http.post('/Visits/Add', { ...params })
    if (result?.status === 200) {
      commit('TOGGLE_DIALOG', { name: 'addVisit', isOpen: false }, { root: true })
      console.log(`Respons: The visit was added; Status: ${result?.status}`)
      return result.data?.record
    }
  },
  async getVisit ({ commit }, id) {
    const result = await Vue.$http.get(`/Visits/${id}/Get`)
    if (result?.status === 200) {
      commit('SET_DATA', ['visit', result.data?.record])
    }
  },
  async getModel ({ commit }, id) {
    const result = await Vue.$http.get(`/Visits/GetModel?PatientId=${id}`)
    if (result?.status === 200) {
      commit('SET_DATA', ['model', result.data?.record])
    }
  },
  async updateReschedule ({ commit }, params) {
    const { id, data } = params
    const result = await Vue.$http.put(`/Visits/${id}/Reschedule`, data)
    if (result?.status === 200) {
      commit('SET_DATA', ['model', result.data?.record])
    }
  },
  async getVisitsInfo ({ commit }, id) {
    const result = await Vue.$http.get(`/Visits/${id}/GetInfo`)
    if (result?.status === 200) {
      commit('SET_DATA', ['visitsInfo', result.data?.record])
      return true
    }
  },
  async getVisitsHistory ({ commit }, id) {
    const result = await Vue.$http.post('/Visits/GetVisits')
    if (result?.status === 200) {
      commit('SET_DATA', ['visitHistory', result.data?.data])
    }
  }
}

const mutations = {
  SET_DATA: (state, payload) => {
    const [property, value] = payload
    Vue.set(state, property, value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
