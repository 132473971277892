<template>
  <div class="link">
    <router-link v-bind="$props" :style="styles">
      <slot></slot>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Link',
  props: {
    to: String,
    color: String,
    size: String,
    weight: String
  },
  computed: {
    styles () {
      return {
        color: this.color,
        'font-size': this.size + 'px',
        'font-weight': this.weight
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .link {
    a {
      text-decoration: none !important;
    }
  }
</style>
