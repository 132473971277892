<template>
  <v-card class="visit-info-block" @click.stop="openReport(visit, '')">
    <v-card-title>
      <div class="main-row">
        <div class="date">
          <div class="label">Evaluation</div>
          <div class="value">{{$moment(visit.date).format('MM/DD/YYYY')}}</div>
        </div>
        <div class="info-doctor">
          <v-avatar size="71" class="avatar" @click.stop="openDoctorInfo">
            <img :src="visit.doctor.logoFull || visit.doctor.logoSmall"/>
          </v-avatar>
          <div class="full-name">
            <div class="label">Practitioner</div>
            <div class="value">{{ visit.doctorFullName || visit.doctor.name }}</div>
          </div>
        </div>
      </div>
      <div class="line"></div>
    </v-card-title>
    <v-card-text>
      <div class="main-row justify-space-between">
        <div class="status" :class="getStatusCodeName[visit.status].class">
          <span class="status-name">{{ $t('visit.' + getStatusCodeName[visit.status].name)}}</span>
        </div>
        <div class="date" style="text-align: end">
          <div class="text-date">
            {{$t('visit.dueDate')}}
          </div>
          <div class="full-date" :class="getStatusCodeName[visit.status].class">
            {{$moment(visit.date).format('MM/DD/YYYY')}}
          </div>
        </div>
      </div>
      <div class="content-text mt-4">
        <p v-html="visit.patientNotes"></p>
      </div>
    </v-card-text>
    <v-card-actions class="justify-center pb-4">
      <div class="main-row">
        <div class="buttons" v-if="visit.surveyId" @click.stop="openReport(visit, '/survey')">
          <span>Survey</span>
        </div>
        <div class="buttons" v-if="visit.id && visit.hasResults">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="d-flex align-center">
                Receipts
                <v-icon>mdi-chevron-down</v-icon>
              </span>
            </template>
            <v-list>
              <v-list-item
                v-for="(receipt, index) in visit.receipts.$values"
                :key="index"
              >
                <v-list-item-title style="cursor: pointer" @click.stop="openReport(visit, '/receipts', receipt)">{{$moment(receipt.date).format('MM/DD/YYYY')}} {{receipt.orderMethodName}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="buttons" v-if="visit.id && visit.hasResults" @click.stop="openReport(visit, '/schedule')">
          <span>Schedule</span>
        </div>
      </div>
    </v-card-actions>
    <doctor-info v-if="dialogs.doctorInfo.isOpen" :visit="visit"/>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import DoctorInfo from '@/components/layouts/forms/doctorInfo'
import Mapping from '@/mixins/Mapping'

export default {
  props: {
    visit: {
      type: Object
    },
    componentFrom: {
      type: String
    }
  },
  components: { DoctorInfo },
  mixins: [Mapping],
  name: 'visit-info-block',
  data: () => ({
    items: [
      { title: 'Click Me' },
      { title: 'Click Me' },
      { title: 'Click Me' },
      { title: 'Click Me 2' }
    ]
  }),
  methods: {
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    openReport (visit, path, obj) {
      let newPath = ''
      if (this.componentFrom === 'evaluations') {
        newPath = 'evaluations/visit/'
      } else {
        newPath = 'history/visit/'
      }
      if (obj) {
        this.$router.push({ path: newPath + visit.id + path, query: { receiptId: obj.id } })
      } else {
        this.$router.push({ path: newPath + visit.id + path })
      }
    },
    openDoctorInfo () {
      console.log('openDoctorInfo')
      this.TOGGLE_DIALOG({ name: 'doctorInfo', isOpen: true })
    }
  },
  computed: {
    dialogs () {
      return this.$store.state.dialogs
    }
  }
}
</script>

<style scoped lang="scss">
.visit-info-block {
  width: 100%;
  border-radius: 16px;
  .main-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    column-gap: 1rem;
    row-gap: 1rem;
    justify-content: center;
    align-items: center;
    .status {
      width: auto;
      height: 56px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      justify-content: center;
      .status-name {
        font-weight: 600;
        font-size: 14px;
      }
    }
    .date {
      width: 45%;
      min-width: 120px;
      .text-date {
        font-weight: 400;
        background: transparent!important;
      }
      .full-date {
        font-weight: 600;
        background: transparent!important;
      }
    }
    .info-doctor {
      display: flex;
      flex-wrap: wrap;
      column-gap: 0.5rem;
      width: 45%;
      min-width: 120px;
    }
    .label {
      font-weight: 400;
      font-size: 14px;
      color: #718096;
    }
    .value {
      font-weight: 600;
      font-size: 18px;
      color: #16192C;
    }
    .buttons {
      background: #F7FAFC;
      border-radius: 8px;
      padding: 18px 19px;
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      color: #425466;
    }
  }
  .line {
    width: 100%;
    background: #d6d6d6;
    height: 0.01rem;
    margin-top: 1rem;
  }
  @media screen and (max-width: 320px){
    .main-row {
      .info-doctor {
        justify-content: center;
        .full-name {
          text-align: center;
        }
      }
    }
  }

  .need-product {
    background: rgba(255, 237, 227, 0.7)!important;
    color: #F7936F!important;
  }
  .in-active {
    background: #EDF2F7!important;
    color: #A0AEC0!important;
  }
  .need-survey {
    background: rgba(255, 237, 227, 0.7)!important;
    color: #F7936F!important;
  }
  .need-reeval {
    background: rgba(255, 237, 227, 0.7)!important;
    color: #F7936F!important;
  }
  .survey-sent {
    background: rgba(208, 220, 255, 0.5);
    color: #668BFF;
  }
  .survey-due {
    background: rgba(255, 230, 228, 0.7)!important;
    color: #F16063!important;
  }
  .need-review {
    background: rgba(238, 227, 255, 1)!important;
    color: #9959F8!important;
  }
  .reviewed {
    background: rgba(208, 220, 255, 0.5)!important;
    color: #668BFF!important;
  }
  .in-protocol {
    background: rgba(115, 217, 149, 0.2)!important;
    color: #4FAA6E!important;
  }
  .protocol-ending {
    background: #FFE9E2!important;
    color: #E69376!important;
  }
  .completed {
    background: rgba(141, 186, 106, 0.2)!important;
    color: #8DBA6A!important;
  }
  .in-progress {
    background: rgba(208, 220, 255, 0.5);
    color: #668BFF;
  }
}
</style>
