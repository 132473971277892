<template>
  <div class="forgot-password">
    <Dialog width="492" :scrollable="false">

      <template slot="title">
        <span>
          Reset password
        </span>
      </template>

      <template slot="body">
        <v-form ref="form" v-model="valid" lazy-validation id="forgotForm" autocomplete="off">
          <v-row>
            <template v-if="emailWasSendForResetPwd">
              <v-col class="my-4 px-8">Thank you. If your email is registered, we will send you password reset instructions shortly</v-col>
            </template>
            <template v-else>
              <v-col class="my-4 px-8">Enter your email and we'll send you a link to reset your password</v-col>
            </template>
          </v-row>
          <v-row v-if="!emailWasSendForResetPwd">
            <v-col class="pt-0 px-8">
              <div class="label">E-mail</div>
              <Input outlined
                autocomplete="off"
                height="56"
                radius="16"
                background-color="#EDF2F7"
                placeholder="Type your email"
                :rules="emailRules"
                @input="form.email=$event"
                />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center mb-10  px-8">
              <Button v-if="!emailWasSendForResetPwd" block dark btn-bg="dark-green" height="56" @click.native="recovery">Reset password</Button>
              <Button v-else block dark btn-bg="dark-green" height="56" @click.native="closeModal">Go back to login page</Button>
            </v-col>
          </v-row>
        </v-form>
      </template>

    </Dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

import Image from '@/mixins/Image'
import Validation from '@/mixins/Validation'

import Dialog from '@/components/layouts/dialog'
import Input from '@/components/elements/Input'
import Button from '@/components/elements/Button'

export default {
  name: 'forgot-password',
  mixins: [Image, Validation],
  components: { Dialog, Input, Button },
  data: () => ({
    form: {
      email: ''
    },
    emailRules: [],
    emailWasSendForResetPwd: false
  }),
  methods: {
    ...mapActions(['forgotPassword']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    async recovery () {
      this.emailRules = [
        v => !!v || 'E-mail is required.'
        // v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ]
      if (this.validate()) {
        const isSuccess = await this.forgotPassword(this.form.email)
        this.emailWasSendForResetPwd = isSuccess
        /* if (isSuccess) {
          this.TOGGLE_DIALOG({ name: 'forgot', isOpen: false })
        } */
      }
    },
    closeModal () {
      this.TOGGLE_DIALOG({ name: 'forgot', isOpen: false })
      this.emailWasSendForResetPwd = false
    }
  },
  watch: {
    'form.email' (val) {
      if (!val) this.emailRules = []
      if (val) this.emailRules = [v => /.+@.+\..+/.test(v)] // || 'E-mail must be valid']
    }
  }
}
</script>

<style lang="scss" scoped>
  .forgot-password {
    .label {
      font-size: 14px;
      font-weight: 500;
      color: #425466
    }
    ::v-deep .v-dialog {
      overflow: hidden;
      .v-card {
        padding-top: 35px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .forgot-password {
      #forgotForm {
        ::v-deep {
          .v-btn {
            white-space: unset !important;
            display: unset !important;
          }
        }
      }
    }
  }
</style>
