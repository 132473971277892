<template>
  <div class="registration-form">
    <template v-if="!isRegistered">
      <div>Start for free</div>
      <div class="registration-form__title mb-10">Create an account</div>
      <v-form ref="form" v-model="valid" lazy-validation id="authForm">
        <v-row>
          <v-col class="py-0">
            <div class="label">E-mail</div>
            <Input input-class="registration"
                   outlined
                   height="56"
                   radius="16"
                   tabindex="1"
                   background-color="#EDF2F7"
                   placeholder="Your Email"
                   :rules="regEmailRules"
                   @input="form.email=$event"
                   @enter="preLocalRegister"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-0" style="position: relative">
            <div class="label">Password</div>
            <Input input-class="registration"
                   outlined
                   height="56"
                   radius="16"
                   tabindex="2"
                   background-color="#EDF2F7"
                   placeholder="Password"
                   type="password"
                   :rules="regPwdRules"
                   @input="form.password=$event"
                   @focus="showPasswordRequirements = true"
                   @blur="showPasswordRequirements = false"
                   @enter="preLocalRegister"
            /><!--notEmtpy.concat(pwdRules)-->
            <div class="pwd-requirements-prompt">
              <password-requirements
                v-show="showPasswordRequirements"
                :verifiablePassword="form.password"
                :rules="passwordRequirementsRules"
              />
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-0">
            <div class="label">Confirm Password</div>
            <Input input-class="registration"
                   outlined
                   height="56"
                   radius="16"
                   tabindex="3"
                   background-color="#EDF2F7"
                   placeholder="Confirm password"
                   type="password"
                   :rules="regCfmPwdRules"
                   @input="form.confirmPassword=$event"
                   @enter="preLocalRegister"
            /><!--notEmtpy.concat(cfmPwdRule)-->
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-0">
            <div class="registration-form__agree d-flex align-start">
              <checkbox color="success" tabindex="4" :rules="regAgreeRules" @change="form.isAgreedToTerms=$event" />
              <span class="mr-2">Agree with</span>
              <a
                href="https://surveymaestro.com/contact.php"
                target="_blank"
                style="color: #72767F">Terms end Conditions</a>
<!--              <Link color="gray" to="#">Terms end Conditions</Link>-->
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="py-0">
            <div class="d-flex align-start">
              <!-- <vue-recaptcha
                :sitekey="captchaKey"
                @verify="verify"
                @expired="expired"
                @render="render"
                @error="error"
              ></vue-recaptcha> -->
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="mt-4">
            <Button btn-bg="dark-green" @click.native="preLocalRegister" height="56" block dark>Sign up</Button>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="registration-form__registration-link d-flex justify-center">Already have an account? <Link class="ml-2" to="/sign-in">Sign in</Link></div>
          </v-col>
        </v-row>

      </v-form>
    </template>
    <template v-else>
      <div class="registered">
        <img src="../../../assets/icons/icon-done.svg" alt="Almost done!">
        <h2 class="registered__title">Nice. Almost done!</h2>
        <div class="registered__info">
          <v-row justify="center">
            <v-col class="py-5">
              <p class="registered__info-text">
                We sent a verification email to you. Please
                check your incoming mail
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <div class="d-flex justify-center">
                <Button block btn-bg="dark-green"
                        :width="'auto'"
                        :height="56"
                        :styles="{ 'border-radius': '8px' }"
                        @click.native="$router.push('/sign-in')"
                >
                  <span style="color: white">Back to login page</span>
                </Button>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Validation from '@/mixins/Validation'

// import VueRecaptcha from 'vue-recaptcha'

import Input from '@/components/elements/Input'
import checkbox from '@/components/elements/checkbox'
import Link from '@/components/elements/Link'
import Button from '@/components/elements/Button'
import { mapActions, mapState } from 'vuex'
import { MIN_PASS_LENGTH } from '@/constants/const'
import PasswordRequirements from '@/components/elements/PasswordRequirements'

export default {
  name: 'registration-form',
  mixins: [Validation],
  components: { Input, checkbox, Link, Button, PasswordRequirements },
  data: () => ({
    form: {
      email: '',
      password: '',
      confirmPassword: '',
      isAgreedToTerms: false,
      isCaptchaChecked: true
    },
    isRegistered: false,
    showPasswordRequirements: false,
    passwordRequirementsRules: [
      {
        text: `Be at least ${MIN_PASS_LENGTH} characters long`,
        icon: 'mdi-check-circle',
        passedValidation: false
      },
      {
        text: 'Have at least one uppercase',
        icon: 'mdi-check-circle',
        passedValidation: false
      },
      {
        text: 'Have at least one lowercase',
        icon: 'mdi-check-circle',
        passedValidation: false
      },
      {
        text: 'Have at least one number',
        icon: 'mdi-check-circle',
        passedValidation: false
      },
      {
        text: 'Have at least one special characters (!@#)',
        icon: 'mdi-check-circle',
        passedValidation: false
      }
    ]
  }),
  methods: {
    ...mapActions(['registration']),
    preLocalRegister () {
      this.setDynamicValidationRules({
        regEmailRules: [
          v => !!v || 'E-mail is required.',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
        ],
        regPwdRules: [
          v => !!v || 'Password is required.',
          v => !!v || 'This field should not be empty',
          v => (v && v?.length) >= MIN_PASS_LENGTH || `Min ${MIN_PASS_LENGTH} characters`],
        regCfmPwdRules: [
          v => !!v || 'Confirm password is required.',
          v => (v === this.form.password) || 'This field must match'
        ],
        regAgreeRules: [v => !!v || 'You must agree to continue!']
      })
      this.$nextTick(() => {
        this.register()
      }, 200)
    },
    async register () {
      if (this.validate()) {
        const result = await this.registration(this.form)
        if (result && result.data && result.data.success && result.data.validationResults.isValid) {
          this.isRegistered = true
        }
      }
    },
    async recaptcha () {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      const token = await this.$recaptcha('registration')
      console.log(token)

      // Do stuff with the received token.
    }
    // verify ($event) {
    //   if ($event) {
    //     this.isCaptchaChecked = true
    //   }
    // },
    // expired ($event) {
    //   console.log('expired', $event)
    // },
    // render ($event) {
    //   console.log('render', $event)
    // },
    // error ($event) {
    //   console.log('error', $event)
    // }
  },
  created () {
    this.recaptcha()
  },
  computed: {
    captchaKey () {
      return process.env.VUE_APP_GOOGLE_API_TOKERN
    },
    ...mapState({
      messages: state => state.messages
    })
  },
  watch: {
    'form.email' (val) {
      if (!val) this.regEmailRules = []
      if (val) {
        this.regEmailRules = [
          v => !!v || 'E-mail is required.',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
        ]
      }
    },
    'form.password' (val) {
      if (!val) this.regPwdRules = []
      if (val) {
        this.regPwdRules = [
          v => !!v || 'Password is required.',
          v => !!v || 'This field should not be empty',
          v => (v && v?.length) >= MIN_PASS_LENGTH || `Min ${MIN_PASS_LENGTH} characters`
        ]
      }
    },
    'form.confirmPassword' (val) {
      if (!val) this.regCfmPwdRules = []
      if (val) {
        this.regCfmPwdRules = [
          v => !!v || 'Confirm password is required.',
          v => (v === this.form.password) || 'This field must match'
        ]
      }
    },
    'form.isAgreedToTerms' (val) {
      if (!val) this.regAgreeRules = [v => !!v || 'You must agree to continue!']
      if (val) this.regAgreeRules = []
    },
    messages (val) {
      if (val && val.length > 0) {
        let errMessages = ''
        for (let i = 0; i < val.length; i++) {
          errMessages += `${val[i].msg} `
        }
        this.regEmailRules = [v => errMessages]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .label {
    font-size: 14px;
    font-weight: 500;
    color: #425466;
  }
  .registration-form {
    width: 100%;
    &__registration-link,
    &__agree {
      font-weight: 500;
      font-size: 14px;
      color: #72767F;
      position: relative;
      ::v-deep .v-messages.error--text {
        position: absolute;
        top: 26px;
      }
    }
    &__title {
      font-weight: 500;
      font-size: 38px;
    }
    .registered {
      &__title {
        font-weight: 500;
        font-size: 36px;
        line-height: 136.02%;
        color: #27272E;
      }
      &__info-text {
        color: #27272E;
        font-size: 20px;
      }
    }
  }
  .pwd-requirements-prompt {
    width: inherit;
    position: absolute;
    z-index: 4;
    right: 2px;// -458px;
    top: 83px;// -75px;
  }
  ::v-deep .v-text-field__details {
    padding-top: 5px !important;
  }
  @media screen and (max-width: 350px) {
    .registration-form {
      #authForm {
        .registration-form__agree {
          display: unset !important;
          .checkbox {
            display: inline-block !important;
          }
        }
        .registration-form__registration-link {
          display: unset !important;
          .link {
            margin-left: 0!important;
          }
        }
      }
    }
  }
</style>
