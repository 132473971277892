<template>
  <div class="registration-from-qr">
    <template v-if="!isRegistered">
      <div class="registration-from-qr__title mb-5">Join {{responseData.practiceName}} Patient Portal</div>
      <v-form ref="form" v-model="valid" lazy-validation id="authForm">
        <v-row style="margin-bottom: -23px;">
          <v-col class="py-0">
            <div class="label">First Name</div>
            <Input input-class="registration"
                   outlined
                   height="56"
                   radius="16"
                   tabindex="1"
                   background-color="#EDF2F7"
                   placeholder="Your First Name"
                   :rules="notEmtpy"
                   @input="form.firstName=$event"
                   @enter="register"
            />
          </v-col>
        </v-row>

        <v-row style="margin-bottom: -23px;">
          <v-col class="py-0" style="position: relative">
            <div class="label">Last Name</div>
            <Input input-class="registration"
                   outlined
                   height="56"
                   radius="16"
                   tabindex="2"
                   background-color="#EDF2F7"
                   placeholder="Your Last Name"
                   :rules="notEmtpy"
                   @input="form.lastName=$event"
                   @enter="register"
            />
          </v-col>
        </v-row>

        <v-row style="margin-bottom: -23px;">
          <v-col class="py-0">
            <div class="label">E-mail</div>
            <Input input-class="registration"
                   outlined
                   height="56"
                   radius="16"
                   tabindex="3"
                   background-color="#EDF2F7"
                   placeholder="Your Email"
                   :rules="emailRules"
                   @blur="checkEmail"
                   @input="form.email=$event"
                   @enter="register"
            />
          </v-col>
        </v-row>

        <v-row style="margin-bottom: -23px;">
          <v-col class="py-0">
            <div class="label">Phone</div>
            <PhoneInput
              height="56"
              radius="16"
              tabindex="4"
              :value="form.phone"
              @input="form.phone=$event"
            />
          </v-col>
        </v-row>

        <v-row style="margin-bottom: -23px;">
          <v-col class="py-0 d-flex justify-space-between align-center">
            <div class="label">Gender</div>
            <div class="gender-switcher">
              <v-row class="d-flex mr-0">
                <v-col cols="6">
                  <Button
                    block
                    dark
                    :class="{ 'active-gender': form.sex === 'Male' }"
                    tabindex="5" height="36"
                    @click.native="updateGender('Male')">
                    Male
                  </Button>
                </v-col>
                <v-col cols="6">
                  <Button
                    block
                    dark
                    :class="{ 'active-gender': form.sex === 'Female' }"
                    tabindex="6" height="36"
                    @click.native="updateGender('Female')">
                    Female
                  </Button>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>

        <v-row style="margin-bottom: -35px;">
          <v-col class="py-0 d-flex justify-space-between align-center">
            <div class="label">Dietary</div>
            <div class="d-flex registration-from-qr__dietary">
              <v-checkbox
                class="pt-1 pb-1"
                color="#008C89"
                tabindex="7"
                v-model="form.vegetarian"
                label="Vegetarian"/>
              <v-checkbox
                color="#008C89"
                tabindex="8"
                class="pt-1 pb-1"
                v-model="form.glutenFree"
                label="Gluten free"/>
            </div>
          </v-col>
        </v-row>

        <v-row style="margin-bottom: -40px;">
          <v-col class="py-0 d-flex justify-space-between align-center my-0">
            <div class="d-flex registration-from-qr__dietary">
              <v-checkbox
                class="pt-1 pb-1"
                color="#008C89"
                tabindex="9"
                v-model="form.nursingPregnant"
                label="Are you Nursing/Pregnant?"/>
            </div>
          </v-col>
        </v-row>

        <v-row style="margin-bottom: -40px;">
          <v-col class="py-0 d-flex justify-space-between align-center my-0">
            <div class="d-flex registration-from-qr__dietary">
              <v-checkbox
                class="pt-1 pb-1"
                color="#008C89"
                tabindex="10"
                v-model="form.sufferedFromHypertension"
                label="Do you suffer from hypertension?"/>
            </div>
          </v-col>
        </v-row>

        <v-row style="margin-bottom: -15px;">
          <v-col class="py-0 mt-5">
            <div class="registration-from-qr__agree d-flex align-start">
              <checkbox color="#008C89" tabindex="11" :rules="regAgreeRules" @change="form.isAgreedToTerms=$event" />
              <span class="mr-2">Agree with</span>
              <a
                @click="openTermConditions"
                style="color: #72767F">Terms of Use</a>
              <!--              <Link color="gray" to="#">Terms end Conditions</Link>-->
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div v-if="commonError && commonError.length" style="line-height: 12px;font-size: 12px;color: #ff5252;margin-bottom: 5px;">
              <div v-for="(err, idx) in commonError" :key="idx">
                {{err}}
              </div>
            </div>
            <div>
              <Button btn-bg="dark-green" tabindex="12" @click.native="register" height="56" block dark>Sign up</Button>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="registration-from-qr__registration-link d-flex justify-center">Already have an account? <Link class="ml-2" to="/sign-in">Sign in</Link></div>
          </v-col>
        </v-row>

      </v-form>
      <div v-if="loading" class="loading d-flex justify-center px-2 py-2 align-center">
        <v-progress-circular
          indeterminate
          color="#008C89"
        ></v-progress-circular>
      </div>
    </template>
    <template v-else>
      <div class="registered">
        <img src="../../../assets/icons/icon-done.svg" alt="Almost done!">
        <h2 class="registered__title">Nice. Almost done!</h2>
        <div class="registered__info">
          <v-row justify="center">
            <v-col class="py-5">
              <p class="registered__info-text">
                We sent a verification email to you. Please
                check your incoming mail
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <div class="d-flex justify-center">
                <Button block btn-bg="dark-green"
                        :width="'auto'"
                        :height="56"
                        :styles="{ 'border-radius': '8px' }"
                        @click.native="$router.push('/sign-in')"
                >
                  <span style="color: white">Back to login page</span>
                </Button>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </template>
    <terms-conditions v-if="dialogTermsConditions.isOpen"/>
  </div>
</template>

<script>
import Validation from '@/mixins/Validation'

import Input from '@/components/elements/Input'
import PhoneInput from '@/components/elements/phone-input'
import checkbox from '@/components/elements/checkbox'
import Link from '@/components/elements/Link'
import Button from '@/components/elements/Button'
import { mapActions, mapMutations, mapState } from 'vuex'
import { MIN_PASS_LENGTH } from '@/constants/const'
import TermsConditions from '@/components/layouts/dialogs/terms-conditions'

export default {
  name: 'registration-from-qr',
  mixins: [Validation],
  props: {
    responseData: Object
  },
  components: { Input, checkbox, Link, Button, PhoneInput, TermsConditions },
  data: () => ({
    form: {
      URLKey: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      sex: '',
      vegetarian: false,
      glutenFree: false,
      nursingPregnant: false,
      sufferedFromHypertension: false,
      isAgreedToTerms: false,
      isCaptchaChecked: true
    },
    isRegistered: false,
    showPasswordRequirements: false,
    passwordRequirementsRules: [
      {
        text: `Be at least ${MIN_PASS_LENGTH} characters long`,
        icon: 'mdi-check-circle',
        passedValidation: false
      },
      {
        text: 'Have at least one uppercase',
        icon: 'mdi-check-circle',
        passedValidation: false
      },
      {
        text: 'Have at least one lowercase',
        icon: 'mdi-check-circle',
        passedValidation: false
      },
      {
        text: 'Have at least one number',
        icon: 'mdi-check-circle',
        passedValidation: false
      },
      {
        text: 'Have at least one special characters (!@#)',
        icon: 'mdi-check-circle',
        passedValidation: false
      }
    ],
    commonError: [],
    loading: false
  }),
  mounted () {
    document.title = 'Registration to Maestro Patient Portal'
  },
  methods: {
    ...mapActions(['registrationFromQr', 'vailidateEmail']),
    ...mapMutations('dialogs', ['TOGGLE_DIALOG']),
    updateGender (lang) {
      this.form.sex = lang
    },
    async checkEmail () {
      const { email } = this.form
      const params = { email, URLKey: this.$route.params.dynamicUrl }
      const res = await this.vailidateEmail(params)
      this.setDynamicValidationRules({
        emailRules: [
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
          res.data.validationResults.isValid || res.data.message
        ]
      })
      // this.$set(this.$data, 'isValidEmail', res.data.validationResults.isValid)
    },
    async register () {
      this.loading = true
      this.form.URLKey = this.$route.params.dynamicUrl
      if (this.validate()) {
        const result = await this.registrationFromQr(this.form)
        if (result && result.data && result.data.success && result.data.validationResults.isValid) {
          this.isRegistered = true
        }
        /*if (!result.data.validationResults.isValid) {
          if (result.data.validationResults?.results[0].name === 'Email') {
            this.setDynamicValidationRules({
              emailRules: [
                v => !!v || 'Email is required',
                // v => (!v || /.+@.+\..+/.test(v)) || 'E-mail must be valid',
                result.data.validationResults.isValid || result.data.validationResults?.results[0].message
              ]
            })
          }
          if (result.data.validationResults?.results[0].name === 'Common') {
            this.commonError = result.data.validationResults?.results[0].message
          }
        }*/
        if (result.data.errors) {
          Object.values(result.data.errors).forEach(err => {
            this.commonError.push(...err)
          })
        }
      }
      this.loading = false
    },
    openTermConditions () {
      this.TOGGLE_DIALOG({ name: 'termAndConditions', isOpen: true })
    }
  },
  computed: {
    captchaKey () {
      return process.env.VUE_APP_GOOGLE_API_TOKERN
    },
    ...mapState({
      messages: state => state.messages,
      dialogTermsConditions: state => state.dialogs.termAndConditions
    })
  },
  watch: {
    'form.email' (val) {
      if (!val) this.regEmailRules = []
      if (val) {
        this.regEmailRules = [
          v => !!v || 'E-mail is required.',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
        ]
      }
    },
    'form.password' (val) {
      if (!val) this.regPwdRules = []
      if (val) {
        this.regPwdRules = [
          v => !!v || 'Password is required.',
          v => !!v || 'This field should not be empty',
          v => (v && v?.length) >= MIN_PASS_LENGTH || `Min ${MIN_PASS_LENGTH} characters`
        ]
      }
    },
    'form.confirmPassword' (val) {
      if (!val) this.regCfmPwdRules = []
      if (val) {
        this.regCfmPwdRules = [
          v => !!v || 'Confirm password is required.',
          v => (v === this.form.password) || 'This field must match'
        ]
      }
    },
    'form.isAgreedToTerms' (val) {
      if (!val) this.regAgreeRules = [v => !!v || 'You must agree to continue!']
      if (val) this.regAgreeRules = []
    },
    messages (val) {
      if (val && val.length > 0) {
        let errMessages = ''
        for (let i = 0; i < val.length; i++) {
          errMessages += `${val[i].msg} `
        }
        this.regEmailRules = [v => errMessages]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.loading {
  min-height: 100px;
  position: absolute;
  height: 95%;
  width: 110%;
  top: 35px;
  left: -20px;
  background: rgba(213, 213, 213, 0.5);
  border-radius: 16px;
}
.label {
  font-size: 14px;
  font-weight: 500;
  color: #425466;
}
.registration-from-qr {
  width: 100%;
  position: relative;
  &__registration-link,
  &__agree {
    font-weight: 500;
    font-size: 14px;
    color: #72767F;
    position: relative;
    ::v-deep .v-messages.error--text {
      position: absolute;
      top: 23px;
      //margin-bottom: 10px;
    }
  }
  &__title {
    font-weight: 600;
    font-size: 25px;
  }
  &__dietary {
    ::v-deep .v-input {
      &__control {
        .v-messages {
          display: none;
        }
      }
    }
  }
  .registered {
    &__title {
      font-weight: 500;
      font-size: 36px;
      line-height: 136.02%;
      color: #27272E;
    }
    &__info-text {
      color: #27272E;
      font-size: 20px;
    }
  }
  .gender-switcher {
    border: 1px solid #f1f1f1;
    border-radius: 18px;
    padding: 4px;
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);

    .button {
      ::v-deep .v-btn {
        background-color: rgba(255, 255, 255, 0.64) !important;
        color: black!important;
        font-weight: 600!important;
      }
    }
    .button.active-gender {
      ::v-deep .v-btn {
        background-color: $dark-green !important;
        color: white!important;
        font-weight: 600!important;
      }
    }
  }
}
.pwd-requirements-prompt {
  position: absolute;
  z-index: 4;
  right: -458px;
  top: -75px;
}
::v-deep .v-text-field__details {
  padding-top: 5px !important;
}
@media screen and (max-width: 350px) {
  .registration-from-qr {
    #authForm {
      .registration-from-qr__agree {
        display: unset !important;
        .checkbox {
          display: inline-block !important;
        }
      }
      .registration-from-qr__registration-link {
        display: unset !important;
        .link {
          margin-left: 0!important;
        }
      }
    }
  }
}
</style>
